import StripeCustomerPortal from "../../../components/Stripe/StripeCustomerPortal";

const SuccessDisplay = () => {
  return (
      <section
          className="bg-green-100 border border-green-400 success-text px-4 py-3 rounded-2xl relative max-w-4xl mx-auto my-10 w-full"
          role="alert"
      >
          <div className="text-lg mb-10">
              <h3 className="font-bold">Subscription Successful!</h3>
              <p>
                  Your subscription to the BlueFairy Infinity plan has been
                  activated.
              </p>
          </div>
          <div className='w-full text-center'>
            <StripeCustomerPortal/>
          </div>
          
      </section>
  );
};


export default SuccessDisplay