import {useState, useEffect} from 'react';
import OrangeRays from '../../../assets/OrangeRays.svg';

const isSVG = (content) => {
  return typeof content === 'string' && content.startsWith('<svg');
};


const RaysWithSVG = ({ strokeWidth, radius, circumference, Image }) => {

  const [offset, setOffset] = useState(circumference - (0 / 100) * circumference);

  useEffect(() => {
    const startAnimation = () => {
      const start = Date.now();
      const duration = 800; // 0.8 seconds

      const step = () => {
        const now = Date.now();
        const progress = Math.min((now - start) / duration, 1);
        setOffset(circumference - progress * circumference);

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);
    };

    const timerId = setTimeout(startAnimation, 1000);

    return () => clearTimeout(timerId);
  }, []);


  return (
  <div className="relative flex justify-center items-center w-full h-full mx-auto my-24 [transform:rotateX(15deg)]">
    <div
      className="absolute w-[180%] h-[180%] animate-spin-slow opacity-40 z-[-1]"
      style={{ background: `url(${OrangeRays}) center center / cover no-repeat` }}
    ></div>
    <svg
      width={200}
      height={200}
      className="-rotate-90 transform flex flex-col relative z-10 items-center drop-shadow-[0_0px_6px_rgba(243,186,117,0.60)]"
    >
      <circle
        stroke="rgba(234, 181, 52)"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        cx={93}
        cy={100}
        className="transform -translate-y-1 drop-shadow-xl"
      />
      <circle
        stroke="rgb(255, 187, 51)"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={100}
        cy={100}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        className="transform -translate-y-1"
      />
      <foreignObject
        x={50}
        y={47}
        width={100}
        height={100}
      >
        <div className="flex items-center justify-center h-full drop-shadow-md">
          <div className="rotate-90">
            {isSVG(Image) ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-20"
                alt="Badge Icon"
              >
                {Image}
              </svg>
            ) : (
              <img
                src={Image}
                className="h-20"
                alt="Badge Icon"
              />
            )}
            </div>
        </div>
      </foreignObject>
    </svg>
  </div>
  );
};

export default RaysWithSVG;
