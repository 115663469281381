import IncreaseCost from '../../assets/market-today.svg';
import EarnPoints from '../../assets/earn-rewards.svg';
import ConnectingPeople from '../../assets/connecting-people.svg';

/**
 * Array of objects representing sections on the landing page.
 * @typedef {object} Section
 * @property {string} title - The title of the section.
 * @property {string} description - The description of the section.
 * @property {string} statistic - The statistic related to the section.
 * @property {string} style - The style of the section (e.g., 'row', 'row-reverse').
 * @property {string} image - The image associated with the section.
 */

/**
 * Array of sections on the landing page.
 * @type {Section[]}
 */
export const sections = [
    {
        title: 'Your journey could help unlock cures.',
        description:
            "24 million Americans battle an autoimmune disorder and it's time we collaborate for a cure. Sharing your data with researchers and clinicians can help us better understand and prevent autoimmunity.",
        statistic: '6% annual increase in autoimmunity cases.',
        style: 'row',
        image: IncreaseCost,
    },
    {
        title: 'Earn points for sharing your data.',
        description:
            "4 out of 5 patients have an adverse reactions to their first medication, and costs of treatment are skyrocketing to over $150 billion annually. Let's solve this together. ",
        statistic: 'Treatment costs increased by over 700% in the past decade.',
        style: 'row-reverse',
        image: EarnPoints,
    },

    {
        title: 'Join us in reversing autoimmunity.',
        description:
            "Every contribution counts. As part of our community, you'll be rewarded for helping drive research and advancements in autoimmune treatments.",
        statistic: 'Autoimmunity is 150+ unique symptom classifiers',
        style: 'row',
        image: ConnectingPeople,
    },
];
