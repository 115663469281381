import * as yup from 'yup';

export const dietaryHabitsSchema = yup.object({
  dietaryHabit: yup.array().of(
    yup.object({
      dailyDiet: yup.string().required('Typical Daily Diet is required.'),
      dietaryRestrictions: yup.string().required('Dietary Restrictions are required.'),
      dietaryPreferences: yup.string().required('Dietary Preferences are required.'),
      mealFrequency: yup.object({
        mealAmount: yup.number().positive().integer().required('Full Meal Amount is required.'),
        snackAmount: yup.number().positive().integer().required('Snack Amount is required.'),
      }).required('Meal Frequency is required.')
    })
  ).required('Dietary Habit information is required.'),
});


export const supplementUseSchema = yup.object({
  supplement: yup.array().of(
    yup.object({
      supplementName: yup.string().required('Supplement Name is required.'),
      dosage: yup.object({
        doseAmount: yup.number().positive().required('Dose Amount is required.'),
        doseUnit: yup.string().required('Dose Unit is required.'),
      }).required('Dosage information is required.'),
      frequency: yup.object({
        frequencyAmount: yup.number().positive().integer().required('Frequency Amount is required.'),
        frequencyUnit: yup.string().required('Frequency Unit is required.'),
      }).required('Frequency information is required.'),
      reasonForUse: yup.string().required('Reason for Use is required.'),
      perceivedEffectiveness: yup.number().min(1).max(10).required('Perceived Effectiveness is required.'),
    })
  ).notRequired(),
});


export const nutritionalDeficienciesSchema = yup.object({
  nutritionalDeficiency: yup.array().of(
    yup.object({
      deficiency: yup.string().required('Deficiency is required.'),
      diagnosisDate: yup.date().nullable(),
      severity: yup.string().required('Severity is required.'),
      recommendedSupplements: yup.string().required('Recommended Supplements/Changes are required.'),
      followUpResults: yup.string().required('Follow-up Tests/Results are required.'),
    })
  ).notRequired(),
});
