import { Menu, Transition } from '@headlessui/react';
import {
    IconCircleCheck,
    IconClockHour3,
    IconDotsVertical,
} from '@tabler/icons-react';
import { Fragment } from 'react';
import React, { useEffect, useState } from 'react';
import { fetchAdminUsers, updateAdminUser } from '../../api/adminAPI';
import Button from '../../components/ui/Button/Button';
import cn from '../../components/ui/utils/cn';
import AddUserModal from './components/AddUserModal';
import DeleteUserModal from './components/DeleteUserModal';
import Chip from '../../components/ui/Chip/Chip';
import { IconChevronRight } from '@tabler/icons-react';
import { IconChevronLeft } from '@tabler/icons-react';

/**
 * Renders the ManageAdminUsers page in the AdminPanel
 * @returns {React.JSX.Element} The rendered ManageAdminUsers page.
 */
export default function ManageAdminUsers() {
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState(null);
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetchAdminUsers(page, searchQuery);
                setData(response.users);
                setTotalPages(response.totalPages);
                setCurrentPage(response.currentPage);
                setTotalUsers(response.totalUsers);
                setError(null);
            } catch (error) {
                console.error('Error fetching early access users:', error);
                setError('Failed to fetch users. Please try again.');
            }
        };
        !searchQuery && fetchUsers();
    }, [searchQuery]);

    const handleUpdateUser = async (userId, role) => {
        try {
            await updateAdminUser(userId, { role });
            setData(
                data.map((user) =>
                    user._id === userId ? { ...user, role } : user
                )
            );
        } catch (error) {
            console.error('Error updating user approval status:', error);
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setPage(1);
                const response = await fetchAdminUsers(page, searchQuery);
                setData(response.users);
                setTotalPages(response.totalPages);
                setCurrentPage(response.currentPage);
                setTotalUsers(response.totalUsers);
                setError(null);
            } catch (error) {
                console.error('Error fetching early access users:', error);
                setError('Failed to fetch users. Please try again.');
            }
        };
        const debounceTimer = setTimeout(() => {
            if (searchQuery) fetchUsers();
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchQuery]);

    if (!data) {
        return <p>Loading...</p>;
    }

    const usersOnScreen = data.length;

    return (
        <div className="admin-panel min-h-screen ">
            <div className="mx-auto max-w-7xl">
                <div className="mb-6 flex flex-col items-center md:flex-row md:justify-between">
                    <div className="flex-1 text-center md:text-left">
                        <h1 className="text-3xl font-bold">
                            Manage Admin Users
                        </h1>
                    </div>
                </div>
                <div className="mb-6 flex w-full items-center gap-10">
                    <form className="flex w-full flex-col justify-center space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                        <input
                            type="text"
                            placeholder="Search by email"
                            value={searchQuery}
                            onChange={handleSearch}
                            className="w-full appearance-none rounded-lg  border bg-white px-4 py-2 leading-tight text-gray-700 shadow md:px-6  md:py-3"
                        />
                    </form>
                    <div className="flex-shrink-0">
                        <Button
                            className="rounded-md font-semibold sm:text-sm md:text-base"
                            onClick={() => setIsAddUserModalOpen(true)}
                        >
                            Add User
                        </Button>
                    </div>
                </div>
            </div>

            {error && <p className="text-red-500">{error}</p>}

            <div className="overflow-x-auto rounded-md">
                <table className="min-w-full rounded-md border border-gray-300 bg-white">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="border-b border-gray-300 px-4 py-2 text-left">
                                Email
                            </th>
                            <th className="border-b border-gray-300 px-4 py-2 text-left">
                                Role
                            </th>
                            <th className="border-b border-gray-300 px-4 py-2 text-left">
                                Last Login
                            </th>
                            <th className="border-b border-gray-300 px-4 py-2 text-left">
                                Status
                            </th>
                            <th className="border-b border-gray-300 px-4 py-2 text-left">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((user) => (
                            <tr
                                key={user._id}
                                className="border-b border-gray-300"
                            >
                                <td className="whitespace-nowrap px-4 py-2 text-left">
                                    {user.email}
                                </td>
                                <td className="whitespace-nowrap px-4 py-2 text-left">
                                    {user.role}
                                </td>
                                <td className="px-4 py-2 text-left">
                                    {new Date(
                                        user.lastLogin
                                    ).toLocaleDateString()}
                                </td>
                                <td className="px-4 py-2">
                                    {user.status === 'active' ? (
                                        <Chip
                                            variant="success"
                                            className="flex w-fit items-center gap-2"
                                        >
                                            <IconCircleCheck className="size-4 text-green-800" />
                                            active
                                        </Chip>
                                    ) : (
                                        <Chip
                                            variant="warning"
                                            className="flex w-fit items-center gap-2"
                                        >
                                            <IconClockHour3 className="size-4 text-yellow-800" />
                                            pending
                                        </Chip>
                                    )}
                                </td>
                                <td className="px-4 py-2 text-left">
                                    <Menu as="div" className="relative w-full">
                                        <div className="w-full">
                                            <Menu.Button className=" group flex w-fit items-center gap-5 rounded-lg p-2  text-[#777777]  hover:bg-gray-100">
                                                <span className="sr-only">
                                                    Open more menu
                                                </span>

                                                <IconDotsVertical className=" h-7 text-gray-500 transition duration-75  group-hover:text-[#777777] " />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="-right-30 fixed z-50 mt-2 w-48 origin-top-left rounded-md bg-white px-2 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:right-10">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            type="button"
                                                            className={cn(
                                                                active
                                                                    ? 'bg-gray-100'
                                                                    : '',
                                                                ' group flex w-full items-center justify-center gap-2 rounded-lg p-2 px-4 py-2  text-sm font-bold tracking-wide  text-[#777777] transition hover:bg-gray-100'
                                                            )}
                                                            onClick={() =>
                                                                handleUpdateUser(
                                                                    user._id,
                                                                    user.role ===
                                                                        'member'
                                                                        ? 'admin'
                                                                        : 'member'
                                                                )
                                                            }
                                                        >
                                                            Change to{' '}
                                                            {user.role ===
                                                            'member'
                                                                ? 'admin'
                                                                : 'member'}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            type="button"
                                                            className={cn(
                                                                active
                                                                    ? 'bg-gray-100'
                                                                    : '',
                                                                ' group flex w-full items-center justify-center gap-2 rounded-lg p-2 px-4 py-2  text-sm font-bold tracking-wide text-red-800 transition hover:bg-red-100'
                                                            )}
                                                            onClick={() =>
                                                                setIsDeleteUserModalOpen(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            Remove user
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </td>
                                <DeleteUserModal
                                    setIsOpen={setIsDeleteUserModalOpen}
                                    isOpen={isDeleteUserModalOpen}
                                    userId={user._id}
                                    setData={setData}
                                />
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 flex w-full items-center justify-center gap-2">
                <Button
                    variant="textWithIcon"
                    onClick={() => setPage(page > 1 ? page - 1 : 1)}
                    disabled={page === 1}
                    className="mr-2 rounded-md bg-gray-200  font-semibold sm:text-sm md:text-base"
                    icon={<IconChevronLeft className="size-5 " />}
                >
                    Prev
                </Button>
                <p className="text-center text-gray-400">
                    Page: {currentPage} of {totalPages}
                </p>

                <Button
                    variant="textWithIcon"
                    icon={<IconChevronRight className="size-5 " />}
                    onClick={() =>
                        setPage(page < totalPages ? page + 1 : totalPages)
                    }
                    disabled={page === totalPages}
                    className="flex-row-reverse rounded-md bg-gray-200 font-semibold sm:text-sm md:text-base"
                >
                    Next
                </Button>
            </div>

            <div className="mt-4 flex items-center justify-between">
                <div className="text-gray-700">
                    Showing {usersOnScreen} of{' '}
                    {usersOnScreen < 20 ? usersOnScreen : 20} users out of{' '}
                    {totalUsers}
                </div>
            </div>
            <AddUserModal
                setIsOpen={setIsAddUserModalOpen}
                isOpen={isAddUserModalOpen}
            />
        </div>
    );
}
