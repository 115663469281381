import React from 'react';
import { useFormContext } from 'react-hook-form';

import Butterfly from '../../../assets/roundIcons/Lupus.svg';
import Cocoon from '../../../assets/roundIcons/Cocoon.svg';
import Caterpillar from '../../../assets/roundIcons/Caterpillar.svg';

const frequencyLevels = [
    { 
        name: '5 min', 
        icon: Caterpillar,
    },
    { 
        name: '10 min',
        icon: Cocoon,
     },
    { 
        name: '15 min',
        icon: Butterfly,
     },
];

/**
 * Renders the Frequency component.
 * @returns {React.JSX.Element} The rendered Frequency component.
 */
export default function Frequency() {
    const { register, watch } = useFormContext();

    const selectedFrequency = watch('appUsageFrequency');

    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2 ">
                <div className=" flex flex-col items-center gap-2 text-center ">
                    <h1 className="text-2xl font-extrabold tracking-wide md:text-3xl ">
                        How long do you want to use the app?
                    </h1>
                    <h2>Choose one from the following</h2>
                </div>
            </div>
            <div className="mx-auto mt-10 grid max-w-xl grid-cols-1 gap-4 px-4 md:mt-20">
                {frequencyLevels.map((item, index) => (
                    <label
                    key={index}
                    className={`h-24 flex items-center rounded-lg border px-4 py-4 font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:h-20 md:text-lg ${
                        selectedFrequency === item.name ? 'bg-pictonBlue-300' : ''
                    }`}
                    >
                    <div className="flex justify-center items-center gap-4 w-full break-words text-left">
                        <img src={item.icon} className="w-10" />
                        <span className="w-full text-sm sm:text-base">{item.name}</span>
                    </div>
                    <input
                        type="radio"
                        value={item.name}
                        {...register('appUsageFrequency')}
                        className="hidden" // Hide the radio but keep it functional
                    />
                    </label>
                ))}
            </div>
        </>
    );
}
