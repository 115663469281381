export const formatPhoneNumber = (e) => {
    let input = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters

    if (input.length === 0) {
        input = ''; // Clear input when no digits left
    } else if (input.length <= 3) {
        input = `(${input}`; // Apply formatting only when input is not empty
    } else if (input.length <= 6) {
        input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    } else {
        input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
    }

    e.target.value = input; // Update the input value with formatted number
};
