import { useRef } from 'react';
import './Tooltip.css';

const baseClasses =
    'invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all ease-in-out bg-blue-500 text-white px-4 py-2 md:px-6 md:py-3 rounded-xl absolute mt-2 whitespace-nowrap tooltip-content';

export default function Tooltip({ children, content, position = 'left' }) {
    const tooltipRef = useRef(null);
    const containerRef = useRef(null);

    const setPosition = () => {
        if (!tooltipRef.current || !containerRef.current) return;

        const containerRect = containerRef.current.getBoundingClientRect();

        switch (position) {
            case 'top':
                tooltipRef.current.style.left = '50%';
                tooltipRef.current.style.bottom = `${containerRect.height}px`;
                tooltipRef.current.style.transform =
                    'translateX(-50%) translateY(-100%)';
                tooltipRef.current.className = `${baseClasses} top`;
                break;
            case 'right':
                tooltipRef.current.style.left = `${containerRect.width + 10}px`;
                tooltipRef.current.style.top = '40%';
                tooltipRef.current.style.transform = 'translateY(-50%)';
                tooltipRef.current.className = `${baseClasses} right`;
                break;
            case 'bottom':
                tooltipRef.current.style.left = '50%';
                tooltipRef.current.style.top = `${containerRect.height}px`;
                tooltipRef.current.style.transform = 'translateX(-50%)';
                tooltipRef.current.className = `${baseClasses} bottom`;
                break;
            case 'left':
            default:
                tooltipRef.current.style.top = '40%';
                tooltipRef.current.style.right = '110%';
                tooltipRef.current.style.transform = 'translateY(-50%)';
                tooltipRef.current.className = `${baseClasses} left`;
        }
    };

    return (
        <div
            ref={containerRef}
            onMouseEnter={setPosition}
            className="group relative inline-block"
        >
            {children}
            {content && (
                <span ref={tooltipRef} className={baseClasses}>
                    {content}
                </span>
            )}
        </div>
    );
}
