import React from 'react';
import cn from '../../../components/ui/utils/cn';
import formatLogType from '../utils/getformatttedLogType.js';
import RenderLog from './RenderLog';

/**
 * LogSection component renders a section of log item cards based on the log type.
 * @param {object} props - The component props.
 * @param {string} props.logType - The type of log to be rendered.
 * @param {object} props.healthData - The health data object containing log items, indexed by log type.
 * @param {string|null} props.hoveredItem - The ID of the currently hovered log item, used to apply hover effects.
 * @param {Function} props.setHoveredItem - Function to set the currently hovered log item.
 * @returns {React.JSX.Element|null} The rendered section of logs, or null if there are no logs of the specified type.
 */
const LogSection = ({ logType, healthData, hoveredItem, setHoveredItem }) => {
    if (healthData[logType].length === 0) return null;

    return (
        <div key={logType} className="log-type">
            <h2 className="small-title mb-4">{formatLogType(logType)}s</h2>
            <ul>
                {healthData[logType].map((logItem, index) => {
                    const isHovered = hoveredItem === logItem._id;

                    return (
                        <li
                            key={logItem._id}
                            className={cn(
                                'mb-4 rounded-lg border-2 bg-white px-4 py-4 transition-transform duration-300',
                                isHovered &&
                                    '-translate-y-1 transform shadow-lg'
                            )}
                            onMouseEnter={() => setHoveredItem(logItem._id)}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            <div className="info">
                                {
                                    <RenderLog
                                        logType={logType}
                                        log={logItem}
                                        isHovered={isHovered}
                                        index={index}
                                    />
                                }
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default LogSection;
