import React from 'react'
import cn from '../../components/ui/utils/cn'

/**
 * Droplet icon component.
 * @param {object} props - The component props.
 * @param {string} props.className - The additional class name for the icon.
 * @returns {React.JSX.Element} - The rendered Droplet icon component.
 */
export default function DropletIcon({className}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
        width="29" 
        height="35" 
        viewBox="0 0 29 35" 
        role="img"
        className={cn("w-4 h-4", className)}
        fill="none"
    >
        <path d="M26.9999 20.8332C26.9999 14.1666 15.5417 1.14569 14.6041 0.104004C14.6041 0.104004 2 13.9581 2 20.8332C2 27.7082 7.62505 33.3331 14.5 33.3331C14.9166 33.3331 15.4375 33.3331 15.8541 33.229C15.9583 33.229 16.0624 33.229 16.1666 33.1248C16.4791 33.1248 16.7917 33.0207 17.1041 33.0207C17.2083 33.0207 17.3124 32.9165 17.5208 32.9165C17.8332 32.8124 18.1458 32.8124 18.4583 32.7082C18.5624 32.7082 18.6666 32.6041 18.7707 32.6041C19.0832 32.4999 19.3958 32.3957 19.7083 32.2916C19.8124 32.2916 19.9166 32.1874 19.9166 32.1874C20.2291 32.0833 20.5416 31.875 20.8541 31.6666C20.9583 31.6666 20.9583 31.5625 21.0624 31.5625C21.3749 31.3542 21.6875 31.1459 22 30.9374C22 30.9374 22.1041 30.9374 22.1041 30.8333C22.4166 30.625 22.7292 30.3125 23.0416 30.1041C25.4375 27.7082 26.9999 24.479 26.9999 20.8332Z" fill="#76CBEA"/>
        <path d="M15.5415 32.2916C8.66649 32.2916 3.04157 26.6666 3.04157 19.7916C3.04157 14.0624 11.5832 3.54168 14.604 0.104157L14.5 0C14.5 0 2 13.9583 2 20.8333C2 27.7084 7.62505 33.3333 14.5 33.3333C18.25 33.3333 21.5833 31.6667 23.875 29.1667C21.5831 31.0416 18.7706 32.2916 15.5415 32.2916Z" fill="#5FA4D6"/>
    </svg>)
}
