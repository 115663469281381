import React, { useState } from 'react';
import MoodChart from '../Charts/MoodChart';
import Button from '../ui/Button/Button';
import WaterChart from '../Charts/WaterChart';
import SleepChart from '../Charts/SleepChart';
import PainChart from '../Charts/PainChart';
import SymptomChart from '../Charts/SymptomChart';

const insightComponents = {
    'Mood Intensity': MoodChart,
    'Sleep Quality': SleepChart,
    'Water Intake': WaterChart,
    'Pain Intensity': PainChart,
    'Symptoms': SymptomChart
};
const insightButtons = [
    { label: 'Mood', insight: 'Mood Intensity' },
    { label: 'Sleep', insight: 'Sleep Quality' },
    { label: 'Water', insight: 'Water Intake' },
    { label: 'Pain', insight: 'Pain Intensity' },
    { label: 'Symptoms', insight: 'Symptoms' }
];

export default function HealthInsights() {
    const [selectedInsight, setSelectedInsight] = useState('Mood Intensity');
    const InsightComponent = insightComponents[selectedInsight];

    return (
        <div>
            <h1 className="small-title tracking-wide ">
                Health Insights: {selectedInsight}
            </h1>
            <div className="flex flex-col min-h-64 justify-center">
                <InsightComponent />
            </div>

            <div className="grid grid-cols-4 gap-2 w-full">
                {insightButtons.map(button => (
                    <Button
                        key={button.insight}
                        variant="secondary"
                        className="w-full py-2 px-0"
                        onClick={() => setSelectedInsight(button.insight)}
                    >
                        <span className="text-sm">{button.label}</span>
                    </Button>
                ))}
            </div>
        </div>
    );
}
