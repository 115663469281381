export const funFacts = [
  { fact: "Nurses play a central role as the main caregivers for patients in hospitals.", source: "barton.edu" },
  { fact: "Nurses represent the largest workforce segment within hospitals, according to the AACN.", source: "barton.edu" },
  { fact: "The origins of occupational therapy (OT) can be traced back to 100 BC.", source: "barton.edu" },
  { fact: "OT practices were first implemented by Asclepiades, a Greek physician, for treating mental illness.", source: "barton.edu" },
  { fact: "Your nails are like tiny health detectives—they can reveal secrets about your blood oxygen levels and circulation. Who knew those mini canvases had so much to say?", source: "barton.edu" },
  { fact: "The average age of registered nurses is 52, meaning there are many exciting opportunities for new nurses to step in and lead the way!", source: "barton.edu" },
  { fact: "BSNs are increasingly sought after, with many RN job postings specifying 'BSN preferred.'", source: "barton.edu" },
  { fact: "Physical therapy emerged during WWI when the army employed nurses as reconstruction aides to assist wounded soldiers with rehabilitation.", source: "barton.edu" },
  { fact: "The senior population in the US is set to reach 69 million by 2030 and 88.5 million by 2050, providing a great opportunity to innovate and care for this growing community!", source: "barton.edu" },
  { fact: "Occupational therapy officially began in 1917, with its founding members forming the first OT practice three years before women gained the right to vote.", source: "barton.edu" },
  { fact: "The lymphatic system is like a gym buff—it relies on physical activity to get moving, unlike your circulatory system that’s all heart-driven.", source: "barton.edu" },
  { fact: "Physical therapy was initially a female-only profession, with the first professional PT association in the US, led by Mary McMillan, founded in 1921.", source: "barton.edu" },
  { fact: "The idea of hand washing before surgery was initially ridiculed in the mid-1800s when Dr. Ignaz Semmelweis proposed it.", source: "barton.edu" },
  { fact: "Your body is basically a 24/7 factory, cranking out about 3.8 million new cells every second. Talk about speedy service!", source: "barton.edu" },
  { fact: "Healthcare is the fastest-growing industry in the US, with solid job security expected for the foreseeable future.", source: "barton.edu" },
  { fact: "Beard hair grows faster than any other hair. If you never trimmed it, it could grow to almost 30 feet long! That’s like having Rapunzel’s tower on your face.", source: "hartfordhealthcare.org" },
  { fact: "Everyone has a unique scent, except for identical twins, who smell the same. Twins: same face, same vibes!", source: "hartfordhealthcare.org" },
  { fact: "A human fetus develops fingerprints by three months, making each person unique from the start.", source: "hartfordhealthcare.org" },
  { fact: "Like fingerprints, each person has a unique tongue print that can be used for identification.", source: "hartfordhealthcare.org" },
  { fact: "The middle finger's nail grows the fastest among all fingers.", source: "hartfordhealthcare.org" },
  { fact: "Studies suggest that people who frequently dream and have vivid dreams tend to have higher IQs.", source: "hartfordhealthcare.org" },
  { fact: "Sneezes can exceed 100 mph, while coughs typically reach around 60 mph.", source: "hartfordhealthcare.org" },
  { fact: "You can't tickle yourself because you know the exact time and method of the tickle, unlike when someone else does it.", source: "hartfordhealthcare.org" },
  { fact: "Your nose can remember around 50,000 different scents, making familiar smells like cookies evoke strong memories.", source: "hartfordhealthcare.org" },
  { fact: "Humans shed approximately 600,000 skin particles per hour, totaling about 1.5 pounds annually.", source: "hartfordhealthcare.org" },
  { fact: "Nearly half of all teenagers are sleep-deprived, even though they seem energetic.", source: "hartfordhealthcare.org" },
  { fact: "We spend about 10% of our waking hours blinking, which happens 10-20 times per minute.", source: "hartfordhealthcare.org" },
  { fact: "Your heartbeat syncs with the music you listen to, slowing down or speeding up depending on the tempo. It’s like your heart’s got rhythm!", source: "hartfordhealthcare.org" },
  { fact: "Athazagoraphobia is the fear of being forgotten or ignored.", source: "hartfordhealthcare.org" },
  { fact: "The word 'muscle' comes from a Latin term meaning 'little mouse,' as the Romans thought flexed biceps resembled a mouse.", source: "natgeokids.com" },
  { fact: "Human teeth are as strong as shark teeth.", source: "sciencemag.org" },
  { fact: "Nurses constitute the largest segment of hospital staff and are the primary caregivers for patients.", source: "aacnnursing.org" },
  { fact: "Job ads for registered nurses increasingly favor candidates with a BSN, making it an important credential for career advancement.", source: "aacnnursing.org" },
  { fact: "Occupational therapy was established in 1917 by a group of three men and three women, three years before women had the right to vote.", source: "napacenter.org" },
  { fact: "OT techniques have been around since 100 BC, when the Greek physician Asclepiades used them to treat mental illnesses.", source: "bchcares.org" },
  { fact: "Eleanor Roosevelt attended the 21st Annual Meeting of the American Occupational Therapy Association, honoring Eleanor Clarke Slagle, a key figure in OT history.", source: "lewisu.edu" },
  { fact: "Physical therapy began during WWI, with nurses, known as reconstruction aides, helping soldiers with rehabilitation through both physical and occupational therapy.", source: "orthocarolina.com" },
  { fact: "Physical therapy started as an all-female profession, with the first PT association in the US, led by Mary McMillan, established in 1921.", source: "apta.org" },
  { fact: "Research suggests that cold temperatures may help reduce allergies, inflammation, and improve mental clarity.", source: "rd.com" },
  { fact: "Optimism has been linked to lower risks of death from cancer, disease, infection, and stroke.", source: "nytimes.com" },
  { fact: "The American Speech-Language-Hearing Association (ASHA) was founded in 1925 at a meeting of the National Association of Teachers of Speech in New York City.", source: "bchcares.org" },
  { fact: "Singing is linked to the right hemisphere of the brain, while speaking is controlled by the left hemisphere, which may explain why some stroke survivors can still sing.", source: "discovermagazine.com" },
  { fact: "Some throat singers can produce up to four tones at once.", source: "discovermagazine.com" },
  { fact: "The body's smallest bones are located in the middle ear: the malleus, incus, and stapes.", source: "entandaudiology.com" },
  { fact: "Around 15 to 25 percent of Americans are 'supertasters,' meaning they have more taste buds than the average person.", source: "discovermagazine.com" },
  { fact: "Each person's tongue print is unique, just like fingerprints.", source: "hartfordhealthcare.org" },
  { fact: "It's impossible to tickle yourself because you can predict the sensation.", source: "hartfordhealthcare.org" },
  { fact: "Watching yourself in a mirror while on a treadmill can make the workout feel quicker and easier. Maybe it’s because your reflection is cheering you on!", source: "health.com" },
  { fact: "Your belly button is a bustling city for bacteria—2,400 species to be exact. It's like a microscopic metropolis hiding in plain sight.", source: "webmd.com" },
  { fact: "Surprise! Your finger movements are all thanks to muscles in your forearm, not the fingers themselves. It's like having a puppet show in your arm.", source: "deserthandandpt.com" },
  { fact: "There’s no one-size-fits-all for sleep — some people snooze like bears, others are up all the time, but scientists are still scratching their heads on the *why*.", source: "Touro SHS" }
];
