import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { resetAdminUserPassword } from '../../api/adminAPI';
import Button from '../../components/ui/Button/Button';
import { StandardInput } from '../../components/ui/Inputs/StandardInput';

const schema = yup
    .object({
        password: yup.string().min(5).required(),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required(),
    })
    .required();

/**
 * Renders the AdminResetPassword page where the admin can reset their password.
 * @returns {React.JSX.Element} The rendered AdminResetPassword page.
 */
export default function AdminResetPassword() {
    const { token } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [submitError, setSubmitError] = useState('');

    const onSubmit = async (data) => {
        try {
            const { password, confirmPassword } = data;
            await resetAdminUserPassword({
                password,
                confirmPassword,
                token,
            });
            navigate('/admin/login');
        } catch (err) {
            setSubmitError(err.message || 'Something went wrong. Try again');
        }
    };
    return (
        <div className="flex h-screen flex-col items-center justify-center gap-4 md:-mt-20">
            <div className="w-full max-w-2xl px-4">
                <h1 className="text-3xl font-bold md:text-5xl ">
                    Reset Password
                </h1>
                <p className="mt-2 text-lg text-gray-600">
                    Please enter your new password below.
                </p>
            </div>
            <div className="w-full max-w-2xl px-4">
                <form
                    className="mb-4 rounded-lg bg-white px-8 py-16 text-black shadow-md"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="mb-4">
                        <StandardInput
                            label="Password"
                            id="password"
                            type="password"
                            placeholder="******************"
                            error={errors.password}
                            errorText={
                                errors.password?.message ||
                                'Please enter a valid password'
                            }
                            register={register}
                        />
                    </div>
                    <div className="relative mb-4">
                        <StandardInput
                            label="Confirm Password"
                            id="confirmPassword"
                            type="password"
                            placeholder="******************"
                            error={errors.confirmPassword}
                            errorText={
                                errors.confirmPassword?.message ||
                                'Please enter a valid password'
                            }
                            register={register}
                        />
                    </div>
                    {submitError && (
                        <div className="error-text mb-4 flex items-center justify-center gap-2 rounded-lg bg-red-100 px-4 py-2 font-medium">
                            <ExclamationCircleIcon className="h-5 w-5" />
                            <p>{submitError}</p>
                        </div>
                    )}
                    <Button
                        className="mx-auto mt-4 w-full"
                        type="submit"
                        disabled={!isValid}
                        isLoading={isSubmitting}
                    >
                        Reset Password
                    </Button>
                </form>
            </div>
        </div>
    );
}
