import * as yup from 'yup';


export const resetPasswordSchema = yup.object().shape({
  password: yup
      .string()
      .matches(new RegExp('^(?=.*[a-z])'), 'At least one letter is required') // At least one lowercase letter
      .matches(
          new RegExp('^(?=.*[A-Z])'),
          'At least one uppercase letter is required'
      ) // At least one uppercase letter
      .matches(new RegExp('^(?=.*\\d)'), 'At least one digit is required') // At least one digit
      .matches(
          new RegExp('^(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]'),
          'At least one special character is required'
      ) // At least one special character
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
});