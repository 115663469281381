import { createContext, useContext, useState } from 'react';
import {
    adminLoginAPI,
    adminLogoutAPI,
    verifyAdminSession,
} from '../api/adminAPI';

export const AdminAuthContext = createContext();

export const useAdminAuth = () => useContext(AdminAuthContext);

export const AdminAuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState({});

    const verifySession = async () => {
        try {
            await verifyAdminSession();
            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
        }
    };

    const login = async (data) => {
        try {
            const response = await adminLoginAPI(data);
            setIsAuthenticated(true);
            console.log(response);
            setUser(response);
        } catch (error) {
            setIsAuthenticated(false);
            setUser({});
            throw error;
        }
    };

    const logout = async () => {
        try {
            await adminLogoutAPI();
            setIsAuthenticated(false);
            setUser({});
        } catch (error) {
            setIsAuthenticated(false);
            setUser({});
        }
    };

    return (
        <AdminAuthContext.Provider
            value={{
                isAuthenticated,
                verifySession,
                login,
                logout,
                user,
            }}
        >
            {children}
        </AdminAuthContext.Provider>
    );
};
