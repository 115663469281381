import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../ui/Button/Button';
import { sendFeatureRequestAPI } from '../../api/supportAPI';
import { useToast } from '../../context/ToastContext';
import { GamifiedStandardSelect } from '../ui/Inputs/GamifiedStandardSelect';
import { GamifiedStandardInput } from '../ui/Inputs/GamifiedStandardInput';
import { GamifiedStandardTextArea } from '../ui/Inputs/GamifiedStandardTextArea';
import requestMascot from '../../assets/mascots/RocketMascot.gif';

const typeOptions = [
    {
        value: 'Feature',
        label: 'Feature',
    },
    {
        value: 'Element',
        label: 'Element',
    },
    {
        value: 'Unit',
        label: 'Unit',
    },
    {
        value: 'Quest',
        label: 'Quest',
    },
    {
        value: 'Reward',
        label: 'Reward',
    },
];

/**
 * Renders the FeatureRequestForm component.
 * @param {object} props The props passed to the component.
 * @param {('Reward' | 'Quest' | 'Unit' | 'Element'| 'Feature')} props.type The type of the feature request.
 * @param {Function} props.setIsOpen The function to set the open state of the form.
 * @returns {React.JSX.Element} The rendered FeatureRequestForm component.
 */
export default function FeatureRequestForm({ type, setIsOpen }) {
    const { addToast } = useToast();
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: type
            ? {
                  type: type,
              }
            : undefined,
    });
    const onSubmit = async (data) => {
        try {
            await sendFeatureRequestAPI(data);
            setIsOpen(false);
            addToast({
                variant: 'success',
                title: 'Feature request submitted',
                description: 'Thank you for your feedback!',
            });
        } catch (error) {
            addToast({
                variant: 'error',
                title: 'Failed to submit feature request',
                description: 'Please try again later.',
            });
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name={'type'}
                control={control}
                defaultValue={typeOptions[0].value}
                rules={{
                    required: 'This field is required',
                }}
                render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                }) => (
                    <GamifiedStandardSelect
                        id={'type'}
                        required={true}
                        label={'Type'}
                        options={typeOptions}
                        value={value}
                        onChange={(selectedOption) => onChange(selectedOption)}
                        onBlur={onBlur}
                        reference={ref}
                        error={Boolean(error)}
                        errorText={error?.message}
                    />
                )}
            />
            <Controller
                name={'title'}
                control={control}
                rules={{
                    required: 'Title is required',
                }}
                defaultValue=""
                render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                }) => (
                    <GamifiedStandardInput
                        type="text"
                        required={true}
                        id={'title'}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        reference={ref}
                        placeholder={'Title'}
                        label={'Title'}
                        error={Boolean(error)}
                        errorText={error?.message}
                    />
                )}
            />
            <Controller
                name={'description'}
                control={control}
                rules={{
                    required: 'This field is required',
                }}
                defaultValue={''}
                render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                }) => (
                    <GamifiedStandardTextArea
                        id={'description'}
                        required={true}
                        label={'Description'}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        reference={ref}
                        error={Boolean(error)}
                        errorText={error?.message}
                        placeholder={'Description'}
                    />
                )}
            />
           <div className="my-4 w-full text-center">
                    <Button type="submit" isLoading={isSubmitting}>
                        Submit
                    </Button>
                    <img src={requestMascot} alt="Mascot" className="absolute -bottom-4 right-20 pointer-events-none w-24 h-24" />
            </div>
        </form>
    );
}
