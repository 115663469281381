import React from 'react';
import Button from '../../components/ui/Button/Button';
import { Link } from 'react-router-dom';

/**
 * Renders the InviteWarning page that warns users they should be invited before they can sign up.
 * @returns {React.JSX.Element} The rendered InviteWarning page.
 */
export default function InviteWarning() {
    return (
        <div className="mx-auto -mt-10 flex min-h-screen max-w-3xl items-center px-4">
            <div>
                <h1 className="mb-10 text-4xl font-extrabold tracking-wide">
                    Welcome to Our Platform!
                </h1>
                <p className="mt-4 text-xl font-medium ">
                    We&apos;re currently in a beta phase and operating on an
                    invite-only basis.
                </p>
                <p className="mt-4 text-xl font-medium ">
                    If you&apos;ve already received an invite, please proceed to
                    sign up. We&apos;re excited to have you join us!
                </p>
                <p className="mt-4 text-xl font-medium ">
                    If you haven&apos;t received an invite yet, we encourage you
                    to request access. We&apos;ll review your request and get
                    back to you as soon as possible.
                </p>
                <div className=" mt-10 flex justify-between">
                    <Button variant="secondary">
                        <Link to="/">Request Access</Link>
                    </Button>
                    <Button variant="secondary">
                        <Link to="/sign-up">Sign Up</Link>
                    </Button>
                </div>
            </div>
        </div>
    );
}
