import { createStripePortalAPI } from '../../api/stripeAPI';
import Button from '../ui/Button/Button';

const StripeCustomerPortal = () => {
    const openStripePortal = async () => {
        try {
            const { url } = await createStripePortalAPI();
            window.location.href = url; // Redirect to the billing portal
        } catch (error) {
            console.log(
                error.message ||
                    'Failed to create portal session. Try again later'
            );
        }
    };

    return (
        <Button
            onClick={openStripePortal}
            type="submit"
            className="mt-4 w-full"
        >
            Manage Billing
        </Button>
    );
};
export default StripeCustomerPortal;
