import React, { useEffect, useState } from 'react';
import cn from '../utils/cn';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

const variantClasses = {
    success: 'bg-green-200 text-green-800',
    error: 'bg-red-200 text-red-800',
    standard: 'bg-sapphireBlue-100',
    standardWithIcon: 'bg-sapphireBlue-100 flex items-start space-x-2',
};

/**
 * Toast component displays a notification with a title and description.
 * @param {object} props - The component props.
 * @param {string} props.id - The unique identifier for the toast.
 * @param {string} props.title - The title of the toast.
 * @param {string} props.description - The description of the toast.
 * @param {'success' | 'error' | 'standard'} [props.variant] - The variant of the toast (success, error, standard). Defaults to 'standard'.
 * @param {number} [props.duration] - The duration in milliseconds for which the toast should be displayed. Defaults to 5000ms.
 * @param {Function} props.removeToast - The function to remove the toast from the UI.
 * @returns {React.JSX.Element} The rendered Toast component.
 */
export default function Toast({
    id,
    title,
    description,
    variant = 'standard',
    duration = 5000,
    removeToast,
}) {
    const [isPaused, setIsPaused] = useState(false);
    const [show, setShow] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isPaused) removeToast(id);
        }, duration);

        return () => clearTimeout(timer);
    }, [id, isPaused, removeToast, duration]);

    const closeToast = () => {
        setShow(false);
        removeToast(id);
    };

    return (
        <Transition
            show={show}
            enter="transition ease-out duration-300"
            enterFrom="transform opacity-0 translate-y-2"
            enterTo="transform opacity-100 translate-y-0"
            leave="transition ease-in duration-300"
            leaveFrom="transform opacity-100 translate-y-0"
            leaveTo="transform opacity-0 translate-y-2"
            beforeLeave={() => removeToast(id)}
        >
            <div
                className={cn(
                    'cursor-pointer rounded-xl p-4 shadow-lg',
                    variantClasses[variant]
                )}
                onMouseEnter={() => setIsPaused(true)}
                onMouseLeave={() => setIsPaused(false)}
                onFocus={() => setIsPaused(true)}
                onBlur={() => setIsPaused(false)}
                role="alert"
                aria-live="assertive"
            >
                <button onClick={() => closeToast()} className="float-right">
                    <XMarkIcon className="h-6 w-6" />
                </button>
                <strong>{title}</strong>
                {description && <p>{description}</p>}
            </div>
        </Transition>
    );
}
