import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateYupSchema } from '../../../../components/ui/utils/generateYupSchema';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitMedicationLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';

import {
    inputOptions,
    layoutOptions,
} from '../../../../shared/util/formConfig';

const doseUnitEnum = ['mg', 'g', 'IU', 'mL'];
const adherenceEnum = ['compliant', 'non-compliant', 'not-taking'];
const medicationStatusEnum = [
    'active',
    'completed',
    'on-hold',
    'cancelled',
    'stopped',
];
const medicationFillStatusEnum = [
    'dispensed',
    'not-dispensed',
    'partially-dispensed',
];

const config = {
    title: 'Medication Log',
    description:
        'Track your medication intake, including dosage, frequency, adherence, and other relevant details.',
    fields: [
        {
            name: 'date',
            label: 'Log Date:',
            type: 'date',
            required: true,
            max: new Date(),
        },
        {
            name: 'medication',
            type: inputOptions.comboBox,
            endpoint: 'medication',
            label: 'Medication Name',
            placeholder: 'Enter medication name',
            required: true,
        },
        {
            name: 'dose',
            label: 'Dose',
            placeholder: 'Enter Dose',
            type: layoutOptions.group, // Group layout for value and unit
            required: false,
            subFields: [
                {
                    name: 'value',
                    label: 'Value',
                    placeholder: 'Enter Value',
                    type: inputOptions.number, // Number input for dose value
                    required: false,
                },
                {
                    name: 'unit',
                    label: 'Unit',
                    placeholder: 'Select Unit',
                    type: inputOptions.select, // Select input for dose unit
                    required: false,
                    options: doseUnitEnum.map((unit) => ({
                        value: unit,
                        label: unit,
                    })),
                },
            ],
        },
        {
            name: 'indications',
            type: layoutOptions.dynamic,
            label: 'Indications',
            required: true,
            subFields: [
                {
                    name: 'indication',
                    type: inputOptions.comboBox,
                    endpoint: 'snomed/clinical-finding',
                    label: 'Reason for medication',
                    placeholder: 'Enter indication',
                    required: true,
                },
            ],
        },
        {
            name: 'prescribedOn',
            label: 'Prescribed On',
            placeholder: 'Enter Prescribed Date',
            type: inputOptions.date, // Date input for prescription date
            required: false,
        },
        {
            name: 'status',
            label: 'Status',
            placeholder: 'Select Status',
            type: inputOptions.select, // Select input for medication status
            required: true,
            options: medicationStatusEnum.map((status) => ({
                value: status,
                label: status
                    .split('-')
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(' '), // Capitalize each word in status
            })),
        },
        {
            name: 'fillStatus',
            label: 'Fill Status',
            placeholder: 'Select Fill Status',
            type: inputOptions.select, // Select input for fill status
            required: true,
            options: medicationFillStatusEnum.map((status) => ({
                value: status,
                label: status
                    .split('-')
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(' '), // Capitalize each word in fill status
            })),
        },
        {
            name: 'instructions',
            label: 'Instructions',
            placeholder: 'Enter Instructions',
            type: inputOptions.textarea, // Textarea for additional instructions
            required: false,
        },
        {
            name: 'adherence',
            label: 'Adherence',
            placeholder: 'Select Adherence',
            type: inputOptions.select, // Select input for medication adherence
            required: true,
            options: adherenceEnum.map((status) => ({
                value: status,
                label: status
                    .split('-')
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(' '), // Capitalize each word in adherence
            })),
        },
        {
            name: 'route',
            label: 'Route',
            placeholder: 'Select Route',
            type: inputOptions.comboBox,
            endpoint: 'route-of-administration',
            required: false,
        },
        {
            name: 'notes',
            label: 'Additional Notes',
            type: inputOptions.textarea, // Textarea for additional notes
            required: false,
        },
    ],
};

/**
 * MedicationLog component renders a dynamic form to log medication intake.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function MedicationLog() {
    const navigate = useNavigate();
    const { addToast } = useToast();

    const onFormSubmission = async (data) => {
        // Ensure indications are an array
        if (data.indications && !Array.isArray(data.indications)) {
            data.indications = [data.indications];
        }

        try {
            await submitMedicationLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description:
                    'Unable to submit medication log. Please try again later.',
                variant: 'error',
            });
        }
    };

    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={generateYupSchema(config.fields)}
            onFormSubmission={onFormSubmission}
            logType={config.title}
        />
    );
}
