import cn from '../ui/utils/cn';

const ProgressBar = ({ currentStep, totalSteps, className }) => {
    const progressPercentage = ((currentStep / totalSteps) * 100).toFixed(0);
    return (
        <div className={cn("w-full bg-gray-200 h-min rounded-full", className)}>
            <div
                className="bg-green-500 p-2.5 leading-none rounded-full transition-all duration-500"
                style={{ width: `${progressPercentage}%` }}
            >
            </div>
        </div>
    );
};

export default ProgressBar;