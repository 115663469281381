import React, { useState, useEffect } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { fetchMoodInsightsAPI } from '../../api/healthLogsAPI';

const moodIntensities = {
    5: 'Happy, Excited',
    4: 'Excited',
    3: 'Anxious',
    2: 'Angry, Stressed, Tired',
    1: 'Sad, Tired',
};

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const mood = moodIntensities[payload[0].value];
        return (
            <div className="rounded-lg border border-gray-200 bg-purple-100 p-4 shadow-sm">
                <p className="paragraph">{`Intensity ${payload[0].value}: ${mood || 'No mood data'}`}</p>
            </div>
        );
    }

    return null;
};

const renderColorfulLegendText = () => {
    return (
        <span style={{ color: '#8884d8', fontWeight: 'bold' }}>
            Average Intensity
        </span>
    );
};

/**
 * MoodChart component fetches mood data from the server and displays it in a line chart.
 * @returns {React.JSX.Element} - MoodChart component
 */
export default function MoodChart() {
    const [moodData, setMoodData] = useState([]);

    useEffect(() => {
        const fetchMoodData = async () => {
            try {
              const data = await fetchMoodInsightsAPI();
                setMoodData(
                    data.map((item) => ({
                        ...item,
                        date: item._id,
                        averageIntensity: item.averageIntensity,
                    }))
                );
            } catch (error) {
                console.error('Failed to fetch mood data', error);
            }
        };

        fetchMoodData();
    }, []);

    return (
        <div>
            {moodData.length > 0 ? (
                <div className="-ml-10 mt-4">
                    <ResponsiveContainer width="100%" aspect={1.5}>
                        <LineChart data={moodData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                height={60}
                                label={{
                                    value: 'Date',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    fill: '#8884d8',
                                }}
                                stroke="#8884d8"
                                strokeWidth={2}
                                tick={{ fontSize: 14, fontWeight: 'bold' }}
                            />
                            <YAxis
                                domain={[0, 5]}
                                label={{
                                    value: 'Mood Intensity',
                                    angle: -90,
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    fill: '#8884d8',
                                }}
                                stroke="#8884d8"
                                strokeWidth={2}
                                tick={{ fontSize: 14, fontWeight: 'bold' }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend formatter={renderColorfulLegendText}
                            wrapperStyle={{left: '10%', bottom: 20}} />
                            <Line
                                type="monotone"
                                dataKey="averageIntensity"
                                stroke="#8884d8"
                                strokeWidth={2}
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <p className="paragraph">No mood data available for the past week.</p>
            )}
        </div>
    );
}
