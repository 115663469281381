import React from 'react';
import cn from '../../ui/utils/cn';

const variantClasses = {
    selected:
        'border-pictonBlue-500 bg-pictonBlue-200 text-gray-700 hover:bg-lightblue-400 border-pictonBlue-500 shadow-[0_5px_0_0_#3D9ECE] hover:shadow-[0_9px_0_0_#3D9ECE]',
    notSelected:
        'bg-white hover:bg-gray-100 border-gray-300 text-[#777777]  shadow-[0_3px_0_0_#d1d5db] hover:shadow-[0_6px_0_0_#d1d5db]',
};

/**
 * Renders a navigation button with customizable styles and states.
 * @param {object} props - Component props.
 * @param {React.ReactNode} props.children - Content to be displayed within the button.
 * @param {boolean} [props.isSelected=false] - Whether the button is selected.
 * @param {string} [props.className] - Additional CSS classes for the button.
 * @returns {React.JSX.Element} The rendered navigation button.
 */
export default function NavigationButton({
    children,
    isSelected = false,
    className = '',
    ...props
}) {
    const baseClasses =
        'border-2 py-1 px-3 text-sm justify-center font-bold rounded-xl transition-all ease-in-out duration-100 active:shadow-sm flex items-center active:translate-y-1';

    const buttonClasses = cn(
        baseClasses,
        isSelected ? variantClasses.selected : variantClasses.notSelected,
        className,
        !props.disabled && 'hover:-translate-y-1'
    );

    return (
        <button className={buttonClasses} {...props} disabled={props.disabled}>
            {children}
        </button>
    );
}
