

export const sendQuestInterestFormAPI = async (formData) => {
    const response = await fetch('/api/quest/interest', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    });
    if (!response.ok) throw new Error('Unable to submit Interest Form. Try again.');
    return await response.json();
}

export const checkEligibilityForQuestAPI = async () => {
    const response = await fetch('/api/quest/is-eligible', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error('Unable to check eligibility. Try again.');
    return await response.json();
}