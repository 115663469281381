import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToast } from '../../../context/ToastContext';
import { requestEarlyAccessAPI } from '../../../api/userAPI';
import Button from '../../../components/ui/Button/Button';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { formatPhoneNumber } from '../../../shared/util/formatPhoneNumber';
// import { formatDate } from '../../../shared/util/formatDate';
import { useParams, useSearchParams } from 'react-router-dom';
import { StandardInput } from '../../../components/ui/Inputs/StandardInput';

import MailChimpModal from './MailChimpModal';
import Modal from '../../../components/DialogModal/GamifiedModal';

const schema = yup
    .object({
        fullName: yup
            .string()
            .matches(/^[a-zA-Z  -]+$/, 'Name must contain only letters.')
            .required('Name is required.'),
        email: yup
            .string()
            .email()
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                'Please enter a valid email address.'
            )
            .required('Email is required.'),
        phoneNumber: yup
            .string()
            .matches(
                /^\(\d{3}\) \d{3}-\d{4}$/,
                'Invalid phone number format (e.g., (123) 456-7890).'
            )
            .required('Phone number is required.'),
        // birthday: yup
        //     .string()
        //     .matches(
        //         /^\d{2}\/\d{2}\/\d{4}$/,
        //         'Invalid date format (e.g., 01/01/2000).'
        //     )
        //     .required('Date of birth is required.')
        //     .test(
        //         'is-older-than-13',
        //         'You must be at least 13 years old.',
        //         function (value) {
        //             const today = new Date();
        //             const birthDate = new Date(value);
        //             let age = today.getFullYear() - birthDate.getFullYear();
        //             const m = today.getMonth() - birthDate.getMonth();
        //             if (
        //                 m < 0 ||
        //                 (m === 0 && today.getDate() < birthDate.getDate())
        //             ) {
        //                 age--;
        //             }
        //             return age >= 13;
        //         }
        //     ),
    })
    .required();

const emailSchema = yup.object().shape({
    email: yup.string().email('Invalid email format'),
});

/**
 * Renders the EarlyAccessForm component.
 * @returns {React.JSX.Element} The EarlyAccessForm component.
 */
export default function EarlyAccessForm() {
    const [searchParams] = useSearchParams();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState('');
    const [submittedName, setSubmittedName] = useState('');
    const { addToast } = useToast();
    const { inviteCode } = useParams();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });
    const onSubmit = async (data) => {
        try {
            let emailReferrer = searchParams.get('emailReferrer');
            const email = data.email.toLowerCase();
            if (emailReferrer) {
                //validate if email
                emailReferrer = emailReferrer.toLowerCase();
                await emailSchema.validate({ email: emailReferrer });
            }
            if (email === emailReferrer) {
                emailReferrer = null;
            }
            // const birthday = new Date(data.birthday).toISOString();
            await requestEarlyAccessAPI({
                fullName: data.fullName,
                email,
                phoneNumber: data.phoneNumber,
                // birthday,
                inviteCode,
                emailReferrer,
            });
            addToast({
                title: 'Request sent successfully',
                description:
                    'You will receive a email once you are granted access.',
                variant: 'success',
            });
            setSubmittedEmail(email);
            setSubmittedName(data.fullName);
            setModalIsOpen(true);
            reset();
        } catch (error) {
            if (error.name === 'ValidationError') {
                addToast({
                    title: 'Invalid link',
                    description: 'Please check the link and try again.',
                    variant: 'error',
                });
                return;
            }
            addToast({
                title: 'Oops! Something went wrong',
                description: error.message,
                variant: 'error',
            });
        }
    };
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex w-full max-w-md flex-col items-center gap-2 md:-mt-8"
        >
            <div className="flex w-full flex-col">
                <StandardInput
                    label="Name"
                    id="fullName"
                    type="text"
                    placeholder="Name"
                    error={errors.fullName}
                    errorText={errors.fullName?.message}
                    register={register}
                />
            </div>
            <div className="flex w-full flex-col">
                <StandardInput
                    label="Email"
                    id="email"
                    type="email"
                    placeholder="Email"
                    error={errors.email}
                    errorText={errors.email?.message}
                    register={register}
                />
            </div>
            <div className="flex w-full flex-col">
                <StandardInput
                    label="Phone Number"
                    id="phoneNumber"
                    type="tel"
                    placeholder="(123) 456-7890"
                    error={errors.phoneNumber}
                    errorText={errors.phoneNumber?.message}
                    register={register}
                    onChange={formatPhoneNumber}
                />
            </div>
            {/* <div className="flex w-full flex-col">
                <StandardInput
                    label="Date of Birth"
                    id="birthday"
                    type="text"
                    placeholder="MM/DD/YYYY"
                    error={errors.birthday}
                    errorText={errors.birthday?.message}
                    register={register}
                    onChange={formatDate}
                />
            </div> */}
            {/* Fine print text */}
            <p className="mt-4 text-center text-sm italic text-gray-500">
                <strong>Note:</strong> We need your phone number (no spam, we
                promise). Just making sure you&rsquo;re not an AI trying to take
                over the world!
            </p>

            <Button
                type="submit"
                disabled={!isValid}
                isLoading={isSubmitting}
                className="mt-6"
                icon={<ChevronRightIcon className="h-5 w-5 md:h-7 md:w-7" />}
            >
                Submit
            </Button>
            {modalIsOpen && (
                <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
                    <MailChimpModal
                        email={submittedEmail}
                        name={submittedName}
                        onClose={() => setModalIsOpen(false)}
                    />
                </Modal>
            )}
        </form>
    );
}
