import React from 'react';
import TransitionWrapper from './TransitionWrapper';

/**
 * Renders a water log entry with transition effects.
 * @param {object} props - The component props.
 * @param {object} props.waterLog - The water log data.
 * @param {boolean} props.isHovered - A boolean flag to control the transition visibility.
 * @param {number} props.index - The index of the water log entry.
 * @returns {React.ReactElement} The rendered component.
 */
const RenderWaterLog = ({ waterLog, isHovered, index }) => (
    <>
        <p className="font-bold">{`Consumed #${index + 1}`}</p>
        <p>{`Amount: ${waterLog.waterAmount.value} ${waterLog.waterAmount.unit}`}</p>
        <TransitionWrapper isHovered={isHovered}>
            <div className="mt-1">
                <p>{`Number of Times Consumed: ${waterLog.timesOfConsumption}`}</p>
            </div>
        </TransitionWrapper>
    </>
);

export default RenderWaterLog;
