import React from 'react';
import TransitionWrapper from './TransitionWrapper';
import getCaptializedString from '../../utils/getCaptializedString';

/**
 * Renders a symptom log entry with transition effects.
 * @param {object} props - The component props.
 * @param {object} props.symptomLog - The symptom log data.
 * @param {boolean} props.isHovered - A boolean flag to control the transition visibility.
 * @returns {React.JSX.Element} The rendered component.
 */
const RenderSymptomLog = ({ symptomLog, isHovered }) => (
    <>
        <p className="font-bold">{`Symptom: ${symptomLog.symptom}`}</p>
        <p>{`Severity: ${symptomLog.severity}/10`}</p>
        <TransitionWrapper isHovered={isHovered}>
            <div className="mt-1">
                <p>{`Frequency: ${symptomLog.frequency} times`}</p>
                <p>{`Associated Conditions: ${getCaptializedString(symptomLog.associatedConditions.join(', '))}`}</p>
            </div>
        </TransitionWrapper>
    </>
);

export default RenderSymptomLog;
