import { PlusCircleIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import FeatureRequestForm from '../Forms/FeatureRequestForm';
import Button from '../ui/Button/Button';
import GamifiedModal from './GamifiedModal';

/**
 * Renders the Feature Request Modal.
 * @param {object} props The component props.
 * @param {('Reward' | 'Quest' | 'Unit' | 'Element'| 'Feature')} props.type The type of the feature request.
 * @returns {React.JSX.Element} The rendered Feature Request Modal.
 */
const FeatureRequestModal = ({ type }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <>
            <Button
                variant="icon"
                icon={
                    <PlusCircleIcon className="h-8 w-8 rounded-full bg-white text-sapphireBlue-800" />
                }
                onClick={() => setModalIsOpen(true)}
            />
            <GamifiedModal
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                title={'Feature Request'}
            >
                <FeatureRequestForm setIsOpen={setModalIsOpen} type={type} />
            </GamifiedModal>
        </>
    );
};

export default FeatureRequestModal;
