import { useEffect, useState } from 'react';
import { fetchLogsAPI } from '../../api/healthLogsAPI';
import { useToast } from '../../context/ToastContext';

/**
 * Fetches the health data.
 * @param {Date} selectedDate The selected date.
 * @returns {object} The health data.
 */
const useFetchHealthData = (selectedDate) => {
    const { addToast } = useToast();
    const [healthData, setHealthData] = useState({});
    useEffect(() => {
        // fetch health data
        const fetchHealthData = async () => {
            try {
                const data = await fetchLogsAPI(
                    selectedDate.toISOString().slice(0, 10)
                );
                setHealthData(data.logs);
            } catch (error) {
                addToast({
                    variant: 'error',
                    title: 'Failed to fetch health data',
                    description: 'Please try again later.',
                });
            }
        };
        fetchHealthData();
    }, [selectedDate]);

    return healthData;
};

export default useFetchHealthData;
