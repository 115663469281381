import { Link } from 'react-router-dom';
import { LogoFull } from '../Logo/Logo';

const TopNavLogoOnly = () => {
    return (
        <nav className="flex justify-between items-center pt-3 px-3 md:px-10 md:pt-5">
            <Link to="/" className="flex gap-2 ml-1 items-center ps-2.5 ">
                <LogoFull />
            </Link>
            <div className="flex gap-5"></div>
        </nav>
    );
};

export default TopNavLogoOnly;
