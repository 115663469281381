import { LogoOnly } from '../../../components/Logo/Logo';
import StripeCustomerPortal from '../../../components/Stripe/StripeCustomerPortal';
import React from 'react';

/**
 * The user subscription component.
 * @returns {React.JSX.Element} The user subscription component.
 */
const UserSubscription = () => {
    return (
        <section className="mx-auto mt-10 max-w-4xl rounded-2xl border-2 bg-white p-6 shadow-md">
            <div className=" text-center md:text-left">
                <div className="mb-2 flex items-center gap-2">
                    <LogoOnly />
                    <h3 className="mt-4 text-xl font-bold text-gray-900 md:mt-0 md:text-2xl">
                        BlueFairy Infinity - Annual Subscription
                    </h3>
                </div>

                <p className="mt-2 text-gray-600">
                    Be the first to explore new features added to our platform.
                    Enjoy an uninterrupted, premium experience all year round.
                </p>
            </div>
            <div className="text-center">
                <StripeCustomerPortal />
            </div>
        </section>
    );
};

export default UserSubscription;
