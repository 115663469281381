import * as yup from 'yup';
import {
    ethnicityEnum,
    raceEnum,
    sexEnum,
    sexualOrientationEnum,
    genderIdentityEnum,
    FamilyMemberRelationshipEnum,
    conditionCategoryEnum,
    conditionClinicalStatusEnum,
    conditionVerificationStatusEnum,
    conditionSeverityEnum,
    procedureStatusEnum,
    doseUnitEnum,
    frequencyEnum,
    adherenceEnum,
    medicationStatusEnum,
    medicationFillStatusEnum,
} from './enums';

export const patientFormSchema = yup.object().shape({
    address: yup
        .object()
        .shape({
            line1: yup.string().optional().nullable(),
            line2: yup.string().optional().nullable(),
            city: yup.string().optional().nullable(),
            state: yup.string().optional().nullable(),
            zipCode: yup.string().optional().nullable(),
        })
        .optional(),
    demographics: yup
        .object()
        .shape({
            race: yup
                .array()
                .of(
                    yup.string().oneOf(
                        raceEnum.map((value) =>
                            value.toLowerCase().split(' ').join('-')
                        ),
                        'Invalid race'
                    )
                )
                .required('Race is required'),
            ethnicity: yup
                .string()
                .oneOf(
                    ethnicityEnum.map((value) =>
                        value.toLowerCase().split(' ').join('-')
                    ),
                    'Invalid ethnicity'
                )
                .required('Ethnicity is required'),
            tribalAffiliation: yup.string().optional().nullable(),
        })
        .optional(),
    identity: yup
        .object()
        .shape({
            sex: yup
                .string()
                .oneOf(
                    sexEnum.map((value) => value.toLowerCase()),
                    'Invalid sex'
                )
                .required('Sex is required'),
            sexualOrientation: yup
                .string()
                .oneOf(
                    sexualOrientationEnum.map((value) =>
                        value.toLowerCase().split(' ').join('-')
                    ),
                    'Invalid sexual orientation'
                )
                .required('Sexual orientation is required'),
            genderIdentity: yup
                .string()
                .oneOf(
                    genderIdentityEnum.map((value) =>
                        value.toLowerCase().split(' ').join('-')
                    ),
                    'Invalid gender identity'
                )
                .required('Gender identity is required'),
        })
        .optional(),
});
const allergyCategoryEnum = ['food', 'medication', 'environment', 'other'];
const allergyClinicalStatusEnum = ['active', 'inactive', 'resolved'];
const allergyVerificationStatusEnum = [
    'unconfirmed',
    'confirmed',
    'refuted',
    'entered-in-error',
];
const allergySeverityEnum = ['mild', 'moderate', 'severe'];

const isNoKnownAllergy = (substance) => {
    return (
        (typeof substance === 'string' &&
            substance.toLowerCase() === 'no known allergy') ||
        (typeof substance === 'object' &&
            substance.display &&
            substance.display.toLowerCase() === 'no known allergy (situation)')
    );
};

export const allergyFormSchema = yup.object().shape({
    allergy: yup
        .array()
        .of(
            yup.object().shape({
                category: yup
                    .string()
                    .oneOf([...allergyCategoryEnum])
                    .when('substance', {
                        is: (value) => !isNoKnownAllergy(value),
                        then: (schema) =>
                            schema.required(
                                'Category is required when substance is not "No known allergy"'
                            ),
                    }),
                substance: yup.mixed().required('Substance is required'),
                clinicalStatus: yup
                    .string()
                    .oneOf([...allergyClinicalStatusEnum])
                    .when('substance', {
                        is: (value) => !isNoKnownAllergy(value),
                        then: (schema) =>
                            schema.required(
                                'Clinical status is required when substance is not "No known allergy"'
                            ),
                    }),
                verificationStatus: yup
                    .string()
                    .oneOf([...allergyVerificationStatusEnum])
                    .when('substance', {
                        is: (value) => !isNoKnownAllergy(value),
                        then: (schema) =>
                            schema.required(
                                'Verification status is required when substance is not "No known allergy"'
                            ),
                    }),
                reaction: yup
                    .array()
                    .of(
                        yup.object().shape({
                            manifestation: yup
                                .mixed()
                                .required('Manifestation is required'),
                            severity: yup
                                .string()
                                .oneOf([...allergySeverityEnum])
                                .required('Severity is required'),
                        })
                    )
                    .when('substance', {
                        is: (value) => isNoKnownAllergy(value),
                        then: (schema) =>
                            schema
                                .max(
                                    0,
                                    "Reactions should not be present for 'No Known Allergy'"
                                )
                                .nullable(),
                        otherwise: (schema) =>
                            schema
                                .required()
                                .min(
                                    1,
                                    'Reaction is required when substance is not "No known allergy"'
                                ),
                    }),
            })
        )
        .min(
            1,
            'At least one allergy is required. If no allergies, add allergy and please select or enter "No known allergy" for substance'
        )
        .test(
            'no-known-allergies-exclusive',
            'There can\'t be multiple allergies if one allergy is "No known allergy"',
            function (value) {
                if (!value || value.length <= 1) return true;
                const hasNoKnownAllergies = value.some((allergy) =>
                    isNoKnownAllergy(allergy.substance)
                );
                return !hasNoKnownAllergies;
            }
        ),
});

export const familyHistorySchema = yup.object().shape({
    familyMembers: yup.array().of(
        yup.object().shape({
            name: yup.string().optional().nullable(),
            relationship: yup
                .string()
                .oneOf(Object.values(FamilyMemberRelationshipEnum)),
            age: yup
                .object()
                .shape({
                    value: yup
                        .number()
                        .transform((value) =>
                            isNaN(value) ? undefined : value
                        )
                        .optional(),
                    estimated: yup.boolean().optional(),
                })
                .optional()
                .nullable(),
            deceased: yup.boolean().optional().nullable(),
            sex: yup
                .string()
                .oneOf(sexEnum.map((value) => value.toLowerCase())),
            note: yup.string().optional().nullable(),
            conditions: yup.array().of(
                yup.object().shape({
                    name: yup.mixed().optional().nullable(),
                    outcome: yup.mixed().optional().nullable(),
                    contributedToDeath: yup.boolean().optional().nullable(),
                    onsetAge: yup
                        .object()
                        .shape({
                            value: yup
                                .number()
                                .transform((value) =>
                                    isNaN(value) ? undefined : value
                                )
                                .optional()
                                .nullable(),
                            estimated: yup.boolean().optional().nullable(),
                        })
                        .optional(),
                    note: yup.string().optional().nullable(),
                })
            ),
            procedures: yup
                .array()
                .of(
                    yup.object().shape({
                        name: yup.mixed().optional().nullable(),
                        outcome: yup.mixed().optional().nullable(),
                        contributedToDeath: yup.boolean().optional().nullable(),
                        performedAge: yup
                            .object()
                            .shape({
                                value: yup
                                    .number()
                                    .transform((value) =>
                                        isNaN(value) ? undefined : value
                                    )
                                    .optional()
                                    .nullable(),
                                estimated: yup.boolean().optional().nullable(),
                            })
                            .optional()
                            .nullable(),
                        note: yup.string().optional().nullable(),
                    })
                )
                .optional()
                .nullable(),
        })
    ),
});

export const conditionFormSchema = yup.object().shape({
    conditions: yup
        .array()
        .of(
            yup.object().shape({
                category: yup.string().oneOf(conditionCategoryEnum).optional(),
                condition: yup
                    .mixed()
                    .test(
                        'is-string-or-object',
                        'Name must be a string or an object',
                        (value) =>
                            typeof value === 'string' ||
                            typeof value === 'object'
                    )
                    .required('Condition is required'),
                note: yup.string().optional(),
                clinicalStatus: yup
                    .string()
                    .oneOf(conditionClinicalStatusEnum)
                    .required('Clinical status is required'),
                verificationStatus: yup
                    .string()
                    .oneOf(conditionVerificationStatusEnum)
                    .required('Verification status is required'),
                severity: yup.string().oneOf(conditionSeverityEnum).optional(),
                onsetDate: yup
                    .date()
                    .nullable()
                    .optional()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                abatementDate: yup
                    .date()
                    .nullable()
                    .optional()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            })
        )
        .required('Conditions are required'),
});

export const procedureFormSchema = yup.object().shape({
    procedures: yup
        .array()
        .of(
            yup.object().shape({
                status: yup
                    .string()
                    .oneOf(procedureStatusEnum)
                    .required('Status is required'),
                procedure: yup
                    .mixed()
                    .test(
                        'is-string-or-object',
                        'Procedure must be a string or an object',
                        (value) =>
                            typeof value === 'string' ||
                            typeof value === 'object'
                    )
                    .required('Procedure is required'),
                performedDate: yup
                    .date()
                    .nullable()
                    .optional()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                reasonCodes: yup
                    .array()
                    .of(
                        yup.object().shape({
                            reasonCode: yup
                                .mixed()
                                .test(
                                    'is-string-or-object',
                                    'Reason code must be a string or an object',
                                    (value) =>
                                        typeof value === 'string' ||
                                        typeof value === 'object'
                                ),
                        })
                    )
                    .optional(),
                referenceCondition: yup
                    .array()
                    .of(yup.string().optional().nullable()),
                note: yup.string().optional().nullable(),
            })
        )
        .optional(),
});

export const medicationFormSchema = yup.object().shape({
    medications: yup.array().of(
        yup.object().shape({
            medication: yup
                .mixed()
                .test(
                    'is-string-or-object',
                    'Medication must be a string or an object',
                    (value) =>
                        typeof value === 'string' || typeof value === 'object'
                )
                .required('Medication is required'),
            dose: yup.object().shape({
                value: yup
                    .number()
                    .transform((value) => (isNaN(value) ? undefined : value))
                    .optional(),
                unit: yup.string().oneOf(doseUnitEnum),
            }),
            frequency: yup.object().shape({
                value: yup
                    .number()
                    .transform((value) => (isNaN(value) ? undefined : value))
                    .optional(),
                unit: yup.string().oneOf(frequencyEnum),
            }),
            fillStatus: yup
                .string()
                .oneOf(medicationFillStatusEnum)
                .required('Fill status is required'),
            indications: yup
                .array()
                .of(
                    yup.object().shape({
                        indication: yup
                            .mixed()
                            .test(
                                'is-string-or-object',
                                'Indication must be a string or an object',
                                (value) =>
                                    typeof value === 'string' ||
                                    typeof value === 'object'
                            ),
                    })
                )
                .min(1, 'At least one indication is required')
                .required('Indications is required'),
            prescribedOn: yup.date().required('Prescribed date is required'),
            status: yup
                .string()
                .oneOf(medicationStatusEnum)
                .required('Status is required'),
            instructions: yup.string().optional().nullable(),
            notes: yup.string().optional().nullable(),
            adherence: yup
                .string()
                .oneOf(adherenceEnum)
                .required('Adherence is required'),
            route: yup
                .mixed()
                .test(
                    'is-string-or-object',
                    'Route must be a string or an object',
                    (value) =>
                        typeof value === 'string' || typeof value === 'object'
                )
                .optional()
                .nullable(),
        })
    ),
});
