import Button from '../ui/Button/Button';
import { LogoOnly } from '../Logo/Logo';
import { NavLink } from 'react-router-dom';
import cn from '../ui/utils/cn';
import { IconTableFilled, IconUserFilled } from '@tabler/icons-react';
import { useAdminAuth } from '../../context/AdminAuthContext';

const navigation = [
    {
        name: 'Admin Panel',
        href: '/admin/panel',
        icon: (
            <IconTableFilled className=" size-5 text-gray-200 transition duration-75  " />
        ),
        role: ['admin', 'member'],
    },
    {
        name: 'Manage Users',
        href: '/admin/manage-users',
        icon: (
            <IconUserFilled className=" size-5 text-gray-200 transition duration-75   " />
        ),
        role: ['admin'],
    },
];

export default function AdminSideNav() {
    const { logout, user } = useAdminAuth();
    return (
        <>
            <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="ms-3 mt-2 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 sm:hidden dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
                <span className="sr-only">Open sidebar</span>
                <svg
                    className="h-6 w-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                </svg>
            </button>
            <aside
                id="logo-sidebar"
                className="fixed left-0 top-0 z-40 h-screen w-64 -translate-x-full transition-transform sm:translate-x-0"
                aria-label="Sidebar"
            >
                <div className="flex h-full flex-col overflow-y-auto bg-gray-50 px-3 py-4 dark:bg-gray-800">
                    <a
                        href="/admin/panel"
                        className="mb-5 flex items-center gap-2 ps-2.5"
                    >
                        <LogoOnly />
                        <span className="self-center whitespace-nowrap text-2xl font-bold dark:text-white">
                            BF Admin
                        </span>
                    </a>
                    <div className="mt-10 flex flex-1 flex-col justify-between">
                        <ul className="space-y-2 font-medium">
                            {navigation.map(
                                (item, index) =>
                                    item.role.includes(user.role) && (
                                        <li className="w-full" key={index}>
                                            <NavLink
                                                to={item.href}
                                                className={({ isActive }) =>
                                                    cn(
                                                        'group flex w-full items-center gap-5 rounded-lg transition p-2 hover:bg-slate-700',
                                                        isActive
                                                            ? 'bg-sapphireBlue-800 text-gray-200 '
                                                            : 'text-gray-200'
                                                    )
                                                }
                                            >
                                                {item.icon}

                                                <span className="ms-3 hidden flex-1 font-semibold md:block">
                                                    {item.name}
                                                </span>
                                            </NavLink>
                                        </li>
                                    )
                            )}
                        </ul>
                        <div className='text-center space-y-4'>
                            <p className="font-semibold text-gray-200">
                                Role: {user.role}
                            </p>
                            <Button
                                variant="destructive"
                                onClick={async () => {
                                    try {
                                        logout();
                                    } catch (err) {
                                        console.log(err);
                                    }
                                }}
                                className="w-full"
                            >
                                Logout
                            </Button>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
}
