import React, { useEffect, useState } from 'react';
import cn from '../../../components/ui/utils/cn';
import { useAuth } from '../../../context/AuthContext';
import useFetchBasicInformation from '../../../shared/hooks/useFetchBasicInformation';
import InterestForm from './InterestForm';

/**
 * The QuestList component.
 * @param {object} props The component props.
 * @param {object[]} props.quests The list of quests.
 * @returns {React.JSX.Element} The rendered QuestList component.
 */
const QuestList = ({ quests }) => {
    const { user, fetchUserProfile } = useAuth();

    const [interestFormModalOpen, setInterestFormModalOpen] = useState(false);
    const [selectedQuest, setSelectedQuest] = useState('');

    useEffect(() => {
        if (!user) {
            fetchUserProfile();
        }
    }, [user, fetchUserProfile]);

    const basicInformation = useFetchBasicInformation();

    return (
        <div>
            {quests.map((quest, index) => (
                <button
                    key={index}
                    className={cn(
                        'group mb-10 w-full rounded-3xl bg-sapphireBlue-800 px-5 py-8 text-left text-white',
                        quest.style
                    )}
                    onClick={() => {
                        setSelectedQuest(quest.title);
                        setInterestFormModalOpen(true);
                    }}
                >
                    <h3 className="medium-title">{quest.title}</h3>
                    <p className="mt-3 subtitle transition-all group-hover:line-clamp-none">
                        {quest.description}
                    </p>
                </button>
            ))}
            {user && (
                <InterestForm
                    isOpen={interestFormModalOpen}
                    setIsOpen={setInterestFormModalOpen}
                    user={user}
                    selectedQuest={selectedQuest}
                    basicInformation={basicInformation}
                />
            )}
        </div>
    );
};

export default QuestList;
