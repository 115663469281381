export const fetchInterests = async () => {
    const response = await fetch('/api/mailchimp/interests');
    if (!response.ok) {
        throw new Error('Failed to fetch interests');
    }
    return response.json();
};

export const updatePreferences = async (email, interestsMap) => {
    const response = await fetch('/api/mailchimp/update-preferences', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, interestsMap }),
    });
    if (!response.ok) {
        throw new Error('Failed to update preferences');
    }
    return response.json();
};
