import {
    IconChevronLeft,
    IconChevronRight,
    IconCircleCheck,
    IconCircleX,
    IconRefresh,
} from '@tabler/icons-react';
import { useState } from 'react';
import { updateUserApprovalStatus } from '../../api/adminAPI';
import Button from '../../components/ui/Button/Button';
import Chip from '../../components/ui/Chip/Chip';
import useFetchEarlyAccessUsers from '../../shared/hooks/useFetchEarlyAccessUsers';

const AdminPanel = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const {
        earlyAccessUsers,
        setEarlyAccessUsers,
        currentPage,
        page,
        setPage,
        totalPages,
        totalUsers,
        loading,
        error,
        refetch,
    } = useFetchEarlyAccessUsers(searchQuery);

    const handleApproval = async (userId, isApproved) => {
        try {
            await updateUserApprovalStatus(userId, isApproved);
            setEarlyAccessUsers(
                earlyAccessUsers.map((user) =>
                    user._id === userId ? { ...user, isApproved } : user
                )
            );
        } catch (error) {
            console.error('Error updating user approval status:', error);
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const usersOnScreen = earlyAccessUsers?.length || 0;

    return (
        <div className="admin-panel min-h-screen ">
            <div className="mx-auto max-w-7xl">
                <div className="mb-6 flex flex-col items-center md:flex-row md:justify-between">
                    <div className="flex-1 text-center md:text-left">
                        <h1 className="text-3xl font-bold">Admin Panel</h1>
                        <p className="text-lg">
                            Welcome to the Admin Panel. You can manage early
                            access requests and more here.
                        </p>
                    </div>
                </div>
                <div className="flex justify-between py-1">
                    <h1 className="mb-4 text-xl font-bold">Early Access</h1>
                    <Button
                        variant="ghost"
                        onClick={async () => {
                            await refetch();
                        }}
                    >
                        <IconRefresh
                            className="size-6 transition-all hover:rotate-90
                        hover:text-sapphireBlue-800"
                        />
                    </Button>
                </div>

                <form className="mb-6 flex flex-col justify-center space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                    <input
                        type="text"
                        placeholder="Search by email or phone number"
                        value={searchQuery}
                        onChange={handleSearch}
                        className="w-full appearance-none rounded-lg  border bg-white px-4 py-2 leading-tight text-gray-700 shadow md:px-6  md:py-3"
                    />
                </form>

                {error && <p className="text-red-500">{error}</p>}

                <div className="overflow-x-auto rounded-lg shadow-sm">
                    {!earlyAccessUsers || loading ? (
                        'Loading...'
                    ) : (
                        <table className="min-w-full rounded-lg border border-gray-300">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="border-b border-gray-300 px-4 py-2 text-left">
                                        Rank
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-left">
                                        Name
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-left">
                                        Email
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-left">
                                        Referrals
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-left">
                                        Phone Number
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-center">
                                        Sign-up Date
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-left">
                                        Approved
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-left">
                                        Referred By
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-left">
                                        Signed Up
                                    </th>
                                    <th className="border-b border-gray-300 px-4 py-2 text-center">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {earlyAccessUsers.map((user) => (
                                    <tr
                                        key={user._id}
                                        className="border-b border-gray-300"
                                    >
                                        <td className="px-4 py-2 text-left">
                                            {user.rank}
                                        </td>

                                        <td className="whitespace-nowrap px-4 py-2 text-left">
                                            {user.fullName ? (
                                               user.fullName
                                            ) : (
                                                <span className="text-gray-400">
                                                    N/A
                                                </span>
                                            )}
                                        </td>

                                        <td className="whitespace-nowrap px-4 py-2 text-left">
                                            {user.email}
                                        </td>
                                        <td className="px-4 py-2 text-left">
                                            {user.referralCount}
                                        </td>
                                        <td className="whitespace-nowrap px-4 py-2 text-left">
                                            {user.phoneNumber}
                                        </td>
                                        <td className="px-4 py-2 text-center">
                                            {user.signupDate ? (
                                                new Date(
                                                    user.signupDate
                                                ).toLocaleDateString()
                                            ) : (
                                                <span className="text-gray-400">
                                                    N/A
                                                </span>
                                            )}
                                        </td>
                                        <td className="px-4 py-2">
                                            {user.isApproved ? (
                                                <Chip
                                                    variant="success"
                                                    className="flex w-fit items-center gap-2"
                                                >
                                                    <IconCircleCheck className="size-4 text-green-800" />
                                                    Yes
                                                </Chip>
                                            ) : (
                                                <Chip
                                                    variant="destructive"
                                                    className="flex w-fit items-center gap-2"
                                                >
                                                    <IconCircleX className="size-4 text-red-800" />
                                                    No
                                                </Chip>
                                            )}
                                        </td>
                                        <td className="px-4 py-2 text-left">
                                            {user.emailReferrer ||
                                                user.inviteCode}
                                        </td>
                                        <td className="px-4 py-2 text-left">
                                            {user.isSignedUp ? (
                                                <Chip
                                                    variant="success"
                                                    className="flex w-fit items-center gap-2"
                                                >
                                                    <IconCircleCheck className="size-4 text-green-800" />
                                                    Yes
                                                </Chip>
                                            ) : user.isSignedUp === false ? (
                                                <Chip
                                                    variant="destructive"
                                                    className="flex w-fit items-center gap-2"
                                                >
                                                    <IconCircleX className="size-4 text-red-800" />
                                                    No
                                                </Chip>
                                            ) : (
                                                <Chip variant="general">
                                                    N/A
                                                </Chip>
                                            )}
                                        </td>
                                        <td className="px-4 py-2 text-center">
                                            {!user.isApproved ? (
                                                <Button
                                                    variant="secondary"
                                                    className="rounded-md font-semibold sm:text-sm md:text-base"
                                                    onClick={() =>
                                                        handleApproval(
                                                            user._id,
                                                            true
                                                        )
                                                    }
                                                >
                                                    Approve
                                                </Button>
                                            ) : !user.isSignedUp ? (
                                                <Button
                                                    onClick={() =>
                                                        handleApproval(
                                                            user._id,
                                                            false
                                                        )
                                                    }
                                                    variant="destructive"
                                                    className="rounded-md font-semibold sm:text-sm md:text-base"
                                                >
                                                    Deny
                                                </Button>
                                            ) : (
                                                <span className="text-gray-400">
                                                    N/A
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="mt-4 flex w-full items-center justify-center gap-2">
                    <Button
                        variant="textWithIcon"
                        onClick={() => setPage(page > 1 ? page - 1 : 1)}
                        disabled={page === 1}
                        className="mr-2 rounded-md bg-gray-200  font-semibold sm:text-sm md:text-base"
                        icon={<IconChevronLeft className="size-5 " />}
                    >
                        Prev
                    </Button>
                    <p className="text-center text-gray-400">
                        Page: {currentPage} of {totalPages}
                    </p>

                    <Button
                        variant="textWithIcon"
                        icon={<IconChevronRight className="size-5 " />}
                        onClick={() =>
                            setPage(page < totalPages ? page + 1 : totalPages)
                        }
                        disabled={page === totalPages}
                        className="flex-row-reverse rounded-md bg-gray-200 font-semibold sm:text-sm md:text-base"
                    >
                        Next
                    </Button>
                </div>

                <div className="mt-4 flex items-center justify-between">
                    <div className="text-gray-700">
                        Showing {usersOnScreen} of{' '}
                        {usersOnScreen < 20 ? usersOnScreen : 20} users out of{' '}
                        {totalUsers}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPanel;
