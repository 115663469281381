import React from 'react';
import TransitionWrapper from './TransitionWrapper';
import getCaptializedString from '../../utils/getCaptializedString';

/**
 * Renders a sleep log entry with transition effects.
 * @param {object} props - The component props.
 * @param {object} props.sleepLog - The sleep log data.
 * @param {boolean} props.isHovered - A boolean flag to control the transition visibility.
 * @returns {React.JSX.Element} The rendered component.
 */
const RenderSleepLog = ({ sleepLog, isHovered }) => (
    <>
        <p className="font-bold">{`${getCaptializedString(sleepLog.feelingUponWaking)}: ${sleepLog.quality}/10`}</p>
        <p>{`Length: ${sleepLog.duration.hours} hours ${sleepLog.duration.minutes} minutes`}</p>
        <TransitionWrapper isHovered={isHovered}>
            <div className="mt-1">
                <p>{`Awakenings: ${sleepLog.awakenings}`}</p>
                <p>{`Use of Sleep Aids: ${getCaptializedString(sleepLog.sleepAids.join(', '))}`}</p>
            </div>
        </TransitionWrapper>
    </>
);

export default RenderSleepLog;
