const isSVG = (content) => {
    return typeof content === 'string' && content.startsWith('<svg');
};

const AchievementsCard = ({
    value,
    maxValue,
    title,
    text,
    Image,
    isUnlocked,
}) => {
    const radius = 50;
    const strokeWidth = 10;
    const circumference = 2 * Math.PI * radius;
    const percentage = isUnlocked ? 100 : (value / maxValue) * 100;
    const offset = circumference - (percentage / 100) * circumference;
    const strokeColor = isUnlocked
        ? '#FFD15C'
        : value > 0
          ? '#3b82f6'
          : '#B8B8B8';

    const containerStyles =
        value > 0 || isUnlocked
            ? 'flex flex-col items-center text-center rounded-2xl border-2 bg-white flex-shrink w-[calc(33.33%-12px)] px-4 py-4'
            : 'flex flex-col items-center text-center rounded-2xl border-2 bg-gray-100 grayscale flex-shrink w-[calc(33.33%-12px)] px-4 py-4';

    return (
        <div className={containerStyles}>
            <svg width={120} height={120} className="-rotate-90 transform">
                <circle
                    stroke={value > 0 || isUnlocked ? '#B8E0FF' : '#E0E0E0'}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={radius}
                    cx={60}
                    cy={60}
                />
                <circle
                    stroke={strokeColor}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={radius}
                    cx={60}
                    cy={60}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    strokeLinecap="round"
                />
                <foreignObject x="20" y="20" width="80" height="80">
                    <div className="flex h-full rotate-90 transform items-center justify-center">
                        {isSVG(Image) ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                className="h-10"
                                alt="Badge Icon"
                            >
                                {Image}
                            </svg>
                        ) : (
                            <img
                                src={Image}
                                className="h-10"
                                alt="Badge Icon"
                            />
                        )}
                    </div>
                </foreignObject>
            </svg>
            <div className="mt-2">
                <div
                    className={`font-bold ${
                        value > 0 || isUnlocked ? '' : 'text-gray-400'
                    }`}
                >{`${isUnlocked ? maxValue : value}/${maxValue}`}</div>
                <div className={value > 0 || isUnlocked ? '' : 'text-gray-400'}>
                    {title}
                </div>
                <div className={value > 0 || isUnlocked ? '' : 'text-gray-400'}>
                    {text}
                </div>
            </div>
        </div>
    );
};
export default AchievementsCard;
