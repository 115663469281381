import * as yup from 'yup';
import { StandardInput } from '../../../components/ui/Inputs/StandardInput';
import { StandardSelect } from '../../../components/ui/Inputs/StandardSelect';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addAdminUser } from '../../../api/adminAPI';
import Button from '../../../components/ui/Button/Button';
import DialogModal from '../../../components/DialogModal/DialogModal';
import React from 'react';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    role: yup.string().required(),
});

/**
 * Renders a modal to add a user
 * @param {object} props - The props of the component
 * @param {boolean} props.isOpen - The state of the modal
 * @param {Function} props.setIsOpen - The function to set the state of the modal
 * @returns {React.JSX.Element} The markup of the component
 */
export default function AddUserModal({ isOpen, setIsOpen }) {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const handleAddUser = async (data) => {
        try {
            await addAdminUser(data);
            setIsOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <DialogModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Add new member"
        >
            <form onSubmit={handleSubmit(handleAddUser)}>
                <div className="mb-2">
                    <StandardInput
                        label="Email"
                        id="email"
                        type="email"
                        placeholder="Email"
                        error={errors.email}
                        errorText={
                            errors.email?.message ||
                            'Please enter a valid email'
                        }
                        register={register}
                    />
                </div>
                <div className="mb-2">
                    <StandardSelect
                        label="Role"
                        id="role"
                        error={errors.email}
                        errorText={
                            errors.email?.message ||
                            'Please enter a valid email'
                        }
                        register={register}
                        options={[
                            { value: 'member', label: 'Member' },
                            { value: 'admin', label: 'Admin' },
                        ]}
                    />
                </div>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={!isValid}
                    isLoading={isSubmitting}
                    className="rounded-md px-4 py-2 font-semibold sm:text-sm md:text-base"
                >
                    Submit
                </Button>
            </form>
        </DialogModal>
    );
}
