/**
 * Formats a given log type into a human-readable format
 * @param {string} logType - The log type to be formatted
 * @returns {string} The formatted log type
 */
export default function getformatttedLogType(logType) {
    // Split the string at each uppercase letter and capitalize the first letter
    const words = logType
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with a space
    return words.join(' ');
}
