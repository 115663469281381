import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Returns a merged class name string.
 * @param {...string} inputs - The class names to merge.
 * @returns {string} - The merged class name string.
 */
export default function cn(...inputs) {
  return twMerge(clsx(...inputs));
}
