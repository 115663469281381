import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateYupSchema } from '../../../../components/ui/utils/generateYupSchema';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitWaterLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';

const config = {
    title: 'Water Intake Log',
    description:
        'Document your daily water consumption to track hydration levels.',
    fields: [
        {
            name: 'date',
            label: 'Log Date:',
            type: 'date',
            required: true,
        },
        {
            name: 'waterAmount',
            label: 'Amount of Water Consumed',
            type: 'group',
            required: true,
            subFields: [
                {
                    name: 'value',
                    label: 'Value',
                    type: 'number',
                    placeholder: 'e.g., 2',
                    required: true,
                },
                {
                    name: 'unit',
                    label: 'Unit',
                    type: 'select',
                    placeholder: 'e.g., 500',
                    options: [
                        { label: 'mL', value: 'mL' },
                        { label: 'oz', value: 'oz' },
                        { label: 'cups', value: 'cups' },
                        { label: 'liters', value: 'liters' },
                        { label: 'gallons', value: 'gallons' },
                    ],
                    required: true,
                },
            ],
        },
        {
            name: 'timesOfConsumption',
            label: 'Times of Consumption',
            type: 'number',
            placeholder:
                'Number of times you consumed water throughout the day.',
            required: true,
        },
    ],
};

/**
 * WaterLog component renders a dynamic form to log water intake.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function WaterLog() {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const onFormSubmission = async (data) => {
        try {
            await submitWaterLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description:
                    'Unable to submit water log. Please try again later.',
                variant: 'error',
            });
        }
    };
    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={generateYupSchema(config.fields)}
            onFormSubmission={onFormSubmission}
            logType={config.title}
        />
    );
}
