import cn from '../utils/cn';
import React from 'react';

/**
 * Renders a chip ui component.
 * @param {object} props The props for the component.
 * @param {string} props.children The children to render inside the chip.
 * @param {string} props.variant The variant of the chip.
 * @param {string} props.className The additional class name for the chip.
 * @returns {React.JSX.Element} The rendered chip component.
 */
export default function Chip({ children, variant = 'success', className }) {
    const baseClasses = 'rounded-full px-4 py-1 font-semibold';

    const variantClasses = {
        success: ' bg-green-100 text-green-800',
        destructive: 'bg-red-100 text-red-800',
        warning: 'bg-yellow-100 text-yellow-800',
        info: 'bg-blue-100 text-blue-800',
        general: 'bg-gray-100 text-gray-800',
    };
    return (
        <span className={cn(baseClasses, variantClasses[variant], className)}>
            {children}
        </span>
    );
}
