import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateYupSchema } from '../../../../components/ui/utils/generateYupSchema';
import GamifiedDynamicForm from '../../../../components/DynamicForm/GamifiedDynamicForm';
import { submitPainLogAPI } from '../../../../api/healthLogsAPI';
import { useToast } from '../../../../context/ToastContext';

const config = {
    title: 'Pain Log',
    description:
        'Record the details of any pain experienced to monitor patterns and triggers.',
    fields: [
        {
            name: 'date',
            label: 'Date of Pain',
            type: 'date',
            required: true,
        },
        {
            name: 'intensity',
            label: 'Pain Intensity',
            type: 'slider',
            min: 0,
            max: 10,
            required: true,
            description:
                'Rate the intensity of the pain from 0 (no pain) to 10 (worst possible pain).',
        },
        {
            name: 'location',
            label: 'Location of Pain',
            type: 'text',
            required: true,
            description: 'Specify where on your body you feel the pain.',
        },
        {
            name: 'duration',
            label: 'Duration of Pain (minutes)',
            type: 'number',
            required: true,
        },
        {
            name: 'triggers',
            label: 'Possible Triggers',
            type: 'multiSelect',
            options: [
                { value: 'exercise', label: 'Exercise' },
                { value: 'stress', label: 'Stress' },
                { value: 'lack of sleep', label: 'Lack of Sleep' },
                { value: 'diet', label: 'Diet' },
                { value: 'other', label: 'Other' },
            ],
            required: true,
        },
        {
            name: 'reliefMethods',
            label: 'Relief Methods',
            type: 'multiSelect',
            options: [
                { value: 'medication', label: 'Medication' },
                { value: 'rest', label: 'Rest' },
                { value: 'therapy', label: 'Therapy' },
                { value: 'natural remedies', label: 'Natural Remedies' },
                { value: 'none', label: 'None' },
            ],
            required: true,
        },
    ],
};

/**
 * PainLog component renders a dynamic form to log pain details.
 * @returns {React.JSX.Element} The rendered component.
 */
export default function PainLog() {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const onFormSubmission = async (data) => {
        try {
            await submitPainLogAPI(data);
            navigate('/health');
        } catch (error) {
            addToast({
                title: 'Error',
                description:
                    'Unable to submit pain log. Please try again later.',
                variant: 'error',
            });
        }
    };
    return (
        <GamifiedDynamicForm
            config={config}
            totalSteps={config.fields.length}
            formSchema={generateYupSchema(config.fields)}
            onFormSubmission={onFormSubmission}
            logType={config.title}
        />
    );
}
