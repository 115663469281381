import React from 'react';
import { useFormContext } from 'react-hook-form';

import ChronicIllness from '../../../assets/roundIcons/ChronicIllness.svg';
import Data from '../../../assets/roundIcons/Data.svg';
import Shield from '../../../assets/roundIcons/Shield.svg';
import Puzzle from '../../../assets/roundIcons/Puzzle.svg';
import Treasure from '../../../assets/roundIcons/Treasure.svg';
import Hugging from '../../../assets/roundIcons/Hugging.svg';
import Compass from '../../../assets/roundIcons/Compass.svg';

const reasonsForInterest = [
    {
        name: 'My autoimmune condition',
        icon: Shield,
    },
    {
        name: 'Help a love one with autoimmunity',
        icon: Hugging,
    },
    {
        name: 'Chronic issue',
        icon: ChronicIllness,
    },
    {
        name: 'Looking for better treatments',
        icon: Compass,
    },
    {
        name: 'Help cure autoimmunity',
        icon: Puzzle,
    },
    {
        name: 'Receive rewards for learning',
        icon: Treasure,
    },
    {
        name: 'Contribute data for rewards',
        icon: Data,
    },
];

/**
 * Renders the Interest component.
 * @returns {React.JSX.Element} The rendered Interest component.
 */
export default function Interest() {
    const { register, watch } = useFormContext();
    const selectedInterest = watch('reasonForUsingApp');
    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2">
                <div className="flex flex-col items-center gap-2 text-center">
                    <h1 className="text-2xl font-extrabold tracking-wide md:text-3xl">
                        Any reason you want to use BlueFairy?
                    </h1>
                    <h2>Choose one from the following</h2>
                </div>
            </div>
            <div className="mt-10 grid grid-cols-2 gap-4 px-4 md:mt-20">
                {reasonsForInterest.map((item, index) => (
                    <label
                        key={index}
                        className={`h-24 flex items-center rounded-lg border px-4 py-4 font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:h-20 md:text-lg ${
                            selectedInterest === item.name ? 'bg-pictonBlue-300' : ''
                        }`}
                    >
                        <div className="flex justify-center items-center gap-4 w-full break-words text-left">
                            <img src={item.icon} className="w-10" />
                            <span className="w-full text-sm sm:text-base">{item.name}</span>
                        </div>
                        <input
                            type="radio"
                            value={item.name}
                            {...register('reasonForUsingApp')}
                            className="hidden" // Hide the radio but keep it functional
                        />
                    </label>
                ))}
            </div>
        </>
    );    
}
