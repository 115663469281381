/**
 * Capitalizes a given string
 * @param {string} text - The text to be formatted
 * @returns {string} The formatted text
 */
export default function getCaptializedString(text) {
    // Split the string at each uppercase letter and capitalize the first letter
    const words = text
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with a space
    return words.join(' ');
}

