import cn from '../../../components/ui/utils/cn'

const PointsProgressBar = ({ currentStep, totalSteps }) => {

    const progressPercentage = ((currentStep / totalSteps) * 100).toFixed(0);
    const isGoalReached = currentStep >= totalSteps;

    const steps = (totalSteps) => {
        if (totalSteps <= 4) {
            return Array.from({ length: totalSteps }, (_, i) => i + 1);
        }
        const stepSize = totalSteps / 5;
        return [...Array(4).keys()].map(i => Math.round((i + 1) * stepSize));
    };
    
    return (
        <div className="relative w-full my-3">
            <div className="flex justify-between w-full text-sm text-gray-500 px-14">
            {steps(totalSteps).map(step => (
                <span key={step}>{step}</span>
            ))}
            </div>

            <div className="flex items-center w-full bg-yellow-100 h-6 rounded-full relative">
                <div
                    className={cn("h-full mx-0.5 h-5 rounded-2xl transition-all duration-500 relative bg-sapphireBlue-600", isGoalReached ? "bg-yellow-400 shadow-md shadow-yellow-100 h-6 mx-0 " : "")}
                    style={{ width: `${progressPercentage}%` }}
                >
                </div>
            </div>
        </div>
    );
};

export default PointsProgressBar;
