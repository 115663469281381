import { useForm } from 'react-hook-form';
import Button from '../../components/ui/Button/Button';
import { resetPasswordAPI } from '../../api/userAPI';
import { useToast } from '../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from './schema';
import React from 'react';
import { StandardInput } from '../../components/ui/Inputs/StandardInput';

/**
 * Renders the ResetPassword page.
 * @returns {React.JSX.Element} The rendered ResetPassword page.
 */
export default function ResetPassword() {
    const { addToast } = useToast();
    const navigate = useNavigate();
    const { token } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(resetPasswordSchema),
    });

    const resetPasswordSubmit = async (data) => {
        try {
            const body = { token, ...data };
            await resetPasswordAPI(body);
            navigate('/login');
        } catch (error) {
            addToast({
                variant: 'error',
                title: 'Failed to send reset link',
                description: error.message || 'Please try again later',
            });
        }
    };
    return (
        <div className="mx-auto -mt-20 flex min-h-screen max-w-2xl flex-col items-center justify-center gap-10 overflow-auto">
            <div className="mb-20 text-center">
                <h1 className="medium-title tracking-wide md:text-4xl">
                    Reset Password
                </h1>
                <p className=" mt-2 tracking-wide md:text-lg">
                    Enter your new password below to reset your password.
                </p>
            </div>
            <form
                className="w-full max-w-2xl px-4"
                onSubmit={handleSubmit(resetPasswordSubmit)}
            >
                <div className="mb-6 flex flex-col items-center justify-center gap-6 md:gap-2">
                    <div className="relative w-full">
                        <StandardInput
                            label="Password"
                            id="password"
                            type="password"
                            placeholder="******************"
                            error={errors.password}
                            errorText={errors.password?.message}
                            register={register}
                        />
                    </div>
                    <div className="relative w-full">
                        <StandardInput
                            label="Confirm Password"
                            id="confirmPassword"
                            type="password"
                            placeholder="******************"
                            error={errors.confirmPassword}
                            errorText={errors.confirmPassword?.message}
                            register={register}
                        />
                    </div>
                </div>
                <div className="mt-10 flex w-full justify-end px-4 md:mt-20">
                    <Button type="submit" disabled={isSubmitting}>
                        Reset Password
                    </Button>
                </div>
            </form>
        </div>
    );
}
