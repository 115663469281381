export const autocompleteAPI = async (endpoint, searchTerm) => {
    const response = await fetch(`/api/autocomplete/${endpoint}/${searchTerm}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    if (!response.ok) throw new Error('Network response was not ok');
    return await response.json();
}