import { useEffect, useState } from 'react';
import { fetchElementConfigAPI } from '../../api/unitsAPI';
import { useToast } from '../../context/ToastContext';

/**
 * @typedef {object} ElementConfigReturn
 * @property {object} config The element config.
 */

/**
 * Fetches the element config from the API.
 * @param {string} unitId The unit ID.
 * @param {string} elementId The element ID.
 * @returns {ElementConfigReturn} The element config and form config schema.
 */
const useFetchElementConfig = (unitId, elementId) => {
    const { addToast } = useToast();
    const [config, setConfig] = useState(null);
    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                const data = await fetchElementConfigAPI(unitId, elementId);
                const formConfig = data.element;
                //console.log(data);
                setConfig(formConfig);
            } catch (error) {
                addToast({
                    variant: 'error',
                    title: 'Failed to fetch element config',
                    description: 'Please try again later.',
                });
            }
        };
        fetchFormConfig();
    }, []);
    return { config };
};

export default useFetchElementConfig;
