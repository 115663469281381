import React from 'react';
import CollapsibleSection from '../../components/ui/Section/Section';

const privacySections = [
    {
        title: 'A Special Note About Minors',
        content: (
            <>
                <p>
                    The Site is a general audience website intended for use by
                    adults. Neither the Site nor the App is designed or intended
                    to attract, and is not directed to, children under eighteen
                    (18) years of age, let alone children under thirteen (13)
                    years of age. BlueFairy does not collect personal
                    information through the Site or the App from any person it
                    actually knows to be under thirteen (13) years of age. If
                    BlueFairy obtains actual knowledge that it has collected
                    personal information through the Site or the App from a
                    person under thirteen (13) years of age, then it will use
                    reasonable efforts to refrain from further using such
                    personal information or maintaining it in retrievable form.
                </p>
                <p>
                    Furthermore, if you are under eighteen (18) years of age,
                    then you (or your parent or legal guardian) may at any time
                    request that BlueFairy remove content or information about
                    you that is posted on the Site or the App. Please submit any
                    such request (“Request for Removal of Minor Information”) to
                    any one of the following:
                </p>
                <ul className="ml-4 list-inside list-disc">
                    <li>
                        By mail: BlueFairy, 555 Broadhollow Rd, Suite 305,
                        Melville, NY 11747
                    </li>
                    <li>
                        By email: support@bluefairyhealth.com, with a subject
                        line of “Removal of Minor Information.”
                    </li>
                </ul>
                <p>
                    For each Request for Removal of Minor Information, please
                    state “Removal of Minor Information” in the email or letter
                    subject line, and clearly state the following in the body:
                </p>
                <ul className="ml-4 list-inside list-disc">
                    <li>the nature of your request;</li>
                    <li>
                        the identity of the content or information to be
                        removed;
                    </li>
                    <li>
                        whether such content or information if found on the Site
                        or the App;
                    </li>
                    <li>
                        the location on content or information on the Site or
                        the App (e.g., providing the URL for the specific web
                        page the content or information is found on);
                    </li>
                    <li>
                        that the request is related to the “Removal of Minor
                        Information;
                    </li>
                    <li>
                        your name, street address, city, state, zip code and
                        email address; and
                    </li>
                    <li>
                        whether you prefer to receive a response to your request
                        by mail or email.
                    </li>
                </ul>
                <p>
                    If you send a Request for Removal of Minor Information by
                    mail, then please do so by U.S. Certified Mail, Return
                    Receipt Requested to allow for confirmation of mailing,
                    delivery and tracking. BlueFairy will not accept any Request
                    for Removal of Minor Information via telephone or facsimile.
                    BlueFairy is not responsible for failing to comply with any
                    Request for Removal of Minor Information that is incomplete,
                    incorrectly labeled or incorrectly sent.
                    <br />
                    <br />
                    Please note that the aforementioned removal does not ensure
                    complete or comprehensive removal of such content or
                    information posted on the Site or the App.
                    <br />
                    <br />
                    Also, please note that BlueFairy is not required to erase or
                    otherwise eliminate, or to enable erasure or elimination of,
                    such content or information in certain circumstances, such
                    as, for example, when an international, federal, state, or
                    local law, rule or regulation requires BlueFairy to maintain
                    the content or information; when the content or information
                    is stored on or posted to the Site by a third party other
                    than you (including any content or information posted by you
                    that was stored, republished or reposted by the third
                    party); when BlueFairy anonymizes the content or
                    information, so that you cannot be individually identified;
                    when you do not follow the aforementioned instructions for
                    requesting the removal of the content or information; and
                    when you have received compensation or other consideration
                    for providing the content or information.
                    <br />
                    <br />
                    The foregoing is a description of BlueFairy voluntary
                    practices concerning the collection of personal information
                    through the Site from certain minors, and is not intended to
                    be an admission that BlueFairy is subject to the Children’s
                    Online Privacy Protection Act, the Federal Trade
                    Commission’s Children’s Online Privacy Protection Rule(s),
                    or any similar international, federal, state, or local laws,
                    rules, or regulations.
                    <br />
                    <br />
                </p>
            </>
        ),
    },
    {
        title: 'Categories of Information Collected',
        content: (
            <>
                <p>
                    BlueFairy may collect personal and non-personal information
                    about you through the Site and/or the App.
                </p>
                <p>
                    Categories of Information Collected Through the Site and/or
                    the App. The categories of personal and non-personal
                    information collected from you through the Site and/or the
                    App might include your:
                </p>
                <ul className="ml-4 list-inside list-disc">
                    <li>
                        Name (first, last and/or middle) and unique identifiers
                        (e.g., username);
                    </li>
                    <li>
                        Login information for the Site and/or the App (e.g.,
                        username, password, security questions and answers);
                    </li>
                    <li>
                        Home contact information (e.g., mailing address, email
                        address, telephone number, fax number);
                    </li>
                    <li>Gender;</li>
                    <li>Date of birth;</li>
                    <li>Electronic signature;</li>
                    <li>
                        Photographic or video image to the extent provided by
                        you to BlueFairy (as related to the The Medical Practice
                        Pages, and further subject to the The Medical Practice
                        Notice of Privacy Practices);
                    </li>
                    <li>
                        Medical history, including the results of any
                        questionnaires, surveys, information requests,
                        interviews, or other documentary, text, video, or audio
                        interactions related to the provision of health services
                        (as related to the The Medical Practice Pages, and
                        further subject to the The Medical Practice Notice of
                        Privacy Practices);
                    </li>
                    <li>
                        Billing, payment or shipping information (e.g., payor or
                        payee name, checking account number, credit card number,
                        expiration date, verification code, accountholder name,
                        cardholder name, billing address, mailing address, tax
                        ID number, billing history);
                    </li>
                    <li>
                        Business contact information (e.g., company, title,
                        mailing address, email address, telephone number,
                        facsimile number, tax ID number); and/or
                    </li>
                    <li>
                        Computer, mobile device, and/or browser information
                        (e.g., IP address, mobile device ID information,
                        operating system, connection speed, bandwidth, browser
                        type, referring/exist web pages, web page requests,
                        cookie information, hardware attributes, software
                        attributes);
                    </li>
                    <li>
                        Third-party website, network, platform, server and/or
                        application information (e.g., Facebook, Twitter,
                        Instagram);
                    </li>
                    <li>
                        Usage activity concerning your interactions with the
                        Site, the App and/or third-party websites, networks,
                        platforms, servers or applications accessed through the
                        Site and/or the App (e.g., number of clicks on a page or
                        feature, amount of time spent on a page or feature,
                        identity of third-party websites, networks, platforms,
                        servers and applications being access through the Site
                        and/or the App);
                    </li>
                    <li>
                        Usage activity concerning products and services provided
                        to or accessed by you on or through the Site and/or the
                        App (e.g., viewing habits, viewing preferences, viewing
                        history, title selections, favorites, streams,
                        downloads, demographics and closed captioning
                        selections).
                    </li>
                    <li>
                        Transactional information (e.g., offers, orders,
                        pricing, payments, purchaser, seller, item information,
                        shipping terms, comments, ratings, feedback and instant
                        messages and/or other communications made within or
                        through the Site and/or the App);
                    </li>
                    <li>
                        Information about third parties that you refer to
                        BlueFairy (e.g., name, email and/or other contact
                        information, relationship);
                    </li>
                    <li>
                        Information set forth in the Site’s and/or the App’s
                        online account registration, subscriber profile, and/or
                        subscriber directory forms completed by you; and/or
                    </li>
                    <li>
                        Statements or content (e.g., comments, videos,
                        photographs, images), and information about such
                        statements or content, which you submit or publish on or
                        through the Site and/or the App, or which are accessed
                        via your public or linked social media pages (e.g.,
                        Facebook, Twitter, Instagram).
                    </li>
                </ul>
                <p>
                    Categories of Other Information Collected From Your Mobile
                    Device. If you use your mobile device to visit, access or
                    use the Site or the App, then additional categories of
                    personal and non-personal information collected from you
                    through the Site and/or the App might include:
                </p>
                <ul className="ml-4 list-inside list-disc">
                    <li>Your name associated with your mobile device;</li>
                    <li>
                        Your telephone number associated with your mobile
                        device;
                    </li>
                    <li>Your geolocation;</li>
                    <li>Your mobile device ID information;</li>
                    <li>
                        With your express consent, your contacts and/or contact
                        information (e.g., names, telephone numbers, physical
                        addresses, email addresses, photos) stored on your
                        mobile device; and/or
                    </li>
                    <li>
                        With your express consent, information about third-party
                        software applications on your mobile device (including,
                        without limitation, general software apps, downloadable
                        software apps, social media apps).
                    </li>
                </ul>
            </>
        ),
    },
    {
        title: 'Categories of Information Collected',
        content: (
            <>
                <p>
                    BlueFairy may collect personal and non-personal information
                    about you through the Site and/or the App.
                </p>{' '}
                <ul className="ml-4 list-inside list-disc">
                    <li>
                        Categories of Information Collected Through the Site
                        and/or the App. The categories of personal and
                        non-personal information collected from you through the
                        Site and/or the App might include your:
                    </li>
                    <li>
                        Name (first, last and/or middle) and unique identifiers
                        (e.g., username);
                    </li>
                    <li>
                        Login information for the Site and/or the App (e.g.,
                        username, password, security questions and answers);
                    </li>
                    <li>
                        Home contact information (e.g., mailing address, email
                        address, telephone number, fax number);
                    </li>
                    <li>Gender;</li>
                    <li>Date of birth;</li>
                    <li>Electronic signature;</li>
                    <li>
                        Photographic or video image to the extent provided by
                        you to BlueFairy (as related to the The Medical Practice
                        Pages, and further subject to the The Medical Practice
                        Notice of Privacy Practices);
                    </li>
                    <li>
                        Medical history, including the results of any
                        questionnaires, surveys, information requests,
                        interviews, or other documentary, text, video, or audio
                        interactions related to the provision of health services
                        (as related to the The Medical Practice Pages, and
                        further subject to the The Medical Practice Notice of
                        Privacy Practices);
                    </li>
                    <li>
                        Billing, payment or shipping information (e.g., payor or
                        payee name, checking account number, credit card number,
                        expiration date, verification code, accountholder name,
                        cardholder name, billing address, mailing address, tax
                        ID number, billing history);
                    </li>
                    <li>
                        Business contact information (e.g., company, title,
                        mailing address, email address, telephone number,
                        facsimile number, tax ID number); and/or
                    </li>
                    <li>
                        Computer, mobile device, and/or browser information
                        (e.g., IP address, mobile device ID information,
                        operating system, connection speed, bandwidth, browser
                        type, referring/exist web pages, web page requests,
                        cookie information, hardware attributes, software
                        attributes);
                    </li>
                    <li>
                        Third-party website, network, platform, server and/or
                        application information (e.g., Facebook, Twitter,
                        Instagram);
                    </li>
                    <li>
                        Usage activity concerning your interactions with the
                        Site, the App and/or third-party websites, networks,
                        platforms, servers or applications accessed through the
                        Site and/or the App (e.g., number of clicks on a page or
                        feature, amount of time spent on a page or feature,
                        identity of third-party websites, networks, platforms,
                        servers and applications being access through the Site
                        and/or the App);
                    </li>
                    <li>
                        Usage activity concerning products and services provided
                        to or accessed by you on or through the Site and/or the
                        App (e.g., viewing habits, viewing preferences, viewing
                        history, title selections, favorites, streams,
                        downloads, demographics and closed captioning
                        selections).
                    </li>
                    <li>
                        Transactional information (e.g., offers, orders,
                        pricing, payments, purchaser, seller, item information,
                        shipping terms, comments, ratings, feedback and instant
                        messages and/or other communications made within or
                        through the Site and/or the App);
                    </li>
                    <li>
                        Information about third parties that you refer to
                        BlueFairy (e.g., name, email and/or other contact
                        information, relationship);
                    </li>
                    <li>
                        Information set forth in the Site’s and/or the App’s
                        online account registration, subscriber profile, and/or
                        subscriber directory forms completed by you; and/or
                    </li>
                    <li>
                        Statements or content (e.g., comments, videos,
                        photographs, images), and information about such
                        statements or content, which you submit or publish on or
                        through the Site and/or the App, or which are accessed
                        via your public or linked social media pages (e.g.,
                        Facebook, Twitter, Instagram).
                    </li>
                    <li>
                        Categories of Other Information Collected From Your
                        Mobile Device. If you use your mobile device to visit,
                        access or use the Site or the App, then additional
                        categories of personal and non-personal information
                        collected from you through the Site and/or the App might
                        include:
                    </li>
                    <li>Your name associated with your mobile device;</li>
                    <li>
                        Your telephone number associated with your mobile
                        device;
                    </li>
                    <li>Your geolocation;</li>
                    <li>Your mobile device ID information;</li>
                    <li>
                        With your express consent, your contacts and/or contact
                        information (e.g., names, telephone numbers, physical
                        addresses, email addresses, photos) stored on your
                        mobile device; and/or
                    </li>
                    <li>
                        With your express consent, information about third-party
                        software applications on your mobile device (including,
                        without limitation, general software apps, downloadable
                        software apps, social media apps).
                    </li>
                </ul>
            </>
        ),
    },
    {
        title: 'How Information Is Collected',
        content: (
            <>
                BlueFairy might collect personal and non-personal information
                from you when you:
                <ul className="ml-4 list-inside list-disc">
                    <li>visit, access or use the Site and/or the App;</li>
                    <li>
                        register with or subscribe to the Site and/or the App;
                    </li>
                    <li>
                        register for or subscribe to the products and/or
                        services of BlueFairy online through the Site and/or the
                        App;
                    </li>
                    <li>
                        “sign in,” “log in,” or the like to the Site and/or the
                        App;
                    </li>
                    <li>
                        allow the Site and/or the App to access, upload,
                        download, import or export content found on or through,
                        or to otherwise interact with, your computer or mobile
                        device (or any other device you may use to visit, access
                        or use the Site and/or the App) or online accounts with
                        third-party websites, networks, platforms, servers or
                        applications (e.g., your online social media accounts,
                        your cloud drives and servers, your mobile device
                        service provider);
                    </li>
                    <li>
                        process a payment through the Site and/or the App; or
                    </li>
                    <li>answer an online survey or questionnaire.</li>
                </ul>
                In addition, if you or a third party sends BlueFairy a comment,
                message or other communication (such as, by way of example only,
                email, letter, fax, phone call, or voice message) about you or
                your activities on or through the Site and/or the App, then
                BlueFairy may collect any personal or non-personal information
                provided therein or therewith. Finally, BlueFairy might use
                various tracking, data aggregation and/or data analysis
                technologies, including, for example, the following:
                <ul className="ml-4 list-inside list-disc">
                    <li>
                        Cookies, which are small data files (e.g., text files)
                        stored on the browser or device you use to view a
                        website or message. They may help store user preferences
                        and activity, and may allow a website to recognize a
                        particular browser or device. There are several types of
                        cookies, including, for example, browser cookies,
                        session cookies, and persistent cookies. Cookies may
                        record information you access on one page of a website
                        to simplify subsequent interaction with that website, or
                        to help streamline your transactions on related pages of
                        that website. Most major browsers are set up so that
                        they will initially accept cookies, but you might be
                        able to adjust your browser’s or device’s preferences to
                        issue you an alert when a cookie is downloaded, or to
                        block, reject, disable, delete or manage the use of some
                        or all cookies on your browser or device.
                    </li>
                    <li>
                        Flash cookies, which are cookies written using Adobe
                        Flash, and which may be permanently stored on your
                        device. Like regular cookies, Flash cookies may help
                        store user preferences and activity, and may allow a
                        website to recognize a particular browser or device.
                        Flash cookies are not managed by the same browser
                        settings that are used for regular cookies.
                    </li>
                    <li>
                        Web beacons, which are pieces of code embedded in a
                        website or email to monitor your activity on the website
                        or your opening of the email, and which can pass along
                        information such as the IP address of the computer or
                        device you use to view the website or open the email,
                        the URL page on which the web beacon is located, the
                        type of browser that was used to access the website, and
                        previously set cookie values. Web beacons are sometimes
                        used to collect advertising data, such as counting page
                        views, promotion views or advertising responses.
                        Disabling your computer’s, device’s or browser’s cookies
                        may prevent some web beacons from tracking or recording
                        certain information about your activities.
                    </li>
                    <li>
                        Scripts, which are pieces of code embedded in a website
                        to define how the website behaves in response to certain
                        key or click requests sent by the user. Scripts are
                        sometimes used to collect information about the user’s
                        interactions with the website, such as the links the
                        user clicks on. Scripts are often times temporarily
                        downloaded to the user’s computer or device from the
                        website server, active only while the user is connected
                        to the Site and/or the App, and deactivated or deleted
                        when the user disconnects from the website.
                    </li>
                    <li>
                        Analytic tools and services, which are sometimes offered
                        by third parties, and which track, measure and/or
                        generate information about a website’s or program’s
                        traffic, sales, audience and similar information, and
                        which may be used for various reasons, such as, for
                        example, statistical research, marketing research, and
                        content ratings research, and conversion tracking.
                        Examples of the analytic tools and services which
                        BlueFairy might use include Google Analytics and
                        Taplytics. BlueFairy may also use other third-party
                        analytic tools and services.
                    </li>
                </ul>
                We also may use these technologies to collect information
                regarding your interaction with email messages, such as whether
                you opened, clicked on, or forwarded a message. This information
                is gathered from all users. When you access our Service from a
                mobile device, we may collect unique identification numbers
                associated with your device or our mobile application
                (including, for example, a UDID, Unique ID for Advertisers
                (“IDFA”), Google AdID, or Windows Advertising ID), mobile
                carrier, device type, model and manufacturer, mobile device
                operating system brand and model, phone number, and, depending
                on your mobile device settings, your geographical location data,
                including GPS coordinates (e.g., latitude and/or longitude) or
                similar information regarding the location of your mobile
                device, or we may be able to approximate a device’s location by
                analyzing other information, like an IP address. We and our
                third-party partners may also use cookies and tracking
                technologies for advertising purposes. For more information
                about tracking technologies, please see “Third Party Tracking
                and Online Advertising” below. Third Party Web Beacons and Third
                Party Buttons. We may also implement third-party content or
                advertising on the Service that may use clear gifs or other
                forms of web beacons, which allow the third-party content
                provider to read and write cookies to your browser in connection
                with your viewing of the third-party content on the Service.
                Additionally, we may implement third-party buttons (such as
                Facebook “like” or “share” buttons) that may allow third parties
                to collect information about you through such third parties’
                browser cookies, even when you do not interact with the button.
                Information collected through web beacons and buttons is
                collected directly by these third parties, and BlueFairy does
                not participate in that data transmission. Information collected
                by a third party in this manner is subject to that third party’s
                own data collection, use, and disclosure policies. Please be
                advised that if you choose to block, reject, disable, delete or
                change the management settings for any or all of the
                aforementioned technologies and/or other tracking, data
                aggregation and data analysis technologies, then certain areas
                of the Site and/or the App might not function properly. By
                visiting, accessing or using the Site and/or the App, you
                acknowledge and agree in each instance that you are giving
                BlueFairy permission to monitor or otherwise track your
                activities on the Site and/or the App, and that BlueFairy may
                use the aforementioned technologies and/or other tracking, data
                aggregation and data analysis technologies.
            </>
        ),
    },
    {
        title: 'Use of Information Collected',
        content: (
            <>
                BlueFairy may use the personal and/or non-personal information
                it collects from you through the Site and/or the App in a
                variety of ways, such as, for example, to:
                <ul className="ml-4 list-inside list-disc">
                    <li>Fulfill your requests;</li>
                    <li>Process your payments;</li>
                    <li>
                        Facilitate your movement through the Site and/or the
                        App;
                    </li>
                    <li>
                        Facilitate your use of the Site, the App and/or their
                        respective products and/or services;
                    </li>
                    <li>
                        Send you communications like, for example,
                        administrative emails, answers to your questions and
                        updates about the Site and/or the App;
                    </li>
                    <li>
                        Provide you with information about BlueFairy, The
                        Medical Practice, and/or their businesses, products and
                        services by letter, email, text, telephone or other
                        forms of communication;
                    </li>
                    <li>
                        Provide you with information about third-party
                        businesses, products and services by letter, email,
                        text, telephone or other forms of communication;
                    </li>
                    <li>
                        Improve the Site, the App and/or their respective
                        content, features and/or services;
                    </li>
                    <li>
                        Improve the products, services, marketing and/or
                        promotional efforts of BlueFairy;
                    </li>
                    <li>
                        Create new products, services, marketing and/or
                        promotions for BlueFairy;
                    </li>
                    <li>
                        Market the businesses, products and/or services of
                        BlueFairy;
                    </li>
                    <li>
                        Help personalize user experiences with the Site, the App
                        and/or their respective products and/or services;
                    </li>
                    <li>
                        Analyze traffic to and through the Site and/or the App;
                    </li>
                    <li>
                        Analyze user behavior and activity on or through the
                        Site and/or the App;
                    </li>
                    <li>
                        Conduct research and measurement activities for purposes
                        of product and service research and development,
                        advertising claim substantiation, market research, and
                        other activities related to BlueFairy, The Medical
                        Practice, the Site, the App and/or their respective
                        products and/or services;
                    </li>
                    <li>
                        Monitor the activities of you and others on or through
                        the Site and/or the App;
                    </li>
                    <li>
                        Place and track orders for prescription drugs and other
                        products on your behalf;
                    </li>
                    <li>
                        Protect or enforce BlueFairy’s rights and properties;
                        and/or
                    </li>
                    <li>
                        Protect or enforce the rights and properties of others
                        (which may include you).
                    </li>
                </ul>
                BlueFairy reserves the right to use your personal and
                non-personal information when:
                <ul className="ml-4 list-inside list-disc">
                    <li>
                        Required by applicable law, court order or other
                        governmental authority (including, without limitation
                        and by way of example only, in response to a subpoena or
                        other legal process); or
                    </li>
                    <li>
                        BlueFairy believes in good faith that such use is
                        otherwise necessary or advisable (including, without
                        limitation and by way of example only, to investigate,
                        prevent, or take legal action against someone who may be
                        causing injury to, interfering with, or threatening the
                        rights, obligations or properties of BlueFairy, a user
                        of the Site which may include you, a user of the App
                        which may include you, or anyone else who may be harmed
                        by such activities or to further BlueFairy’s legitimate
                        business interests).
                    </li>
                </ul>
            </>
        ),
    },
    {
        title: 'Sharing of Information Collected',
        content: `BlueFairy reserves the right to share your personal information with BlueFairy’ employees, agents, administrators, accountants, financial advisors and legal advisors;; and with those third parties who help manage, administer distribute and/or operate the Site, the App, and/or their respective products, services and/or features (e.g., web hosting companies, website administrators, mobile app distribution platforms, support services companies, credit card or other payment processing companies), as needed in order for them to perform such services.
BlueFairy does not limit the ways in which it may use or share non-personal information because non-personal information does not identify you. For example, BlueFairy may freely share non-personal information with its affiliates, suppliers and representatives, as well as with other individuals, businesses, and government entities.
You may choose to share certain information and/or follow other users on the Site and/or the App. You may choose to share your contact information with other users or provide it publicly.
`,
    },
    {
        title: 'Data Retention',
        content: `BlueFairy may retain your personal information and non-personal information for as long as it believes necessary; as long as necessary to comply with its legal obligations, resolve disputes and/or enforce its agreements; and/or as long as needed to provide you with the products and/or services of the Site, the App, and/or BlueFairy. BlueFairy may dispose of or delete any such personal information or non-personal information at any time, except as set forth in any other agreement or document executed by BlueFairy or as required by law.`,
    },
    {
        title: 'Transactions',
        content: `In connection with any transaction that you conduct through the Site and/or the App (e.g., the payment of any subscription fees, the purchase or sale of any products on or through the Site and/or the App), you may be asked to supply certain information relevant to the transaction, including, without limitation, your credit card number and expiration date, your billing address, your shipping address, your phone number and/or your email address. By submitting such information, you grant BlueFairy without charge the irrevocable, unencumbered, universe-wide and perpetual right to provide such information to third parties (e.g., payment processing companies, buyers on the Site and/or the App, sellers on the Site and/or the App) for the purpose of facilitating the transaction.
All credit card, debit card and other monetary transactions on or through the Site and/or the App occur through an online payment processing application(s) accessible through the Site and/or the App. This online payment processing application(s) is provided by BlueFairy’ third-party online payment processing vendor, Stripe (“Stripe”). Additional information about Stripe, its privacy policy and its information security measures (collectively, the “Stripe Policies”) should be available on the Stripe website located at https://stripe.com/us/privacy or by contacting Stipe directly. Reference is made to the Stripe Policies for informational purposes only and are in no way incorporated into or made a part of this Privacy Policy. BlueFairy’ relationship with Stripe, if any, is merely contractual in nature, as Stripe nothing more than a third-party vendor to BlueFairy, and is in no way subject to BlueFairy’ direction or control; thus, their relationship is not, and should not be construed as, one of fiduciaries, franchisors-franchisees, agents-principals, employers-employees, partners, joint venturers or the like.
`,
    },
    {
        title: 'Third Party Applications and Networks',
        content: `The Site, the App, and/or any communications sent through or as a function of the Site and/or the App may contain links to third-party websites, networks, platforms, servers and/or applications. To the fullest extent permitted by law, BlueFairy is not responsible for, and you hereby release BlueFairy from any and all liability which may arise from, such third party websites, networks, platforms, servers and applications (including, without limitation, the privacy policies and practices of such third-party websites, networks, platforms, servers, and applications).
You may have the opportunity to access the Site and/or the App using your online user accounts with certain third-party websites, networks, platforms, servers or applications, which may be subject to separate privacy policies pertaining to those third-party websites, networks, platforms, servers or applications. BlueFairy may also collect information from those third-party websites, networks, platforms, servers or applications (such as, for example, your name, gender, date of birth and personal interests, when you “like” or click links provided by or through those third-party websites, networks, platforms, servers, or applications and other information available through your online account or page with those third-party websites, networks, platforms, servers or applications). It is your responsibility to carefully read, accept and comply with the relevant terms of use, waivers, and privacy policies associated with those third-party websites, networks, platforms, servers and applications.
`,
    },
    {
        title: 'Security',
        content: (
            <>
                BlueFairy implements security measures to help protect the
                personal information it collects through the Site and the App.
                For example, BlueFairy encrypts the transmission of information
                through the Site by using secure socket layer (SSL) technology.
                <br />
                <br />
                Regardless of the foregoing, please observe that “perfect” or
                “100%” security does not exist on the Internet. Therefore, your
                access and use of the Site and/or the App, and your opening of
                any communications sent through or as a function of the Site
                and/or the App, are done at your own risk.
                <br />
                <br />
                To help maintain the security of your personal information,
                BlueFairy asks that you please notify it immediately of any
                unauthorized visit, access or use of the Site and/or the App, or
                the loss or unauthorized use of your user access information for
                the Site and/or the App (e.g., username or password). You may
                request at any time that BlueFairy provide you with an
                opportunity to review and change your personal information
                collected through the Site or the App or to no longer use your
                personal information to provide you with any products or
                services. Please submit any such request (“Request Concerning
                Personal Information”) to any one of the following:
                <ul className="ml-4 list-inside list-disc">
                    <li>
                        By mail: BlueFairy, 555 Broadhollow Rd, Suite 305,
                        Melville, NY 11747, with a subject line of “Your
                        Personal Information.”
                    </li>
                    <li>
                        By email: support@bluefairyhealth.com, with a subject
                        line of “Your Personal Information.”
                    </li>
                </ul>
                For each Request Concerning Personal Information, please state
                “Your Personal Information” in the email or letter subject line,
                and clearly state the following in the body:
                <ul className="ml-4 list-inside list-disc">
                    <li>the nature of your request;</li>
                    <li>
                        that the request is related to “Your Personal
                        Information;”
                    </li>
                    <li>
                        your name, street address, city, state, zip code and
                        email address; and
                    </li>
                    <li>
                        whether you prefer to receive a response to your request
                        by mail or email.
                    </li>
                </ul>
                If you send a Request Concerning Personal Information by mail,
                then please do so by U.S. Certified Mail, Return Receipt
                Requested to allow for confirmation of mailing, delivery and
                tracking. BlueFairy will not accept a Request Concerning
                Personal Information via telephone or facsimile. BlueFairy is
                not responsible for any Request Concerning Personal Information
                that is incomplete, incorrectly labeled, or incorrectly sent.
                <br />
                <br />
                You are solely responsible for the accuracy and content of your
                personal information, and for keeping your personal information
                current and correct.
            </>
        ),
    },
    {
        title: 'Your California Privacy Rights',
        content: (
            <>
                If you are a resident of the State of California and BlueFairy
                has an established business relationship with you, then,
                pursuant to Section 1798.83 of the California Civil Code, you
                have the right to request the following at any time: information
                from BlueFairy free of charge regarding the manner in which
                BlueFairy shares certain personal information collected through
                the Site and/or the App with third parties who use such
                information for direct marketing purposes; and the
                discontinuation (or opt-out) of BlueFairy’s sharing of such
                information with such third parties. Please submit any such
                request (“California Privacy Rights Request”) to any one of the
                following:
                <ul className="ml-4 list-inside list-disc">
                    <li>
                        By mail: BlueFairy, 251 Little Falls Drive Wilmington,
                        DE 19808 New Castle County with a subject line of “Your
                        California Privacy Rights.”
                    </li>
                    <li>
                        By email: support@bluefairyhealth.com, with a subject
                        line of “Your California Privacy Rights.”
                    </li>
                </ul>
                For each California Privacy Rights Request, please state “Your
                California Privacy Rights” in the email or letter subject line,
                and clearly state the following in the body:
                <ul className="ml-4 list-inside list-disc">
                    <li>the nature of your request;</li>
                    <li>
                        that the request is related to “Your California Privacy
                        Rights;”
                    </li>
                    <li>
                        your name, street address, city, state, zip code and
                        email address; and
                    </li>
                    <li>
                        whether you prefer to receive a response to your request
                        by mail or email.
                    </li>
                </ul>
                If you send a California Privacy Rights Request by mail, then
                please do so by U.S. Certified Mail, Return Receipt Requested to
                allow for confirmation of mailing, delivery and tracking.
                BlueFairy will not accept a California Privacy Rights Request
                via telephone or fax; and is not responsible for a California
                Privacy Rights Request that is incomplete, incorrectly labeled
                or incorrectly sent.
                <br />
                <br />
                You are solely responsible for the accuracy and content of your
                personal information, and for keeping your personal information
                current and correct.
                <br />
                <br />
                Further, BlueFairy does not respond to “do not track” (DNT)
                signals.
                <br />
                <br />
                Finally, as explained further above, BlueFairy does not
                authorize third parties to collect your personal information
                when you use the Site or the App, except as expressly stated in
                this Privacy Policy. To the fullest extent permitted by law,
                BlueFairy is not responsible for, and you hereby release
                BlueFairy from any and all liability which may arise from, such
                third parties’ unauthorized collection of your personal
                information. BlueFairy may supplement, amend, or otherwise
                modify this Privacy Policy at any time without notice to you.
                Such supplements, amendments and other modifications will be
                posted on this or a similar page of the Site and the App, and
                shall be deemed effective as of their stated effective or
                modification date. It is your responsibility to carefully review
                this Privacy Policy each time you visit, access or use the Site
                or the App.
                <br />
                <br />
                Again, by visiting, accessing or using the Site or the App, you
                are automatically accepting and agreeing to the most-recent
                version of this Privacy Policy, as well as the most-recent
                version of the Site’s and the App’s Terms and Conditions, and
                your continuing visit, access or use of the Site and/or the App
                reaffirms your acceptance and agreement in each instance.
            </>
        ),
    },
    {
        title: 'Severability',
        content: `If any term or condition of this Privacy Policy is deemed invalid or unenforceable by a court of law with binding authority, then the remaining Terms and Conditions shall not be affected, and the court shall reform the invalidated or unenforceable term or condition to the maximum extent permitted under the law and consistent with the intent of this Privacy Policy.
`,
    },
    {
        title: 'Contact Us',
        content: (
            <>
                <p>
                    Please direct any questions you may have about this Privacy
                    Policy, the Site or the App (other than the The Medical
                    Practice Pages) to any one of the following:
                </p>
                <ul className="ml-4 list-inside list-disc">
                    <li>
                        By mail: BlueFairy, 555 Broadhollow Rd, Suite 305,
                        Melville, NY 11747, with a subject line of “Privacy
                        Policy.”
                    </li>
                    <li>
                        By email: support@bluefairyhealth.com, with a subject
                        line of “Privacy Policy.”
                    </li>
                </ul>
                <p>
                    The foregoing contact information may change from
                    time-to-time by supplementation, amendment, or modification
                    of this Privacy Policy.
                </p>
            </>
        ),
    },

    // Add more sections as needed
];

const PrivacyPolicy = () => (
    <div className="privacy-policy mx-auto max-w-4xl p-4">
        <h1 className="mb-6 text-3xl font-bold">Privacy Notice</h1>
        <p>
            mybluefairy.com respects the privacy of others. This Privacy Policy
            explains BlueFairy, Inc (BlueFairy) privacy practices for the
            mybluefairy.com website (together with its pages and features,
            “Site”) and mobile software application (together with its pages and
            features, “App”) by providing you and any person helping you visit,
            access, register with or use the Site and/or the App (collectively,
            “you” or “your”) with an overview of the following:
        </p>
        <br />
        <ul className="ml-4 list-inside list-disc">
            <li>
                the type of information about you that BlueFairy collects
                through the Site and the App;
            </li>
            <li>how that information is collected;</li>
            <li>how that information is used;</li>
            <li>who will have access to that information; and</li>
            <li>
                BlueFairy’s security measures for protecting that information.
            </li>
            <br />
        </ul>
        <p>
            This Privacy Policy also explains the privacy practices for certain
            pages or features wherein one or more of the following entities may
            offer certain health- or healthcare-related products or services.
            <br />
            <br />
            This Privacy Policy applies to information collected through the
            Site and/or the App. Carefully read this Privacy Policy in its
            entirety. <br />
            <br />
            When you create, register or log into an account through the Site or
            the App, you are automatically accepting and agreeing to the
            most-recent version of this Privacy Policy, as well as the Site’s
            and the App’s Terms. <br />
            <br />
            Similarly, by visiting, accessing or using the Site or the App, you
            are automatically accepting and agreeing to the most-recent version
            of this Privacy Policy, as well as the Site’s and the App’s Terms
            and your continuing visit, access or use of the Site or the App
            reaffirms your acceptance and agreement in each instance. <br />
            <br />
            As used herein, the term “personal information” means information
            that would allow someone to specifically identify you or contact you
            physically or online (e.g., your name, physical address, telephone
            number, email address, social security number); and the term
            “non-personal information” means any information other than personal
            information that BlueFairy may collect from you.
        </p>
        {privacySections.map((section, index) => (
            <CollapsibleSection
                key={index}
                title={section.title}
                content={section.content}
            />
        ))}
    </div>
);

export default PrivacyPolicy;
