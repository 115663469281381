/**
 * Formats untits to be plural if not a weight
 * @param {string} unit - The unit type to be formatted
 * @param {string} quantity - The amount of units to be formatted
 * @returns {string} The formatted unit quantity
 */
export default function getPluralUnit(unit, quantity) {
    if (unit === 'ml' || unit === 'g' || unit === 'kg' || unit === 'l') {
        return unit;
    } else {
        return +quantity > 1 ? unit + 's' : unit;
    }
}

