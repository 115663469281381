import { useEffect, useState } from 'react';
import { fetchElementResponseAPI } from '../../api/unitsAPI';
import { useToast } from '../../context/ToastContext';

/**
 * @typedef {object} FormResponse
 * @property {object} formResponse The form response.
 * @property {boolean} loading The loading state.
 */

/**
 * Fetches the element response from the API.
 * @param {string} elementId The element ID.
 * @returns {formResponse} The form response and loading state.
 */
const useFetchElementResponse = (elementId) => {
    const { addToast } = useToast();
    const [formResponse, setFormResponse] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchFormResponse = async () => {
            try {
                let data = await fetchElementResponseAPI(elementId);
                if (data.message) {
                    data = {};
                }
                setFormResponse(data);
            } catch (error) {
                addToast({
                    variant: 'error',
                    title: 'Failed to fetch element response',
                    description: 'Please try again later.',
                });
            }
            setLoading(false);
        };

        fetchFormResponse();
    }, []);

    return { formResponse, loading };
};

export default useFetchElementResponse;
