import React, { useState } from 'react';
import GamifiedModal from '../../../components/DialogModal/GamifiedModal';
import FeatureRequestForm from '../../../components/Forms/FeatureRequestForm';

/**
 * The Feature Request Modal component.
 * @returns {React.JSX.Element} The rendered FeatureRequestModal component.
 */
const FeatureRequestModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <>
            <button
                className="group mb-10 w-full rounded-3xl bg-sapphireBlue-800 px-5 py-8 text-left text-white"
                onClick={() => setModalIsOpen(true)}
            >
                <h3 className="medium-title">Request a quest</h3>
                <p className="mt-3 subtitle transition-all group-hover:line-clamp-none">
                    Click here to let us know what specific quest you&apos;d
                    like added to the platform!
                </p>
            </button>
            <GamifiedModal
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                title={'Feature Request'}
            >
                <FeatureRequestForm type="Quest" />
            </GamifiedModal>
        </>
    );
};

export default FeatureRequestModal;
