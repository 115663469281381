import React from 'react';
import Part1 from './Part1';
import Part2 from './Part2';

/**
 * Renders the sign-up form for patients.
 * @param {Object} props The component props.
 * @param {number} props.step The current step in the sign-up process.
 * @returns {React.JSX.Element} The rendered SignUpPatient component.
 */
export default function SignUpPatient({ step }) {
    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2 text-center">
                <div className=" flex flex-col items-center justify-center gap-2">
                    <h1 className="text-2xl font-extrabold tracking-wide md:text-3xl">
                        Time to create your profile!
                    </h1>
                    <h2>
                        Please enter your information below to sign-up for an
                        account.
                    </h2>
                </div>
            </div>

            <div className="mx-auto mb-4 mt-5 w-full max-w-4xl px-4 py-4 md:mt-10 md:px-8 md:py-8 ">
                {step === 5 ? (
                    <Part1 />
                ) :  (
                    <Part2 />
                )}
            </div>
        </>
    );
}
