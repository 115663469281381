import { removeAdminUser } from '../../../api/adminAPI';
import DialogModal from '../../../components/DialogModal/DialogModal';
import Button from '../../../components/ui/Button/Button';
import React from 'react';

/**
 * Renders a modal to delete a user
 * @param {object} props - The props of the component
 * @param {boolean} props.isOpen - The state of the modal
 * @param {Function} props.setIsOpen - The function to set the state of the modal
 * @param {string} props.userId - The id of the user to delete
 * @param {Function} props.setData - The function to set the data
 * @returns {React.JSX.Element} The markup of the component
 */
export default function DeleteUserModal({
    isOpen,
    setIsOpen,
    userId,
    setData,
}) {
    const handleRemoveUser = async (id) => {
        try {
            await removeAdminUser(id);
            setData((prev) => prev.filter((user) => user._id !== id));
            setIsOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <DialogModal isOpen={isOpen} setIsOpen={setIsOpen} title="Delete user">
            <p className="my-10">Are you sure you want to delete this user?</p>
            <div className="mt-4 flex w-full justify-between gap-4">
                <Button
                    variant="danger"
                    onClick={() => setIsOpen(false)}
                    className="rounded-md  px-4 py-2 sm:text-sm md:text-base"
                >
                    Cancel
                </Button>
                <Button
                    variant="destructive"
                    onClick={() => handleRemoveUser(userId)}
                    className="rounded-md  px-4 py-2 sm:text-sm md:text-base"
                >
                    Delete
                </Button>
            </div>
        </DialogModal>
    );
}
