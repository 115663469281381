import { useState, useEffect } from 'react';
import GamifiedAchievementModal from '../Achievements/GamifiedAchievementModal.jsx';
import achievementIcons from '../../components/Achievements/achievementIcons.js';
import { useSocket } from '../../context/SocketContext.jsx';

const AchievementModal = () => {
    const { socket } = useSocket();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [achievementName, setAchievementName] = useState('');
    const [achievementDescription, setAchievementDescription] = useState('');

    const [icon, setIcon] = useState('');

    useEffect(() => {
        if (socket) {
            // listen for the 'achievementUnlocked' event from the server for the current user.
            socket.on('achievementUnlocked', (data) => {
                //  When the event is received, set the name, points, and icon of the achievement that was unlocked
                const { achievementId, name, description } = data;
                const tempIcon =
                    achievementIcons[achievementId] || achievementIcons.default;
                setAchievementName(name);
                setAchievementDescription(description);
                setIcon(tempIcon);
                setModalIsOpen(true);
            });

            return () => {
                socket.off('achievementUnlocked');
            };
        }
    }, [socket]);

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <>
            {modalIsOpen && (
                <GamifiedAchievementModal
                    isOpen={modalIsOpen}
                    setIsOpen={closeModal}
                    title={achievementName}
                    image={icon}
                    description={achievementDescription}
                />
            )}
        </>
    );
};

export default AchievementModal;
