import { useEffect, useState } from 'react';
import { fetchUnitsAPI } from '../../api/unitsAPI';
import { useToast } from '../../context/ToastContext';

/**
 * Fetches the units from the API.
 * @returns {object[]} The units.
 */

const useFetchUnits = () => {
    const { addToast } = useToast();
    const [units, setUnits] = useState([]);

    useEffect(() => {
        const getUnits = async () => {
            try {
                const data = await fetchUnitsAPI();
                setUnits(data || []);
            } catch (error) {
                addToast({
                    variant: 'error',
                    title: 'Failed to fetch units',
                    description: 'Please try again later.',
                });
            }
        };
        getUnits();
    }, [addToast]);

    return units;
};

export default useFetchUnits;
