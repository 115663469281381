export const sendBugReportAPI = async (data) => {
  const response = await fetch('/api/support/bug-report', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(responseData.message || "Unable to send message. Try again.")
  }
}

export const sendFeatureRequestAPI = async (data) => {
  const response = await fetch('/api/support/feature-request', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(responseData.message || "Unable to send message. Try again.")
  }
}