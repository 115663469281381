import React from 'react';
import TransitionWrapper from './TransitionWrapper';
import getCaptializedString from '../../utils/getCaptializedString';
import getPluralUnit from '../../utils/getPluralUnit';

/**
 * Renders a food log entry with transition effects.
 * @param {object} props - The component props.
 * @param {object} props.foodLog - The food log data.
 * @param {boolean} props.isHovered - A boolean flag to control the transition visibility.
 * @returns {React.ReactElement} The rendered component.
 */
const RenderFoodLog = ({ foodLog, isHovered }) => (
    <>
        <p className="font-bold">{getCaptializedString(foodLog.mealType)}</p>
        <p>
            {foodLog.mealContents
                .map(
                    (content) =>
                        `${content.quantity} ${getPluralUnit(content.unit, content.quantity)} of ${content.food}`
                )
                .join(', ')}
        </p>
        <TransitionWrapper isHovered={isHovered}>
            <div className="mt-1">
                <p>{`Digestive Reactions: ${getCaptializedString(foodLog.reactions.digestiveReactions.join(', '))}`}</p>
                <p>{`Emotional Response: ${getCaptializedString(foodLog.reactions.emotionalResponses)}`}</p>
                <p>{`Physical Reactions: ${getCaptializedString(foodLog.reactions.physicalReactions.join(', '))}`}</p>
            </div>
        </TransitionWrapper>
    </>
);

export default RenderFoodLog;
