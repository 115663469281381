import React from 'react';
import FeatureRequestModal from './components/FeatureRequestModal';
import QuestList from './components/QuestList';
const quests = [
    {
        title: 'Inagene',
        description:
            "Try a free kit that might explain your past experiences with medications, suggest changes to your current treatments, and what you think of the report's layout and details.",
        status: 'active',
        link: '/quest/inagene',
        style: 'bg-sapphireBlue-700 hover:bg-sapphireBlue-600',
    },
    {
        title: 'Kalia Health',
        description:
            'A biomedical startup focused on enhancing maternal health by targeting pre-eclampsia, a leading cause of maternal mortality that is effectively treatable with early detection. The company has developed an innovative, non-invasive at-home diagnostic test for pre-eclampsia, offering accurate, accessible point-of-care diagnostics to improve outcomes for expectant mothers.',
        status: 'active',
        link: '/quest/kalia-health',
        style: 'bg-purple-700 hover:bg-purple-600',
    },
    {
        title: 'HERHealth',
        description:
            'A collaborative initiative that strives to empower low-income women working in global supply chains. They are dedicated to building the largest quality data-lake for female patients with an initial focus on supporting endometriosis diagnosis and treatment',
        status: 'active',
        link: '/quest/herhealth',
        style: 'bg-yellow-700 hover:bg-yellow-600',
    },
];

/**
 * Renders the Quest Page that displays the active and completed quests.
 * @returns {React.JSX.Element} The rendered Quest component.
 */
export default function Quest() {
    return (
        <div className="h-full max-w-xl">
            <div className="flex items-center border-b-2 pb-4">
                <h1 className="flex-1 text-center large-title">
                    Quest
                </h1>
            </div>

            <div className="mt-10 flex flex-col">
                <h2 className="mb-10 medium-title">Active Quests</h2>
                <FeatureRequestModal />
                <QuestList quests={quests} />
                <h2 className="medium-title">Completed Quests</h2>
            </div>
        </div>
    );
}
