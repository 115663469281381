import React from 'react';
import { useFormContext } from 'react-hook-form';
import { StandardInput } from '../../../components/ui/Inputs/StandardInput';
import { Link } from 'react-router-dom';

/**
 * Renders the second part of the Signup form.
 * @returns {React.JSX.Element} The rendered Part2 component.
 */
export default function Part2() {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <>
            <div className="mb-6">
                <StandardInput
                    label="Username"
                    id="username"
                    type="username"
                    placeholder="Enter username"
                    error={errors.username}
                    errorText={errors.username?.message}
                    register={register}
                />
            </div>
            <div className="mb-6">
                <StandardInput
                    label="Date of Birth"
                    id="dob"
                    type="date"
                    error={errors.dob}
                    errorText={errors.dob?.message}
                    register={register}
                />
            </div>

            <div className="mb-6 flex flex-col items-center justify-center gap-6 md:flex-row md:gap-2">
                <div className="relative w-full">
                    <StandardInput
                        label="Password"
                        id="password"
                        type="password"
                        placeholder="******************"
                        error={errors.password}
                        errorText={errors.password?.message}
                        register={register}
                    />
                </div>
                <div className="relative w-full">
                    <StandardInput
                        label="Confirm Password"
                        id="confirmPassword"
                        type="password"
                        placeholder="******************"
                        error={errors.confirmPassword}
                        errorText={errors.confirmPassword?.message}
                        register={register}
                    />
                </div>
            </div>
            <div className="my-4 flex flex-col items-center">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox text-blue-600"
                        {...register('termsAgreement')}
                    />
                    <span className="ml-2">
                        I agree to the{' '}
                        <Link to="/terms" className="text-blue-500">
                            Terms and Conditions
                        </Link>
                        .
                    </span>
                </label>
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox text-blue-600"
                        {...register('privacyPolicyAgreement')}
                    />
                    <span className="ml-2">
                        I agree to the{' '}
                        <Link to="/privacy" className="text-blue-500">
                            Privacy Policy
                        </Link>
                        .
                    </span>
                </label>
            </div>
        </>
    );
}
