

export const getFieldError = (errors, path) => {
  let current = errors;
  path.split('.').forEach((segment) => {
      if (current && segment.includes('[')) {
          const [arrayPath, arrayIndex] = segment
              .split(/\[|\]/)
              .filter(Boolean);
          current = current[arrayPath]?.[parseInt(arrayIndex)];
      } else {
          current = current?.[segment];
      }
  });
  return current;
};
