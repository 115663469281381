import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button/Button';

export function FormEndModal({ isOpen, closeModal }) {
    const navigate = useNavigate();
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 font-sans"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="small-title md:medium-title leading-6 text-gray-600"
                                    >
                                        Don&apos;t go before
                                        finishing
                                    </Dialog.Title>
                                    <div className="my-6">
                                        <p className="text-small md:small-title text-gray-500 font-bold">
                                            You&apos;re almost done! If you
                                            leave now, you&apos;ll loose your
                                            progress.
                                        </p>
                                    </div>

                                    <div className="mt-4 flex justify-between items-center">
                                        <Button
                                            variant="destructive"
                                            type="button"
                                            onClick={() => {
                                                closeModal();
                                                navigate('/journey');
                                            }}
                                        >
                                            Leave
                                        </Button>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            onClick={closeModal}
                                        >
                                            Stay and Finish
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
