import React from 'react';
import { useFormContext } from 'react-hook-form';

import Sprout from '../../../assets/roundIcons/Sprout.svg';
import Seedling from '../../../assets/roundIcons/Seedling.svg';
import Plant from '../../../assets/roundIcons/Plant.svg';
import Flower from '../../../assets/roundIcons/Flower.svg';

const knowledgeLevels = [
    {
        name: "Don't know much about it",
        icon: Sprout,
    },
    {
        name: 'Know the basics',
        icon: Seedling,
    },
    {
        name: 'Well-informed',
        icon: Plant,
    },
    {
        name: 'Very knowledgeable',
        icon: Flower,
    },
];

/**
 * Renders the Knowledge component.
 * @returns {React.JSX.Element} The rendered Knowledge component.
 */
export default function Knowledge() {
    const { register, watch } = useFormContext();
    const selectedKnowledge = watch('knowledgeAboutAutoimmunity');
    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2 ">
                <div className=" flex flex-col items-center gap-2 text-center ">
                    <h1 className="text-2xl font-extrabold tracking-wide md:text-3xl ">
                        How much do you know about autoimmunity?
                    </h1>
                    <h2>Choose one from the following</h2>
                </div>
            </div>
            <div className="mx-auto mt-20 grid max-w-xl grid-cols-1 gap-4 px-4">
                {knowledgeLevels.map((item, index) => (
                    <label
                    key={index}
                    className={`h-24 flex items-center rounded-lg border px-4 py-4 font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:h-20 md:text-lg ${
                        selectedKnowledge === item.name ? 'bg-pictonBlue-300' : ''
                    }`}
                    >
                    <div className="flex justify-center items-center gap-4 w-full break-words text-left">
                        <img src={item.icon} className="w-10" />
                        <span className="w-full text-sm sm:text-base">{item.name}</span>
                    </div>
                    <input
                        type="radio"
                        value={item.name}
                        {...register('knowledgeAboutAutoimmunity')}
                        className="hidden" // Hide the radio but keep it functional
                    />
                </label>
                ))}
            </div>
        </>
    );
}
