import Construction from '../../assets/icons/construction.svg';

function ConstructionPage({ title, description }) {
    return (
        <div className="max-w-xl h-full">
            <h1 className="font-extrabold text-3xl border-b-2 text-center mt-10 pb-4">
                {title}
            </h1>
            {description && (
                <h2 className="font-bold text-lg mt-2 mb-10">{description}</h2>
            )}
            <div className="text-center mt-56">
                <img
                    src={Construction}
                    alt="Construction Zone"
                    className="mx-auto"
                />
                <h3 className="font-extrabold text-2xl">
                    The page you&apos;re looking for is under construction
                </h3>
                <p className="text-xl">
                    We&apos;re glad to see you&apos;re eager for what we have in
                    store. This will be available soon!
                </p>
            </div>
        </div>
    );
}

export default ConstructionPage;
