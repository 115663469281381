import { useState } from 'react';
import Button from '../ui/Button/Button';
import { useForm } from 'react-hook-form';
import { inviteUserAPI } from '../../api/userAPI';
import { useToast } from '../../context/ToastContext';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

export default function ShareWithFriends() {
    const [showInput, setShowInput] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { addToast } = useToast();
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        try {
            await inviteUserAPI(data);
            addToast({
                variant: 'success',
                title: 'Invite sent successfully',
                description: 'Your friend will receive an email shortly.',
            });
            reset();
            setShowInput(false);
        } catch (err) {
            addToast({
                variant: 'error',
                title: 'Failed to send invite',
                description: 'Please try again later.',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="w-full rounded-xl border-2 bg-white px-6 py-2">
            <h1 className="mt-4 small-title tracking-wide ">
                Share {''}
                <span className="text-sapphireBlue-600">
                    BlueFairy Infinity
                </span>{' '}
                {''}
                With Friends
            </h1>
            <p className="mt-2 font-medium text-gray-500">
                You can invite 3 friends to receive early access.
            </p>
            {showInput ? (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex gap-2 py-2"
                >
                    <input
                        type="email"
                        className="w-full appearance-none rounded-lg border-2 px-2 py-1 leading-tight text-gray-700 shadow"
                        required
                        {...register('email')}
                    />
                    <Button
                        type="submit"
                        isLoading={isSubmitting}
                    >
                            <ChevronRightIcon className="h-4 w-4 stroke-2" />
                    </Button>
                </form>
            ) : (
                <Button
                    onClick={() => setShowInput(true)}
                    className="mb-2 mt-6 w-full rounded-xl bg-sapphireBlue-800 py-2 text-lg font-extrabold text-white transition-all hover:bg-sapphireBlue-600"
                >
                    Send Invite
                </Button>
            )}
        </div>
    );
}
