import React from 'react';
import { useFormContext } from 'react-hook-form';

import Lupus from '../../../assets/roundIcons/Lupus.svg';
import MS from '../../../assets/roundIcons/MS.svg';
import IBD from '../../../assets/roundIcons/IBD.svg';
import Psoriasis from '../../../assets/roundIcons/Psoriasis.svg';
import Diabetes from '../../../assets/roundIcons/Diabetes.svg';
import Arthritis from '../../../assets/roundIcons/Arthritis.svg';
import GravesThyroid from '../../../assets/roundIcons/GravesThyroid.svg';
import AddisonsDisease from '../../../assets/roundIcons/AddisonsDisease.svg';
import AnkylosingSpondylitis from '../../../assets/roundIcons/AnkylosingSpondylitis.svg';
import KawasakiDisease from '../../../assets/roundIcons/KawasakiDisease.svg';
import ChronicIllness from '../../../assets/roundIcons/ChronicIllness.svg';
import Undiagnosed from '../../../assets/roundIcons/Undiagnosed.svg';
import Other from '../../../assets/roundIcons/Other.svg';
import Scleroderma from '../../../assets/roundIcons/Scleroderma.svg';


const autoimmuneDiseases = [
    {
        name: 'Systemic Lupus Erythematosus',
        value: 'SLE',
        image: Lupus,
    },
    {
        name: 'Multiple Sclerosis (MS)',
        value: 'MS',
        image: MS,
    },
    {
        name: 'Inflammatory Bowel Disease (IBD)',
        value: 'IBD',
        image: IBD,
    },
    {
        name: 'Psoriasis',
        value: 'Psoriasis',
        image: Psoriasis,
    },
    {
        name: 'Diabetes (Type 1)',
        value: 'Type 1 Diabetes',
        image: Diabetes,
    },
    {
        name: 'Rheumatoid Arthritis (RA)',
        value: 'RA',
        image: Arthritis,
    },
    {
        name: "Graves' Disease",
        value: "Graves' Disease",
        image: GravesThyroid,
    },
    {
        name: "Addison's Disease",
        value: "Addison's Disease",
        image: AddisonsDisease,
    },
    {
        name: 'Scleroderma',
        value: 'Scleroderma',
        image: Scleroderma,
    },
    {
        name: "Hashimoto's Thyroiditis",
        value: "Hashimoto's",
        image: GravesThyroid,    
    },
    {
        name: 'Ankylosing Spondylitis',
        value: 'Ankylosing Spondylitis',
        image: AnkylosingSpondylitis,
    },
    {
        name: 'Kawasaki Disease',
        value: 'Kawasaki Disease',
        image: KawasakiDisease,
    },
    {
        name: 'Chronic Illness',
        value: 'Chronic Illness',
        image: ChronicIllness,
    },
    {
        name: 'Undiagnosed Condition',
        value: 'Undiagnosed Condition',
        image: Undiagnosed,
    },
    {
        name: 'Other',
        value: 'Other',
        image: Other,
    },
];


/**
 * Renders the AutoimmuneDiseases component.
 * @returns {React.JSX.Element} The rendered AutoimmuneDiseases component.
 */
export default function AutoimmuneDiseases() {
    const { register, watch } = useFormContext();

    // Use watch to get the current value of selected diseases
    const selectedDiseases = watch('autoimmuneDiseases');

    return (
        <>
            <div className="mt-10 flex items-center justify-center px-2">
                <div className="flex flex-col items-left justify-left gap-2 text-left">
                    <h1 className="medium-title tracking-wide md:text-3xl">
                        My autoimmune journey includes....
                    </h1>
                    <h2>Choose one or more from the following</h2>
                </div>
            </div>
            <div className="mt-10 grid grid-cols-2 gap-4 px-4 md:mt-20 md:grid-cols-4">
                {autoimmuneDiseases.map((item, index) => (
                    <label
                        key={index}
                        className={`h-36 flex justify-center rounded-lg border px-4 py-4 font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:text-lg ${
                            selectedDiseases.includes(item.value)
                                ? 'bg-pictonBlue-300 hover:bg-pictonBlue-300'
                                : ''
                        }`}
                    >
                        <div className="flex-col items-center gap-4 w-full break-words text-left">
                            <img src={item.image} className="w-10 mb-2" />
                            <span className="w-full text-sm sm:text-base">{item.name}</span>
                        </div>
                        <input
                            type="checkbox"
                            value={item.value}
                            {...register('autoimmuneDiseases')}
                            className="hidden" // Hide the checkbox but keep it functional
                        />
                    </label>
                ))}
            </div>
        </>
    );    
}
