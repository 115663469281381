export const createStripePortalAPI = async () => {
    try {
        const response = await fetch('/api/stripe/create-portal-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Unable to create stripe portal. Try again');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Failed creating stripe portal. Try again.');
    }
};

export const createStripeCheckoutAPI = async () => {
    try {
        const response = await fetch(`/api/stripe/create-checkout-session`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lookupKey: 'BlueFairyInfinityAnnual316',
            }),
        });
        if (!response.ok) {
            throw new Error('Unable to create stripe checkout. Try again');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Failed creating stripe checkout. Try again.');
    }
};

