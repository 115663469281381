import React from 'react';
import TransitionWrapper from './TransitionWrapper';

/**
 * Renders a pain log entry with transition effects.
 * @param {object} props - The component props.
 * @param {object} props.painLog - The pain log data.
 * @param {boolean} props.isHovered - A boolean flag to control the transition visibility.
 * @returns {React.ReactElement} The rendered component.
 */
const RenderPainLog = ({ painLog, isHovered }) => (
    <>
        <p className="font-bold">{`Intensity: ${painLog.intensity}/10`}</p>
        <p>{`Location: ${painLog.location}`}</p>
        <TransitionWrapper isHovered={isHovered}>
            <div className="mt-1">
                <p>{`Duration: ${painLog.duration} minutes`}</p>
                <p>{`Triggers: ${painLog.triggers.join(', ')}`}</p>
                <p>{`Relief Methods: ${painLog.reliefMethods.join(', ')}`}</p>
            </div>
        </TransitionWrapper>
    </>
);

export default RenderPainLog;
