import React, { useEffect, useRef, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../components/ui/Button/Button';
import { sections } from './sections';
import Sections from './components/Sections';
import EarlyAccessForm from './components/EarlyAccessForm';
import { SpeakeasyPassword } from './components/SpeakeasyPassword';

/**
 * Renders the LandingPage page.
 * @returns {React.JSX.Element} The rendered LandingPage page.
 */
export default function LandingPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [inputActive, setInputActive] = useState(false);
    const { isAuthenticated } = useAuth();
    const signupRef = useRef(null);
    const [accessGranted, setAccessGranted] = useState(
        localStorage.getItem('accessGranted') === 'true'
    );
    const scrollToSignup = () => {
        signupRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/journey');
        }
    }, []);

    useEffect(() => {
        const password = searchParams.get('password');
        if(password){
            localStorage.setItem('accessGranted', 'true');
            setAccessGranted(true);
        }
    }, [])

    return !accessGranted ? (
        <SpeakeasyPassword onUnlock={() => setAccessGranted(true)} />
    ) : (
        <div ref={signupRef} className="mx-auto flex flex-col overflow-auto ">
            <div className="-my-20 flex h-screen flex-col justify-center gap-16 ">
                <main className="flex flex-col gap-4 text-center md:gap-6">
                    <h1 className="text-5xl font-bold tracking-tight md:text-6xl">
                        Immune Health Reimagined
                    </h1>
                    <div>
                        <p className="text-xl font-medium md:text-3xl">
                            We&apos;re a community of autoimmune patients
                            <br />
                            working to improve treatment outcomes
                            <br />
                            while we chase cures.
                        </p>
                    </div>
                </main>
                <section className="flex w-full items-center justify-center px-2">
                    {!inputActive ? (
                        <Button
                            variant="primary"
                            onClick={() => setInputActive(true)}
                            className="flex items-center gap-2 rounded-full px-6 py-3 text-xl md:px-8 md:py-4 md:text-3xl"
                        >
                            Join The Early Access Waitlist
                            <ChevronRightIcon className="h-6 w-6 stroke-white stroke-2" />
                        </Button>
                    ) : (
                        <EarlyAccessForm />
                    )}
                </section>
            </div>
            <section className="mb-48 mt-10 text-center">
                <h2 className="text-4xl font-bold tracking-tight md:text-5xl">
                    Why BlueFairy?
                </h2>
                <p className="mt-4 text-xl font-medium md:text-2xl">
                    Because your health is your wealth.
                </p>
            </section>
            <div className="flex flex-col gap-32 bg-pictonBlue-200 px-4 pt-20 text-center md:gap-56">
                {sections.map((section, index) => (
                    <div key={index} className="relative mt-20 w-full">
                        <Sections section={section} />
                    </div>
                ))}
            </div>
            <div className=" mx-auto w-full bg-pictonBlue-200 py-24 sm:pt-56 ">
                <div className="relative isolate mx-auto max-w-7xl overflow-hidden rounded-xl bg-blue-600 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                        aria-hidden="true"
                    >
                        <circle
                            cx={512}
                            cy={512}
                            r={512}
                            fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                            fillOpacity="0.7"
                        />
                        <defs>
                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                <stop stopColor="#7775D6" />
                                <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                        </defs>
                    </svg>
                    <div className="mx-auto flex max-w-7xl flex-col items-center justify-center py-24 lg:py-32 ">
                        <h3 className="text-3xl font-bold tracking-tight text-white md:text-5xl">
                            We&apos;re autoimmune patients too.
                        </h3>
                        <p className="mt-6 text-lg leading-8 text-gray-300 md:text-2xl">
                            There is a better way. Join us in building better
                            autoimmune healthcare.
                        </p>
                        <Button
                            variant="outline"
                            onClick={() => {
                                setInputActive(true);
                                scrollToSignup();
                            }}
                            className="mt-20 flex items-center gap-2 rounded-full px-6 py-3 text-xl text-white md:px-8 md:py-4 md:text-3xl"
                        >
                            Join The Early Access Waitlist
                            <ChevronRightIcon className="h-6 w-6 stroke-white stroke-2" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}