import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import Button from '../../../components/ui/Button/Button';

export const SpeakeasyPassword = ({ onUnlock }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const checkPassword = () => {
        if (password === 'GOBLUEFAIRY!') {
            onUnlock();
            localStorage.setItem('accessGranted', true);
        } else {
            setError(true);
        }
    };

    return (
        <div className="-my-20 mx-auto flex h-screen max-w-4xl items-center justify-center ">
            <div>
                <h1 className="text-center text-5xl font-bold tracking-tight md:text-6xl">
                    Welcome to BlueFairy
                </h1>
                <p className="medium-title mt-1 text-center tracking-wide text-gray-500">
                    We&apos;re an invite only community at the moment. Enter the
                    passphrase to see what we&apos;re building.
                </p>
                <form onSubmit={checkPassword} className="mt-12 md:mt-20">
                    <label
                        htmlFor="password"
                        className="mb-2 block text-lg font-medium"
                    >
                        Passphrase:
                    </label>
                    <div className="flex">
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full appearance-none rounded-lg border-2 bg-white px-4 py-2 leading-tight text-gray-700 shadow md:px-6 md:py-3"
                            placeholder="Type here..."
                        />
                        <Button type="submit">
                            <ChevronRightIcon className="size-6 stroke-white stroke-1" />
                        </Button>
                    </div>
                    {error && (
                        <p className="error-text mt-2 text-center">
                            Incorrect password, try again.
                        </p>
                    )}
                </form>
            </div>
        </div>
    );
};
