import React from 'react';
import { useFormContext } from 'react-hook-form';
import { StandardInput } from '../../../components/ui/Inputs/StandardInput';
import { StandardSelect } from '../../../components/ui/Inputs/StandardSelect';
import { formatPhoneNumber } from '../../../shared/util/formatPhoneNumber';

/**
 * Renders the Part1 component of the sign up flow where users enter their information.
 * @returns {React.JSX.Element} The rendered Part1 component.
 */
export default function Part1() {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mx-auto max-w-3xl">
            <div className="mb-8 flex flex-col">
                <div className="space-y-6">
                    <div className="flex gap-4">
                        <div className="flex-shrink-0">
                            <StandardInput
                                label="Prefix"
                                placeholder="Mr."
                                id="namePrefix"
                                type="text"
                                error={errors.namePrefix}
                                errorText="Please enter a prefix."
                                register={register}
                            />
                        </div>
                        <div className="w-full">
                            <StandardInput
                                label="First Name"
                                placeholder="John"
                                id="firstName"
                                type="text"
                                error={errors.firstName}
                                errorText="Please enter first name."
                                register={register}
                                required={true}
                            />
                        </div>
                    </div>
                    <StandardInput
                        label="Middle Name"
                        placeholder="Middle"
                        id="middleName"
                        type="text"
                        error={errors.middleName}
                        errorText="Invalid middle name."
                        register={register}
                    />
                    <div className="flex gap-4">
                        <div className="w-full">
                            <StandardInput
                                label="Last Name"
                                placeholder="Doe"
                                id="lastName"
                                type="text"
                                error={errors.lastName}
                                errorText="Please enter last name."
                                register={register}
                                required={true}
                            />
                        </div>
                        <div className="flex-shrink-0">
                            <StandardInput
                                label="Suffix"
                                placeholder="Jr."
                                id="nameSuffix"
                                type="text"
                                error={errors.nameSuffix}
                                errorText="Please enter a suffix."
                                register={register}
                            />
                        </div>
                    </div>

                    <StandardInput
                        label="E-Mail"
                        placeholder="email@email.com"
                        id="email"
                        type="email"
                        error={errors.email}
                        errorText="Please enter a valid email address."
                        register={register}
                        required={true}
                    />
                    <div className="flex gap-4">
                        <div className="w-full">
                            <StandardInput
                                label="Phone Number"
                                placeholder="(123) 456-7890"
                                id="phoneNumber"
                                type="tel"
                                error={errors.phoneNumber}
                                errorText="Please enter a phone number"
                                register={register}
                                onChange={formatPhoneNumber}
                                required={true}
                            />
                        </div>
                        <div className="flex-shrink-0">
                            <StandardSelect
                                label="Type"
                                id="phoneNumberType"
                                options={[
                                    { value: 'mobile', label: 'Mobile' },
                                    { value: 'home', label: 'Home' },
                                    { value: 'work', label: 'Work' },
                                    { value: 'other', label: 'Other' },
                                ]}
                                error={errors.phoneNumberType}
                                errorText="Please select a phone number type."
                                register={register}
                                required={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
