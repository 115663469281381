import { useEffect, useState } from 'react';
import {
    format,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    subMonths,
    subWeeks,
    isAfter,
    addMonths,
    addWeeks,
    startOfWeek,
    endOfWeek,
    isToday,
    getDay,
} from 'date-fns';
import Button from '../../../components/ui/Button/Button';
import cn from '../../../components/ui/utils/cn';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const HealthCalendar = ({ selectedDate, setSelectedDate }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [view, setView] = useState('week');
    const [animationDirection, setAnimationDirection] = useState(0); // 0 for initial, 1 for next, -1 for prev

    useEffect(() => {
        // Animation reset
        const timeoutId = setTimeout(() => setAnimationDirection(0), 500); // Reset after animation complete
        return () => clearTimeout(timeoutId);
    }, [currentDate, view]);

    const onNext = () => {
        setAnimationDirection(1);
        setCurrentDate(
            view === 'month'
                ? addMonths(currentDate, 1)
                : addWeeks(currentDate, 1)
        );
    };

    const onPrev = () => {
        setAnimationDirection(-1);
        setCurrentDate(
            view === 'month'
                ? subMonths(currentDate, 1)
                : subWeeks(currentDate, 1)
        );
    };

    /**
     * Get the days to display based on the current view (week or month)
     * @returns {Date[]} Array of dates to display
     */
    const getDaysToDisplay = () => {
        if (view === 'week') {
            const start = startOfWeek(currentDate, { weekStartsOn: 0 });
            const end = endOfWeek(currentDate, { weekStartsOn: 0 });
            return eachDayOfInterval({ start, end });
        } else {
            const start = startOfMonth(currentDate);
            let end = endOfMonth(currentDate);
            end = endOfWeek(end, { weekStartsOn: 0 });
            return eachDayOfInterval({ start, end });
        }
    };

    const datesToDisplay = getDaysToDisplay();
    useEffect(() => {
        setAnimationDirection(0); // Reset animation direction on date change
    }, [currentDate, view]);

    const slideVariants = {
        initial: (direction) => ({
            x: direction > 0 ? 500 : -500,
            opacity: 0,
        }),
        animate: {
            x: 0,
            opacity: 1,
            transition: { type: 'spring', stiffness: 300, damping: 24 },
        },
        exit: (direction) => ({
            x: direction > 0 ? -500 : 500,
            opacity: 0,
            transition: { duration: 0.3 },
        }),
    };

    // When toggling the view, adjust currentDate to ensure it's in the correct month/week
    const handleToggleView = () => {
        if (view === 'week') {
            // Switching to month view; adjust currentDate to selectedDate's month
            setCurrentDate(
                new Date(selectedDate.getFullYear(), selectedDate.getMonth())
            );
        } else {
            // Switching to week view; no adjustment needed, as week view focuses on selectedDate
        }
        setView(view === 'month' ? 'week' : 'month');
    };

    // Update setSelectedDate to also adjust week/month views as necessary
    const handleSelectDate = (day) => {
        setSelectedDate(day);
        if (view === 'week') {
            // If already in week view, ensure currentDate is adjusted if selectedDate is in a different week
            const startOfWeekSelected = startOfWeek(day, { weekStartsOn: 0 });
            if (
                startOfWeekSelected.getTime() !==
                startOfWeek(currentDate, { weekStartsOn: 0 }).getTime()
            ) {
                setCurrentDate(day);
            }
        } else {
            // If in month view, ensure currentDate is adjusted if selectedDate is in a different month
            if (
                day.getMonth() !== currentDate.getMonth() ||
                day.getFullYear() !== currentDate.getFullYear()
            ) {
                setCurrentDate(new Date(day.getFullYear(), day.getMonth()));
            }
        }
    };

    return (
        <div>
            <div className="my-4 flex items-center justify-between">
                <Button
                    variant="icon"
                    className="bg-sapphireBlue-800 text-white"
                    icon={<ChevronLeftIcon className="h-6 w-6" />}
                    onClick={onPrev}
                />
                <h2 className="text-xl font-bold md:text-2xl">
                    {format(currentDate, 'LLLL yyyy')}
                </h2>
                <Button
                    onClick={onNext}
                    variant="icon"
                    className="bg-sapphireBlue-800 text-white"
                    icon={<ChevronRightIcon className="h-6 w-6" />}
                    disabled={isAfter(startOfMonth(currentDate), new Date())}
                />
            </div>
            <div
                className={cn(
                    'relative mb-6 overflow-hidden rounded-xl bg-white pb-14 transition-all duration-500',
                    view === 'month' ? 'h-96' : 'h-32'
                )}
            >
                <div className="grid h-14 grid-cols-7 items-center border-b-2 text-center font-extrabold leading-6">
                    <div className="my-2">SUN</div>
                    <div className="my-2">MON</div>
                    <div className="my-2">TUE</div>
                    <div className="my-2">WED</div>
                    <div className="my-2">THU</div>
                    <div className="my-2">FRI</div>
                    <div className="my-2">SAT</div>
                </div>
                <div className="relative h-full">
                    <AnimatePresence
                        initial={false}
                        custom={animationDirection}
                    >
                        <motion.div
                            key={`${view}-${currentDate}`}
                            custom={animationDirection}
                            variants={slideVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            className="absolute inset-0 grid grid-cols-7 rounded-xl text-center font-extrabold leading-6"
                        >
                            {datesToDisplay.map((day, index) => (
                                <DayButton
                                    key={day.toString()}
                                    day={day}
                                    index={index}
                                    handleSelectDate={handleSelectDate}
                                    selectedDate={selectedDate}
                                />
                            ))}
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>

            <Button onClick={handleToggleView}>Toggle View</Button>
        </div>
    );
};

export default HealthCalendar;

const DayButton = ({ day, index, handleSelectDate, selectedDate }) => {
    const formattedDate = format(day, 'd');
    return (
        <div
            onClick={() => handleSelectDate(day)}
            className={cn(
                'mx-auto flex w-full cursor-pointer items-center justify-center border-b bg-transparent bg-white text-center font-bold transition-all duration-200',
                index === 0 && colStartClasses[getDay(day)],
                format(day, 'yyyy-MM-dd') ===
                    format(selectedDate, 'yyyy-MM-dd') &&
                    ' rounded-2xl bg-sapphireBlue-800 text-lg font-extrabold text-white'
            )}
        >
            <time
                className={cn(
                    isToday(new Date(day)) &&
                        'rounded-full bg-green-400 p-1 px-2 text-white'
                )}
            >
                {formattedDate}
            </time>
        </div>
    );
};
let colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
];
