import FeatureRequestModal from '../../components/DialogModal/FeatureRequestModal';
import AchievementsCard from '../../components/Achievements/AchievementsCard';
import AchievementModal from '../../components/Achievements/AchievementModal.jsx';
import achievementIcons from '../../components/Achievements/achievementIcons.js';
import useAchievements from '../../shared/hooks/useAchievements';



/**
 * Renders the Achievements Page.
 * @returns {React.JSX.Element} The rendered Achievements Page.
 */
export default function AchievementsPage() {
    const { achievements, getUserAchievementProgress } = useAchievements();

    const renderAchievements = (category) => {
        return achievements
            .filter((achievement) => achievement.category === category)
            .map((achievement) => {
                const { progress, isUnlocked } = getUserAchievementProgress(
                    achievement._id
                );

                const icon =
                    achievementIcons[achievement.achievementId] ||
                    achievementIcons.default;

                return (
                    <AchievementsCard
                        key={achievement._id}
                        value={isUnlocked ? achievement.maxValue : progress}
                        maxValue={achievement.maxValue}
                        text={achievement.name}
                        Image={icon}
                        isUnlocked={isUnlocked}
                    />
                );
            });
    };

    return (
        <div className="h-full w-full max-w-xl">
            <div className="flex items-center border-b-2 pb-4">
                <h1 className="flex-1 text-center text-2xl font-extrabold">
                    Achievements
                </h1>
                <FeatureRequestModal />
            </div>

            <div className="mt-10">
                <h2 className="text-xl font-extrabold">Baby Steps</h2>
                <div className="mt-10 flex flex-wrap gap-4">
                    {renderAchievements('beg')}
                </div>
            </div>

            <div className="mt-10">
                <h2 className="text-xl font-extrabold">Consistency</h2>
                <div className="mt-10 flex flex-wrap gap-4">
                    {renderAchievements('int')}
                </div>
            </div>

            <div className="mt-10">
                <h2 className="text-xl font-extrabold">Milestones</h2>
                <div className="mt-10 flex flex-wrap gap-4">
                    {renderAchievements('adv')}
                </div>
            </div>
        </div>
    );
}
