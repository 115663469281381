export const StandardSelect = ({
    label,
    id,
    options,
    className,
    error,
    errorText,
    register,
    required,
}) => {
    return (
        <>
            {label && (
                <label
                    htmlFor={id}
                    className="mb-2 block text-sm font-bold text-gray-700 md:text-base"
                >
                    {label}{' '}
                    {required && <span className="text-red-500">*</span>}
                </label>
            )}
            <select
                id={id}
                {...register(id)} // Use the register function here
                className={`w-full appearance-none rounded-lg border-2 bg-white px-4 py-2 leading-tight text-gray-700 shadow md:px-6 md:py-3 ${className}`}
                required={required}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {error && <p className="error-text">{errorText}</p>}
        </>
    );
};
