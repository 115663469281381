import React from 'react';
import TransitionWrapper from './TransitionWrapper';

/**
 * Renders a supplement log entry with transition effects.
 * @param {object} props - The component props.
 * @param {object} props.supplementLog - The supplement log data.
 * @param {boolean} props.isHovered - A boolean flag to control the transition visibility.
 * @returns {React.JSX.Element} The rendered component.
 */
const RenderSupplementLog = ({ supplementLog, isHovered }) => (
    <>
        <p className="font-bold">{supplementLog.supplementName}</p>
        <p>{`Dosage: ${supplementLog.dosage.doseAmount} ${supplementLog.dosage.doseUnit}`}</p>
        <TransitionWrapper isHovered={isHovered}>
            <div className="mt-1">
                <p>{`Reason for Taking: ${supplementLog.reasonForTaking}`}</p>
                <p>{`Observed Effects: ${supplementLog.observedEffects}`}</p>
            </div>
        </TransitionWrapper>
    </>
);

export default RenderSupplementLog;
