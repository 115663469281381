export const StandardInput = ({
    label,
    id,
    type,
    placeholder,
    error,
    errorText,
    className,
    register,
    onChange,
    onBlur,
    required,
}) => {
    // Function to merge React Hook Form's onChange with custom onChange
    const handleChange = (e) => {
        const { onChange: formOnChange } = register(id); // Destructure onChange from register
        formOnChange(e); // First, call React Hook Form's onChange
        if (onChange) {
            onChange(e); // Then, call the custom onChange if it exists
        }
    };

    // Default handleBlur function to trim input and call custom onBlur
    const handleBlur = (e) => {
        const trimmedValue = e.target.value.trim();
        const { onChange: formOnChange } = register(id); // Destructure onChange from register for updating the value
        e.target.value = trimmedValue; // Update the event's value to the trimmed value
        formOnChange(e); // Update React Hook Form's value
        if (onBlur) {
            onBlur(e); // Call the custom onBlur if it exists
        }
    };

    return (
        <>
            {label && (
                <label
                    htmlFor={id}
                    className="mb-2 block text-sm font-bold text-gray-700 md:text-base"
                >
                    {label}{' '}
                    {required && <span className="text-red-500">*</span>}
                </label>
            )}
            <input
                id={id}
                {...register(id)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`w-full appearance-none rounded-lg border-2 bg-white px-4 py-2 leading-tight text-gray-700 shadow md:px-6 md:py-3 ${className}`}
                type={type}
                placeholder={placeholder}
                required={required}
            />
            {error && <p className="error-text">{errorText}</p>}
        </>
    );
};
