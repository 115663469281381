export const logMessages = {
    "Sleep Log": [
        "NIH says in a study that we spend about one-third of our lives asleep.",
        "The longest recorded time anyone has gone without sleep is 11 days, according to NIH research.",
        "NIH states that blind people can still dream.",
        "NIH research suggests that sleep deprivation can make you more creative.",
        "Your brain is more active during REM sleep than when you're awake, according to NIH.",
        "Some people can function on very little sleep, says the NIH.",
        "Sleeping in two segments was common in the 17th century, according to NIH research.",
        "You're more likely to have a heart attack on Monday morning, reports the NHLBI.",
        "Elephants sleep the least of all mammals, according to NIH studies.",
        "NIH research shows that dolphins sleep with one half of their brain at a time."
    ],
    "Exercise Log": [
        "CDC states that regular physical activity can improve your memory, attention, and even help protect against cognitive decline as you age. (Source: National Institute on Aging)", 
        "The NIH says that your body uses more energy (calories) to maintain its basic functions while you're asleep than when you're passively watching television! (Source: National Heart, Lung, and Blood Institute)",
        "According to an article in Nature Medicine, you don't have to run marathons to reap the benefits of exercise. Even short bursts of activity throughout the day can improve your health. (Source: Centers for Disease Control and Prevention)",
        "The Mayo Clinic tells us that physical activity stimulates the release of endorphins, which have mood-lifting effects. (Source: National Institute of Mental Health)", 
        "May Clinic informs us that strength training not only builds muscle but also helps to increase bone density, reducing your risk of osteoporosis and fractures. (Source: National Institute of Arthritis and Musculoskeletal and Skin Diseases)"
    ],
    "Medication Log": [
        "Did you know that Coca-Cola was invented by a pharmacist? John Pemberton originally created the drink as a medicine!",
        "The first prescription drug ever was aspirin! Imagine a world without it! According to the NIH, it was patented back in 1899 and shipped worldwide!", 
        "Some medications, called nanomedicines, are so tiny they can travel through your bloodstream like tiny submarines!", 
        "Scientists at Oxford University are working on medications that you can inhale - like you're breathing in a superpower!", 
        "Believe it or not, according to the DEA Museum, some medications are made from plants and animals! Like a magic potion from nature!" 
    ],
    "Food Log": [
       "According to the NIH, enzymes in your body break down proteins, carbs, and fats into smaller pieces to fuel your cells!",
        "NIH explains that without digestive enzymes, you wouldn’t be able to absorb nutrients from food.",
        "In NIH research, vitamins and minerals are like tiny tools that help enzymes do their job better.",
        "According to the NIH, some people’s bodies make fewer enzymes, leading to digestion issues.",
        "NIH notes that fiber isn't broken down by enzymes but still helps keep digestion smooth!",
        "According to the NIH, your body doesn’t know the difference between sugar in fruit and sugar in candy—it’s the nutrients around it that matter!",
        "NIH mentions fiber as your gut’s secret weapon, helping lower cholesterol and keep your heart happy. It's like a ninja in your gut!",
        "According to the NIH, proteins keep you full longer, but carbs are still the body’s favorite energy source.",
        "In NIH findings, your heart actually prefers to burn fat for fuel!",
        "Healthy fats, like those in nuts, are packed with nutrients that support heart health, NIH says.",
        "According to the NIH, not all carbs are bad—whole grains are actually great for health!",
        "Spot-reducing fat in one area through exercise is a myth, but regular activity does help with overall fat loss.",
        "Eating lots of Carrots can turn you orange! This is called ",
    ],
    "Supplement Log": [
        "According to the NIH, vitamin D is crucial for bone health and immune function, often produced by the body in response to sunlight exposure.",
        "The CDC states that omega-3 fatty acids, found in fish oil, can reduce inflammation and lower the risk of heart disease when consumed regularly.",
        "NIH highlights that probiotics support gut health by balancing the gut microbiome, which can improve digestion and boost immunity.",
        "Magnesium is involved in over 300 biochemical reactions in the body, and many people are deficient in this essential mineral, affecting sleep and anxiety levels.",
        "Multivitamins can help fill nutrient gaps in diets, especially in older adults, according to research that suggests they may enhance overall well-being.",
        "Curcumin, the active ingredient in turmeric, has anti-inflammatory properties and may help reduce symptoms of arthritis and improve cognitive function.",
        "Coenzyme Q10 (CoQ10) is essential for energy production in cells and may reduce the risk of heart disease and alleviate side effects from cholesterol medications.",
        "B vitamins are crucial for energy metabolism and red blood cell production, and they play a key role in preventing neural tube defects during pregnancy.",
    ],
    "Water Log": [
        "According to the NIH, water makes up about 60% of the human body and is vital for almost every bodily function, including digestion, absorption, and circulation.",
        "Research indicates that even mild dehydration can impair cognitive function, affecting concentration and mood.",
        "Water helps regulate body temperature through sweating, essential for preventing overheating, especially during exercise.",
        "Water is crucial for maintaining healthy joints, helping lubricate cartilage and preventing joint pain.",
        "Drinking water before meals can increase feelings of fullness, which may help with weight loss efforts.",
        "Water is essential for the digestion process, as it helps dissolve nutrients and transport them throughout the body.",
        "Adequate hydration is linked to improved skin health, enhancing elasticity and hydration while reducing dryness.",
        "The kidneys rely on water to filter waste from the blood, helping prevent kidney stones and urinary tract infections.",
        "Drinking cold water can temporarily increase metabolism as the body works to warm it up.",
        "Water is crucial for maintaining fluid balance in the body, transporting nutrients and oxygen to cells while removing waste."
    ],
    "Pain Log": [
        "The body releases endorphins during physical activity, which can reduce the perception of pain.",
        "Techniques like mindfulness and cognitive-behavioral therapy can help manage chronic pain by changing how the brain processes pain signals.",
        "Regular physical activity can help decrease the intensity of chronic pain, improve mobility, and enhance overall quality of life.",
        "Poor sleep can exacerbate pain, creating a cycle of discomfort, emphasizing the importance of good sleep hygiene for pain management.",
        "Certain foods can contribute to inflammation, which may worsen pain; a diet rich in fruits and omega-3s can help reduce it.",
        "Pain signals are transmitted through the nervous system, and conditions such as neuropathy can cause chronic pain without injury.",
        "There are various pain relief options, including medications, physical therapy, acupuncture, and lifestyle modifications.",
        "Different cultures have various beliefs and practices regarding pain, influencing how pain is expressed and managed."
    ],
    "Mood Log": [
        "According to the NIH, dancing can boost your mood and brain health—let loose and shake it off!",
        "NIH states that just a brisk walk can elevate your mood and improve your overall well-being.",
        "Regular physical activity, as noted by NIH, can significantly lessen symptoms of depression and anxiety.",
        "According to the NIH, spending time outdoors can enhance your mood and reduce feelings of stress.",
        "Engaging in hobbies and creative activities can improve mental health and provide joy, says NIH.",
        "NIH mentions that listening to music can have a positive effect on mood and can even reduce stress.",
        "According to the NIH, practicing mindfulness and meditation can help improve emotional well-being and reduce anxiety.",
        "Socializing with friends and loved ones can boost happiness and decrease feelings of loneliness, according to NIH.",
        "According to the NIH, regular physical activity can boost your mood by releasing endorphins, which are natural mood lifters.",
        "NIH mentions that even a short walk can improve your mood and cognitive function. Just 10 minutes of brisk walking can make a noticeable difference in your mental health.",
        "According to research highlighted by the NIH, being in nature can have profound effects on your mood, reducing feelings of stress and promoting a sense of calm.",
        "NIH notes that maintaining a healthy diet rich in fruits, vegetables, and omega-3 fatty acids can positively impact your mood. Nutritional deficiencies can lead to mood disturbances, while a balanced diet supports brain health.",
        "According to the CDC, mindfulness practices, such as meditation and yoga, can help enhance mood and decrease stress levels. These practices promote relaxation and can be effective in managing anxiety.",
        "NIH research shows that social connections play a vital role in mood regulation. Engaging with friends and family can improve emotional health and provide support during challenging times.",
        "According to the NIH, sunlight exposure can increase serotonin levels in the brain, which helps improve mood!",
        "NIH emphasizes that laughter and humor can significantly boost your mood. Laughter triggers the release of endorphins and promotes an overall sense of well-being."
    ],
};