import { useEffect, useState } from 'react';
import { fetchUserProgressAPI } from '../../api/userAPI';
import { useToast } from '../../context/ToastContext';

/**
 * Fetches the user progress from the API.
 * @returns {object} The user progress.
 */

const useFetchProgress = () => {
    const { addToast } = useToast();
    const [progress, setProgress] = useState(null); // start w/ null to handle empty state

    useEffect(() => {
        const getUserProgress = async () => {
            try {
                const data = await fetchUserProgressAPI();
                setProgress(data.progress || {}); // ensure data is always an object
            } catch (error) {
                addToast({
                    variant: 'error',
                    title: 'Failed to fetch user progress',
                    description: 'Please try again later.',
                });
            }
        };
        getUserProgress();
    }, [addToast]);

    return progress;
};

export default useFetchProgress;
