import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { StandardInput } from '../../components/ui/Inputs/StandardInput';
import Button from '../../components/ui/Button/Button';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useAdminAuth } from '../../context/AdminAuthContext';

const schema = yup
    .object({
        email: yup.string().email().required('Please enter a valid email'),
        password: yup.string().min(5).required('This field is required'),
    })
    .required();
/**
 * Renders the AdminLogin component.
 * @returns {React.JSX.Element} The AdminLogin component.
 */
const AdminLogin = () => {
    const navigate = useNavigate();
    const { login } = useAdminAuth();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [submitError, setSubmitError] = useState('');

    const onSubmit = async (data) => {
        try {
            const { email, password } = data;
            await login({ email, password });
            navigate('/admin/panel');
        } catch (err) {
            setSubmitError(err.message || 'Something went wrong. Try again.');
        }
    };

    return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
            <div className="mb-4 w-full max-w-2xl rounded-lg bg-white px-12 py-16 text-black shadow-md">
                <h1 className="mb-8 text-3xl font-bold">Admin Login</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <StandardInput
                            label="Email"
                            id="email"
                            type="email"
                            placeholder="Email"
                            error={errors.email}
                            errorText={
                                errors.email?.message ||
                                'Please enter a valid email'
                            }
                            register={register}
                        />
                    </div>
                    <div className="relative mb-4">
                        <StandardInput
                            label="Password"
                            id="password"
                            type="password"
                            placeholder="******************"
                            error={errors.password}
                            errorText={
                                errors.password?.message ||
                                'Please enter a valid password'
                            }
                            register={register}
                        />
                    </div>
                    {submitError && (
                        <div className="error-text mb-4 flex items-center justify-center gap-2 rounded-lg bg-red-100 px-4 py-2 font-medium">
                            <ExclamationCircleIcon className="h-5 w-5" />
                            <p>{submitError}</p>
                        </div>
                    )}
                    <Button
                        className="mx-auto mt-4 w-full"
                        type="submit"
                        disabled={!isValid}
                        isLoading={isSubmitting}
                    >
                        Login
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default AdminLogin;
