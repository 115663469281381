import { createContext, useContext, useState } from 'react';
/**
 * Toast component displays a notification with a title and description.
 * @typedef {object} Toast - The component props.
 * @property {string} id - The unique identifier for the toast.
 * @property {string} title - The title of the toast.
 * @property {string} description - The description of the toast.
 * @property {string} [variant] - The variant of the toast (success, error, standard). Defaults to 'standard'.
 * @property {number} [duration] - The duration in milliseconds for which the toast should be displayed. Defaults to 5000ms.
 * @property {Function} removeToast - The function to remove the toast from the UI.
 */

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    /**
     * Add a toast to the list of toasts
     * @param {Toast} toast - The toast to add
     */
    function addToast(toast) {
        setToasts((currentToasts) => [
            ...currentToasts,
            { ...toast, id: Date.now() },
        ]);
    }

    const removeToast = (id) => {
        setToasts((currentToasts) =>
            currentToasts.filter((toast) => toast.id !== id)
        );
    };

    return (
        <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
            {children}
        </ToastContext.Provider>
    );
};
