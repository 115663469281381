import { Link, useLocation } from 'react-router-dom';
import { LogoFull } from '../Logo/Logo';
import Button from '../ui/Button/Button';

export const TopNav = () => {
    const { pathname } = useLocation();
    return (
        <nav className="flex items-center justify-between px-3 pt-3 md:px-10 md:pt-5">
            <Button variant="ghost">
                <Link to="/" className="flex items-center gap-2 ps-2.5 ">
                    <LogoFull />
                </Link>
            </Button>
            <div className="flex gap-5">
                {/* {pathname !== '/login' && (
                    <Button variant="outline">
                        <Link to="/login">Login</Link>
                    </Button>
                )}
                {pathname !== '/invite-warning' && pathname !== '/sign-up' && (
                    <Button variant="outline">
                        <Link to="/invite-warning">SignUp</Link>
                    </Button>
                )} */}
            </div>
        </nav>
    );
};
