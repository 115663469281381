import { feedbackMessages } from "../components/FeedbackMessages";
import { logMessages } from "../components/LogMessages";

// Function to randomly select a message from a category
export const getRandomMessage = (category, logType) => {
  const chance =
      category === 'completion' || category === 'corrections'
          ? 1
          : Math.random();
  const messages =
      chance > 0.5
          ? (
              logMessages[logType] && chance > 0.7 ?
              logMessages[logType][
                  Math.floor(Math.random() * logMessages[logType].length)
              ]
            : feedbackMessages[category][
                  Math.floor(Math.random() * feedbackMessages[category].length)
              ]
            )
          : '';
  return messages;
};