import React from 'react';

/**
 * Renders a section component.
 * @param {object} props - The props.
 * @param {object} props.section - The section object.
 * @returns {React.JSX.Element} The rendered section component.
 */
export default function Sections({ section }) {
    return (
        <section
            className={`flex flex-col justify-center max-w-7xl mx-auto ${
                section.style === 'row-reverse'
                    ? 'md:flex-row-reverse'
                    : 'md:flex-row'
            }  gap-20 text-center`}
        >
            <div className="w-full bg-pictonBlue-200">
                <button className="text-3xl lg:text-5xl font-bold tracking-tight bg-blue-600 text-white  rounded-full p-6">
                    {section.title}
                </button>
                <p className="text-lg lg:text-2xl text-left mt-10 font-medium">
                    {section.description}
                </p>
            </div>

            <div>
                <img
                    src={section.image}
                    alt="Adverse Medication Reactions"
                    className="max-w-sm lg:max-w-xl text-center"
                />
                <span className="text-blue-600 font-bold lg:text-lg">
                    Statistic: {section.statistic}
                </span>
            </div>
        </section>
    );
}
