export const StandardTextArea = ({
    id,
    label,
    placeholder,
    rows,
    error,
    errorText,
    className,
    register,
}) => {
    return (
        <>
            {label && (
                <label
                    htmlFor={id}
                    className="mb-2 block text-sm font-bold text-gray-700 md:text-base"
                >
                    {label}
                </label>
            )}
            <textarea
                id={id}
                {...register(id)}
                className={`w-full appearance-none rounded-lg border bg-white px-4 py-2 leading-tight text-gray-700 shadow md:px-6 md:py-3 ${className}`}
                placeholder={placeholder}
                rows={rows || 3}
            />
            {error && <p className="error-text">{errorText}</p>}
        </>
    );
};
