import React from 'react';
import { Transition } from '@headlessui/react';

/**
 * Wraps elements in a `Transition` component from `@headlessui/react` to provide
 * a smooth transition between shown and hidden states based on the `isHovered` prop.
 * @param {object} props - The component props.
 * @param {boolean} props.isHovered - Determines whether the children should be shown or hidden.
 * @param {React.ReactNode} props.children - The content to be transitioned.
 * @returns {React.JSX.Element} - The rendered component.
 */
const TransitionWrapper = ({ isHovered, children }) => (
    <Transition
        show={isHovered}
        enter="transition-all duration-300"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-96 opacity-100"
        leave="transition-all duration-300"
        leaveFrom="max-h-96 opacity-100"
        leaveTo="max-h-0 opacity-0"
        className="overflow-hidden"
    >
        {children}
    </Transition>
);

export default TransitionWrapper;

