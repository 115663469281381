/**
 * Organizes the unit and element data for the profile page.
 * @param {object} data - The data to be organized.
 * @returns {object[]} The organized data.
 */
export default function organizeUnitElementData(data) {
    if (!data) return [];

    const { unitsCompleted, currentUnit, elementsCompleted } = data;
    const organizedData = [];

    //console.log('Data received in organizeUnitElementData:', data);

    unitsCompleted.forEach((unit) => {
        organizedData[unit.order] = {
            ...unit,
            isCompleted: true,
            elements: elementsCompleted.filter(
                (element) => element.unitId === unit._id
            ),
        };
    });

    if (currentUnit) {
        organizedData[currentUnit.order] = {
            ...currentUnit,
            isCompleted: false,
            elements: elementsCompleted.filter(
                (element) => element.unitId === currentUnit._id
            ),
        };
    } else {
        organizedData.push({
            title: 'No current unit',
            description: 'You have not started any unit yet.',
            isCompleted: false,
            elements: [],
        });
    }
    //console.log('Organized data:', organizedData);
    return organizedData;
}
