import * as Yup from 'yup';


export const bugReportSchema = Yup.object().shape({
  description: Yup.string().required('Bug description is required.'),
  steps: Yup.string().required(
      'Please provide steps to reproduce the issue.'
  ),
  severity: Yup.string()
      .required('Selecting the severity of the issue is required.')
      .oneOf(['minor', 'moderate', 'critical'], 'Invalid severity level'),
  comments: Yup.string(),
  // Making this field optional; no need to specify anything
});