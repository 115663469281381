//* Custom Styles
import './LoadSpinner.css';

export const LoadSpinner = () => {
    return (
        <section
            className="LoadSpinner-Container"
            role="alert"
            aria-live="assertive"
        >
            {/* Generated by Loading.io at https://loading.io  */}
            <div className="LoadSpinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    );
};