import { useEffect, useState } from 'react';
import { checkEligibilityForQuestAPI } from '../../api/questAPI';
/**
 * Fetches basic information.
 * @returns {object} The response object from the basic information element.
 */
const useFetchBasicInformation = () => {
    const [basicInformation, setBasicInformation] = useState(null);

    useEffect(() => {
        const getEligibility = async () => {
            try {
                const data = await checkEligibilityForQuestAPI();
                setBasicInformation(data);
            } catch (error) {
                setBasicInformation(null);
            }
        };
        getEligibility();
    }, []);

    return basicInformation;
};

export default useFetchBasicInformation;
