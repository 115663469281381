import React, { useState, useEffect } from 'react';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    AreaChart,
    Area,
} from 'recharts';
import { fetchPainInsightsAPI } from '../../api/healthLogsAPI';


const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="rounded-lg border border-gray-200 bg-purple-100 p-4 shadow-sm">
                <p className="paragraph">{`${payload[0].value} Pain Intensity`}</p>
            </div>
        );
    }

    return null;
};

const renderColorfulLegendText = () => {
    return (
        <span style={{ color: '#8884d8', fontWeight: 'bold' }}>
            Average Intensity
        </span>
    );
};

/**
 * PainChart component fetches pain data from the server and displays it in a line chart.
 * @returns {React.JSX.Element} - PainChart component
 */
export default function PainChart() {
    const [painData, setPainData] = useState([]);

    useEffect(() => {
        const fetchPainData = async () => {
            try {
                const data = await fetchPainInsightsAPI();
                setPainData(
                    data.map((item) => ({
                        ...item,
                        date: item._id,
                        averageIntensity: item.averageIntensity,
                    }))
                );
            } catch (error) {
                console.error('Failed to fetch pain data', error);
            }
        };

        fetchPainData();
    }, []);

    return (
        <div>
            {painData.length > 0 ? (
                <div className="-ml-10 mt-4">
                    <ResponsiveContainer width="100%" aspect={1.5}>
                        <AreaChart data={painData}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#8884d8"
                                        stopOpacity={1}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#8884d8"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                height={60}
                                label={{
                                    value: 'Date',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    fill: '#8884d8',
                                }}
                                stroke="#8884d8"
                                strokeWidth={2}
                                tick={{ fontSize: 14, fontWeight: 'bold' }}
                            />
                            <YAxis
                                label={{
                                    value: 'Average Intensity',
                                    angle: -90,
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    fill: '#8884d8',
                                }}
                                stroke="#8884d8"
                                strokeWidth={2}
                                tick={{ fontSize: 14, fontWeight: 'bold' }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend
                                formatter={renderColorfulLegendText}
                                wrapperStyle={{ left: '10%', bottom: 20 }}
                            />
                            {/* <Line
                                type="monotone"
                                dataKey="totalAmount"
                                stroke="#8884d8"
                                strokeWidth={2}
                                activeDot={{ r: 8 }}
                            /> */}
                            <Area type="monotone" dataKey="averageIntensity" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <p className="paragraph">No pain data available for the past week.</p>
            )}
        </div>
    );
}
