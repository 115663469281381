import React, { useState, useEffect } from 'react';
import Button from '../../../components/ui/Button/Button';
import ButtonSpinner from '../../../components/LoadSpinner/ButtonSpinner';
import { fetchInterests, updatePreferences } from '../../../api/mailchimpAPI';
import { useToast } from '../../../context/ToastContext';

// Fetch interests
const fetchMailchimpInterests = async () => {
    try {
        const data = await fetchInterests();
        return data;
    } catch (error) {
        console.error(`Failed to fetch interests: ${error.message}`);
        return [];
    }
};

/**
 * Renders the MailChimp preferences component.
 * @param {string} email - Unique email that registered to Early Access
 * @param {string} name - The name of the user that registered to Early Access, set to BFF if no name given
 * @param {Function} onClode - Function to close the modal after submitting preferences 
 * @returns {React.JSX.Element} The MailChimp preferences modal.
 */
export default function MailChimpModal({ email, name = "BFF", onClose}) {
    const [interests, setInterests] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const { addToast } = useToast();

    useEffect(() => {
        const fetchGroups = async () => {
            setLoading(true); 
            const data = await fetchMailchimpInterests();
            setInterests(data); 
            setSelectedGroups(data.map((item) => item.id)); // Select all by default
            setLoading(false);
        };
        fetchGroups();
    }, []);

    // Update preferences
    const updateMailchimpPreferences = async (userEmail, userInterests, userSelectedGroups) => {
        try {
            let hasFalseValue = false; // Track if preferences were modified
            const interestsMap = userInterests.reduce((acc, interest) => {
                const isSelected = userSelectedGroups.includes(interest.id);
                acc[interest.id] = isSelected;
                if (!isSelected) {
                    hasFalseValue = true;
                }
                return acc;
            }, {});
            if (hasFalseValue) {
                await updatePreferences(userEmail, interestsMap);
            }
            onClose();
            addToast({
                variant: 'success',
                title: "Updated Newsletter Preferences",
                description: "Look forward to reading the newsletters you chose!",
            });
        } catch (error) {
            addToast({
                variant: 'error',
                title: "Couldn't update preferences",
                description: "Please try again.",
            });
        }
    };

    // Handle selecting/deselecting interests
    const handleToggleInterest = (id) => {
        setSelectedGroups((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((groupId) => groupId !== id)
                : [...prevSelected, id]
        );
    };

    const handleSubmit = () => {
        updateMailchimpPreferences(email, interests, selectedGroups);
    };

    return (
        <div className="flex flex-col text-gray-700">
            <div className="flex flex-col gap-2 text-center md:gap-4">
                <h1 className="text-xl font-bold tracking-tight md:text-3xl">
                    Awesome, {name?.split(' ')[0]}! You&apos;re on the List!
                </h1>
                <p className="text-lg font-medium md:text-xl">
                    Let&apos;s make sure you&apos;re subscribed to letters that suit you. <br/> Choose your interests:
                </p>
            </div>
            <div className="mt-8 flex flex-col gap-2 text-center text-gray-700 md:gap-6">
                <div className="flex-col gap-4 px-4">
                    {loading ? (
                        <ButtonSpinner />
                    ) : (
                        interests.map((item) => (
                            <label
                                key={item.id}
                                className={`flex items-center rounded-lg border p-4 m-4 font-bolder font-extrabold tracking-wide transition-all hover:bg-pictonBlue-200 md:text-lg ${
                                    selectedGroups.includes(item.id)
                                        ? 'bg-pictonBlue-300 hover:bg-pictonBlue-300'
                                        : ''
                                }`}
                            >
                                <input
                                    type="checkbox"
                                    value={item.id}
                                    checked={selectedGroups.includes(item.id)}
                                    onChange={() => handleToggleInterest(item.id)}
                                    className="mr-4 h-6 w-6 outline-2 outline-gray-100 checked:accent-sapphireBlue-700 checked:border-blue-500 focus:outline-none transition duration-200"
                                />
                                {item.name}
                            </label>
                        ))
                    )}
                </div>

                <Button
                    className="mt-6"
                    onClick={handleSubmit}
                >
                    Update Email Preferences
                </Button>
            </div>
        </div>
    );
}
