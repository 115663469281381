import { useEffect, useState } from 'react';
import { LoadSpinner } from '../LoadSpinner/LoadSpinner';
import { useAdminAuth } from '../../context/AdminAuthContext';
import { Navigate } from 'react-router-dom';

export default function PrivateAdminRoute({ children }) {
    const { isAuthenticated, verifySession } = useAdminAuth();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAccess = async () => {
            await verifySession();
            setIsLoading(false);
        };
        checkAccess();
    }, []);

    if (isLoading) {
        return <LoadSpinner />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/admin/login" replace />;
    }

    return children;
}
