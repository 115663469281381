import { useState } from 'react';
import LogSection from './LogSection.jsx';

/**
 * Displays the health logs for a user
 * @param {object} props - The health data to display
 * @param {object} props.healthData - The health data to display
 * @returns {React.JSX.Element} The health logs
 */
export default function HealthLogs({ healthData }) {
    const [hoveredItem, setHoveredItem] = useState(null);
    const logTypes = Object.keys(healthData);

    return (
        <div className="flex flex-col gap-4">
            {logTypes.map((logType) => (
                <LogSection
                    key={logType}
                    logType={logType}
                    healthData={healthData}
                    hoveredItem={hoveredItem}
                    setHoveredItem={setHoveredItem}
                />
            ))}
        </div>
    );
}
