import { useState, useEffect } from 'react';
import {
    fetchAchievementsAPI,
    fetchUserAchievementsAPI,
} from '../../api/userAPI';
import { useAuth } from '../../context/AuthContext';

const useAchievements = () => {
    const { user } = useAuth();
    const [achievements, setAchievements] = useState([]);
    const [userAchievements, setUserAchievements] = useState([]);

    useEffect(() => {
        const fetchAchievements = async () => {
            try {
                const data = await fetchAchievementsAPI();
                setAchievements(data);
                const userAchievementsData = await fetchUserAchievementsAPI();
                setUserAchievements(userAchievementsData);
            } catch (error) {
                console.error('Failed to fetch achievements', error);
            }
        };

        fetchAchievements();
    }, [user]);

    const getUserAchievementProgress = (achievementId) => {
        const userAchievement = userAchievements.find(
            (a) => a.achievementId && a.achievementId._id === achievementId
        );
        if (userAchievement) {
            return {
                progress: userAchievement.progress || 0,
                isUnlocked: userAchievement.isUnlocked || false,
            };
        }
        return { progress: 0, isUnlocked: false };
    };

    return {
        achievements,
        userAchievements,
        getUserAchievementProgress,
    };
};

export default useAchievements;
