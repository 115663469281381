/**
 * Section component renders a section with a title and content.
 * The section is styled with a title bar and a content area.
 * The title is displayed in a colored, rounded container, and the content is shown
 * within a bordered, white background container below the title.
 *
 * @param {Object} props - The props object.
 * @param {string} props.title - The title of the section.
 * @param {React.ReactNode} props.content - The content to be displayed within the section.
 *
 * @returns {JSX.Element} The rendered Section component.
 */

const Section = ({ title, content }) => {
    return (
        <div className="collapsible-section my-4">
            <div className="flex w-full items-center justify-between rounded-lg bg-pictonBlue-200 p-4 text-left">
                <h2 className="text-lg font-bold">{title}</h2>
            </div>
            <div className="mt-4 rounded-lg border border-gray-200 bg-white p-4">
                {content}
            </div>
        </div>
    );
};

export default Section;
